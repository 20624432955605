<template>
  <div>
    <el-form :model="form" :rules="formRule" class="login-form">
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item class="cell_width" label="" prop="username">
            <el-input
              placeholder="请输入账号"
              prefix-icon="el-icon-user-solid"
              v-model="form.username"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item class="cell_width" label="" prop="password">
            <el-input
              placeholder="请输入密码"
              prefix-icon="el-icon-lock"
              v-model="form.password"
              clearable
              show-password
              type="password"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item class="cell_width" label="" prop="captcha">
            <el-row align="middle" type="flex">
              <el-input
                class="cell_width"
                placeholder="请输入验证码"
                v-model="form.captcha"
                clearable
                @keyup.enter.native="submitForm()"
              ></el-input>
              <div class="captcha-img-view">
                <el-image :src="captchaImg" alt="" class="captcha-img" />
                <el-button class="hover-tips" icon="el-icon-refresh" type="text" @click="getCaptchaImg"
                  >点击刷新</el-button
                >
              </div>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div style="border-radius: 100px">
            <el-button
              style="width: 100%; height: 53px; background: #000000; color: white; font-size: 20px;border-radius: 100px;"
              :loading="loading"
              @click="submitForm"
              >登录</el-button
            >
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { authApi } from '@/api/auth';
import { encryptByRsa } from '@/utils/auth';
import { mapActions } from 'vuex';
import { FormRuleUtil } from '@/utils/formRuleUtil';

export default {
  name: 'LoginView',
  data() {
    return {
      loading: false,
      rsaKey: {},
      captcha: '',
      captchaKey: '',
      captchaImg: '',
      form: {
        username: '',
        captcha: '',
        captchaKey: '',
        password: '',
      },
      formRule: {
        username: FormRuleUtil.strRule('账号', true),
        password: FormRuleUtil.strRule('密码', true),
        captcha: FormRuleUtil.strRule('验证码', true),
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(['login']),
    init() {
      this.getCaptchaImg();
    },
    //秘钥
    async getRsaKey() {
      this.rsaKey = await authApi.getRsaKey();
    },
    // 获取验证码
    async getCaptchaImg() {
      const { image, code, key } = await authApi.getCaptcha();
      this.captchaImg = image;
      this.form.captcha = this.captcha;
      this.form.captchaKey = this.captchaKey = key;
    },
    async submitForm() {
      this.loading = true;
      await this.getRsaKey();
      const { key, separator, millis } = this.rsaKey;
      const password = this.form.password + separator + millis;
      const query = {
        username: this.form.username,
        password: encryptByRsa(key, password),
        captchaCode: this.form.captcha,
        captchaKey: this.form.captchaKey,
        role: '',
        roleKey: '',
      };
      this.login(query)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.getCaptchaImg();
          this.loading = false;
          this.$message.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-form {
  width: 100%;
  // margin: auto;
  box-sizing: border-box;
  padding: 50px 0px;
}

.captcha-img-view {
  position: relative;
  display: block;
  width: 100px;
  height: 32px;
  margin-left: 10px;
  cursor: pointer;
  .captcha-img {
    width: 100%;
    height: 100%;
  }

  > .hover-tips {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    opacity: 0;
  }

  &:hover {
    > .hover-tips {
      opacity: 1;
    }
  }
}
.cell_width {
  // width: 480px;
}
::v-deep .el-input__inner {
  height: 54px !important;
  text-align: inherit;
  font-size: 20px;
  border: none;
}
:deep(.el-input .el-input__icon) {
  font-size: 20px;
}
</style>
