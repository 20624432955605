<template>
  <div class="drawer">
    <el-drawer
      :visible.sync="dialog"
      destroy-on-close
      direction="rtl"
      custom-class="drawer"
      :before-close="handleClose"
      :with-header="false"
      ref="drawer"
    >
      <div class="drawer__header">表单标题</div>
      <div class="drawer__content">
        <slot></slot>
      </div>
      <div class="drawer__footer">
        <el-button type="primary" @click="drawerService.submitForm(getDom)" :loading="loading">{{
          loading ? '提交中 ...' : '确 定'
        }}</el-button>
        <el-button @click="cancelForm">取消 </el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { DrawerService } from '@/service/DrawerService'

export default {
  name: 'smart-drawer',
  props: {
    // record: {
    //   type: Object,
    //   require: true
    // }
  },
  data () {
    return {
      loading: false,
      dialog: true,
      timer: null,
      drawerService: new DrawerService()
    }
  },

  methods: {

    handleClose (done) {
      if (this.loading) {
        return
      }
      this.loading = true
      this.timer = setTimeout(() => {
        done()
        // 动画关闭需要一定的时间
        setTimeout(() => {
          this.loading = false
        }, 400)
      }, 2000)
    },
    getDom () {
      // this.$emit('submit')
      return this.$refs.drawer.closeDrawer()
    },

    cancelForm () {
      this.loading = false
      this.dialog = false
      this.$emit('onCancel')
      this.drawerService.resetForm()
      clearTimeout(this.timer)
    }
  }
}
</script>

<style>
</style>