export const props = {
    id: {
        type: String,
    },
    title: {
        type: String || Number
    },
    closeByClickModal: {
        type: Boolean,
        default: false
    },
    showModal: {
        type: Boolean,
        default: true
    },
    content: {
        type: Object
    },
    width: {
        type: String,
        default: '1000px'
    },
    top: {
        type: String,
        default: '100px'
    },
    showBtns: {
        type: Boolean,
        default: true
    },
    cancelBtnText: {
        type: String,
        default: '取消'
    },
    onCancel: {
        type: Function
    },
    showCancel: {
        type: Boolean,
        default: true
    },
    confirmBtnText: {
        type: String,
        default: '确定'
    },
    showConfirm: {
        type: Boolean,
        default: true
    },
    onConfirm: {
        type: Function
    },
    otherBtns: {
        type: Array,
        default: () => {
            return []
        }
    },
    query: {
        type: Object,
        default: () => {
            return {}
        }
    },
    formRef: {
        type: String,
    },
    modelKey: {
        type: String
    },
    closeWithLoading: {
        type: Boolean,
        default: false
    },
    showHeader: {
        type: Boolean,
        default: true
    },
    fullscreen: {
        type: Boolean,
        default: false
    }
}
