<template>
  <el-tabs v-model="pageTabName" class="tabs" @tab-click="onTabClick" @tab-remove="removeTab">
    <template v-for="(item, index) in pageTabs">
      <el-tab-pane
        v-if="item.meta.title !== '错误提示'"
        :key="item.meta.title"
        :closable="index !== 0"
        :label="item.meta.title"
        :name="item.meta.title"
        :path="item.path"
      >
      </el-tab-pane>
    </template>
  </el-tabs>
</template>

<script>
import pageTabsMixin from '@/mixins/pageTabs';

export default {
  name: 'TabsView',
  mixins: [pageTabsMixin],
  created() {
    /*if (this.roles[0]) {
        const staticRoute = this.roles.find(item => item.meta && !item.meta.hide).children[0];
        if (staticRoute && !staticRoute.meta.hide) {
            if (!this.pageTabs.some(pageTab => pageTab.path === staticRoute.path)) {
                this.pageTabs.unshift(staticRoute);
                this.pageTabName = staticRoute.meta.title;
            }
        }
    }*/
    const firstRoute = this.roles.find((item) => ['/', '/home'].includes(item.path))?.children?.[0];
    if (!this.pageTabs?.length) {
      this.pageTabs = [];
    }
    this.pageTabs[0] = firstRoute;
    if (this.hasRole(this.roles, this.$route)) {
      this.addTab(this.$route);
    } else {
      console.error('没有权限');
      if (!firstRoute) {
        this.$router.replace('/login');
      } else {
        this.$router.replace(firstRoute.path || '/');
      }
    }
  },
};
</script>

<style lang="scss">
.tabs {
  height: 40px;
  margin-bottom: 10px;
  border: 0;
  box-shadow: none;

  &.el-tabs--card > .el-tabs__header {
    border-bottom: 0;
  }

  &.el-tabs--card > .el-tabs__header .el-tabs__item {
    border: 0;
    border-radius: 4px 4px 0 0;
    background-color: #eceff4;
    margin-right: 10px;

    &.is-active {
      background-color: #fff;
    }
  }

  &.el-tabs--card > .el-tabs__header .el-tabs__nav {
    border-radius: 0;
    border: 0;
  }
}
</style>
