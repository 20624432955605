<template>
        <div class="empty">
          <img src="@/assets/empty.png" title="加载异常"/>
          <span>{{ mag }}</span>
        </div>
</template>

<script>
export default{
  name:"smart-empty",
  props:{
    mag:{
      type:String,
      default:"暂无数据"
    }
  }
}

</script>

<style lang="scss">

.empty{
    width: 100%;
    min-height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #aaa;
    img{
      width: 60px;
      height: 60px;
    }
}
</style>