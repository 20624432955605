export const theme = {
  header: '#FF9C7D',
  headerBlack: '#000000',
  // header: '#409eff',
  menu: {
    background: '#000000',
    backgroundBlack: '#000000',
    text: '#fff',
    // active: 'rgba(0,0,0,0.8)',
    active: 'rgba(255 156 125)',
    activeBlack: '#000',
    activeWhite: '#ffffff',
    textBlack: '#000'
  },
}
