const getters = {
  token: (state) => state.user.token,
  roles: (state) => state.user.roles,
  topMenus: (state) => state.user.topMenus,
  asideMenus: (state) => state.user.asideMenus,
  systemList: (state) => state.user.systemList,
  permission: (state) => state.user.permission,
  pageTabs: (state) => state.tabs.pageTabs,
  pageTab: (state) => state.tabs.pageTab,
  shopList: (state) => state.user.shopList,
  userInfo: (state) => state.user.userInfo,
  shopInfo: (state) => state.user.shopInfo,
  components: state => state.components.components
}

export default getters
