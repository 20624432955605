<template>
  <el-dropdown v-if="systemList.length" class="head-action-item" trigger="click">
    <div :class="{ 'text-white': !lightHeader }" class="el-dropdown-link">
      <i class="el-icon-menu"></i>
      <span class="ml-1">{{ currSys }}</span>
    </div>
    <el-dropdown-menu slot="dropdown" class="message-menu">
      <el-row :gutter="2">
        <el-col
          v-for="(item, index) in systemList"
          :key="item.id"
          :class="[item.bg, { active: item.col === 2 }]"
          :span="(24 / 4) * item.col"
          class="col text-center"
          @click.native="checkSystem(item, index)"
        >
          <i :class="item.icon"></i>
          <div>{{ item.name }}</div>
        </el-col>
      </el-row>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
import { getAuthCache, getToken, setAuthCache } from '@/utils/auth';
import { mapGetters } from 'vuex';
import router from '@/router';
import { theme } from '@/configs/theme';
import { isLightColor } from '@/utils';
import { authApi } from '@/api/auth';
import { systemApi } from '@/api/system';

export default {
  name: 'SubSystemView',
  data() {
    return {
      sysList: [],
      currSystem: {},
      shopList: [],
      toast: true,
    };
  },
  computed: {
    ...mapGetters(['topMenus', 'systemList']),
    currSys: {
      get() {
        return getAuthCache('currSys');
      },
      set() {
        //
      },
    },
    lightHeader() {
      // TODO 需要更合理的判断方式
      return isLightColor(theme.header);
    },
  },
  mounted() {
    this.sysList = this.$store.state.user.systemList;
    this.shopList = this.$store.state.user.shopList;
    // this.getTenantInfo();
    /*if (this.currSys) {
	  this.$message({
		message: `当前系统: ${this.currSys}`,
		type: 'success',
		duration: 2500,
		offset: 50,
	  });
	}*/
  },
  methods: {
    getTenantInfo() {
      systemApi.common.getTenantInfo().then((data) => {
        document.title = data.name;
        this.$store.commit('SET_SHOP_INFO', data);
        document.title = data.name;
        const link = document.querySelector("link[rel*='icon']");
        link.setAttribute('href', data.logo);
      });
    },
    checkSystem(item) {
      let params = {
        token: getToken(),
        bizSystemId: item.id,
      };

      authApi.checkSystem(params).then((data) => {
        this.$store.commit('SET_SYSTEM_CHECK', item.id);
        this.$store.commit('SET_TOKEN', data.accessToken);
        this.$store.commit('SET_LOGIN_INFO', data);
        setAuthCache('currSys', item.name);
        this.currSys = item.name;
        console.log(this.topMenus, '----topMenus');
        // let time = new Date(data.accessTokenExpiration).getTime();
        // setTokenTime(time);
        location.pathname = '/';
        if (this.topMenus.length) {
          const firstMenu = this.topMenus[0];
          this.$nextTick(() => {
            router.replace({
              path: firstMenu.path,
            });
          });
        }
        // this.removeRole();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.message-menu {
  width: 400px;
  padding: 20px;

  .message-menu-item {
    margin-left: 10px;

    > .msg-title {
      font-weight: 600;
    }

    > .msg-info {
      color: #999;
    }
  }
}

.col {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-bottom: 2px;
  cursor: pointer;
  opacity: 0.5;
  color: #fff;

  &.active {
    opacity: 1;
  }
}
</style>
