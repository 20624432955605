<template>
  <div>
    <el-dialog
    v-if="status"
      append-to-body
      :close-on-click-modal="false"
      @closed="cancelCU"
      :visible.sync="status"
      :title="title"
      width="50%"
    >
      <div class="action_header">
        <el-button type="text" class="text-success" @click="handCheckAll">全选</el-button>
        <el-button type="text" class="text-danger" @click="handClear">清空</el-button>
      </div>
      <div class="area_wrap">
        <!-- {{ areaForm }} -->
        <div
          v-for="(area, index) in areaForm"
          :key="index"
          style="width: 140px; display: inline-block; margin-right: 10px; margin-bottom: 20px"
        >
          <el-popover :close-delay="100" transition="none" placement="bottom" trigger="hover" width="580">
            <el-checkbox-group
              v-model="areaForm[index].areaName"
              @change="handleCheckedCitiesChange(index, $event, area)"
            >
              <el-checkbox v-for="item in area.nameList" :label="item.name" :key="item.id"
                >{{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
            <el-checkbox
              slot="reference"
              :indeterminate="areaForm[index].isIndeterminate"
              v-model="areaForm[index].checkAll"
              @change="handleCheckAllChange(area.id, index, $event)"
              >{{ area.name }}
            </el-checkbox>
          </el-popover>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelCU" class="unify-button">取消</el-button>
        <el-button type="primary" @click="submitCU()" class="unify-button">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'AreaModel',
  data () {
    return {
      isAdd: true,
      editIndex: null,
      status: false,
      title: '选择可配送的区域',
      areaForm: [],
      areaData: [],
      flag: false,
      checked: [] // 全选的ID
    }
  },
  methods: {
    handCheckAll () {
      if (!this.flag) {
        this.areaForm.forEach((val, index) => {
          val.areaName = val.nameList.map(item => { return item.name })
          val.isIndeterminate = false
          val.checkAll = true
        })
      } else {
        this.areaForm.map((val, index) => {
          this.areaForm[index].areaName = []
          this.areaForm[index].checkAll = false
        })
      }
    },
    handClear () {
      this.areaForm.map((val, index) => {
        val.isIndeterminate = false
        val.check = false
        this.areaForm[index].areaName = []
        this.areaForm[index].checkAll = false
      })
    },
    /*模态框start*/
    cancelCU () {
      this.areaForm.map((val, index) => {
        // if (!this.isAdd){
        if (val.checkAll) {
          val.isShow = false
          val.isIndeterminate = false
          val.check = false
          val.areaName = []
          val.checkAll = false
        }
        // }

      })
      this.status = false
    },
    submitCU () {
      let areaForm = [...this.areaForm]
      let arr = {}
      if (this.isAdd) {
        areaForm.map((val, index) => {
          if (val.areaName.length > 0) {
            if (val.areaName.length === val.nameList.length) {
              val.nameArr = [...val.areaName]
              val.areaName = []
              let ids = val.nameList.map(item => { return item.id })
              val.checkedCitiesId = ids
              val.check = true
            } else {
              val.check = true
              let a = []
              val.areaName.forEach(id => {
                val.nameList.forEach(ii => {
                  if (id == ii.name) {
                    a.push(ii.id)
                  }
                })
              })
              val.checkedCitiesId = a
              val.nameArr = [...val.areaName]
            }
          }
        })
        arr = areaForm.filter(val => {
          return val.check
        })
        let json = { area: [], firstValue: 0, firstValuePrice: 0, nextValue: 0, nextValuePrice: 0 }
        arr.map(val => {
          json.area.push(val)
        })
        this.$emit('addData', json)
      } else {
        areaForm.map((val, index) => {
          if (val.areaName.length > 0) {
            if (val.areaName.length === val.nameList.length) {
              val.nameArr = [...val.areaName]
              val.areaName = []
              // val.check = true
              let ids = val.nameList.map(item => { return item.id })
              val.checkedCitiesId = ids
              this.$set(val, 'check', true)
            } else {
              let a = []
              val.areaName.forEach(id => {
                val.nameList.forEach(ii => {
                  if (id == ii.name) {
                    a.push(ii.id)
                  }
                })
              })
              val.nameArr = [...val.areaName]
              val.checkedCitiesId = a
              this.$set(val, 'check', true)
            }
          }
        })
        arr = areaForm.filter(val => {
          return val.check
        })
        let json = { area: [] }
        arr.map(val => {
          json.area.push(val)
        })
        this.$emit('edit', json)
      }
      this.status = false
    },
    /*模态框end*/
    //省全选
    handleCheckAllChange (id, index, val) {
      if (val) {
        this.areaForm.map(item => {
          if (id === item.id) {
            item.areaName = []
            let names = item.nameList.map(val => { return val.name })
            item.areaName = names
            item.isShow = false
            item.checkAll = true
            item.isIndeterminate = false
          }
        })
      } else {
        this.areaForm.map(item => {
          if (id === item.id) {
            item.areaName = []
            item.isShow = true
            item.isIndeterminate = false
            item.checkAll = false
            this.$set(item, 'checkAll', false)
            this.$set(item, 'check', false)
          }
        })
        this.flag = false
      }
    },
    //市单选
    handleCheckedCitiesChange (index, val, area) {
      let checkedCount = val.length
      this.flag = (checkedCount > 0 && checkedCount < area.nameList.length)
      area.checkAll = checkedCount === area.nameList.length
      this.flag = (checkedCount === area.nameList.length)
      area.isIndeterminate = checkedCount > 0 && checkedCount < area.nameList.length
      if (checkedCount === area.nameList.length) {
        area.isShow = false
      } else {
        area.isShow = true
      }
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
.area_wrap {
  display: flex;
  flex-wrap: wrap;
}
.action_header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: -40px;
}

div.el-checkbox-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  > label.el-checkbox {
    width: 150px;
    margin-bottom: 10px;
  }
}
</style>