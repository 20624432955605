import { defHttp } from '@/utils/https';
import { apiModel } from '@/configs/apiModel';
// const ADMIN = process.env.VUE_APP_ADMIN; // 后台管理服务器 admin
const ADMIN = apiModel.ADMIN; // 后台管理服务器 admin
const basePath = '/sys/admin'
import { axios } from '@/utils/request'

export const authApi = {
  //获取验证码
  getCaptcha: () => {
    return defHttp.get({
      url: `${apiModel.UAA}/auth/code/captcha`,
    });
  },
  //获取秘钥
  getRsaKey: () => {
    return defHttp.get({
      url: `${apiModel.UAA}/auth/rsa/key`,
    });
  },
  login: (params) => {
    return defHttp.post(
      {
        url: `${apiModel.UAA}/auth/token/admin`,
        params,
      },
      {
        isTransformResponse: false,
      }
    );
  },
  logout: (params) => {
    return defHttp.get(
      {
        url: `${apiModel.UAA}/auth/logout`,
        params,
      },
      {
        isTransformResponse: false,
      }
    );
  },
  //密码重置
  modifyPwd: (params) => {
    return defHttp.post({
      url: '/'+ADMIN + basePath + "/modify-pwd"+`?id=${params.id}&oldPwd=${params.oldPwd}&newPwd=${params.newPwd}`,
    });
  },
  reLogin: (params) => {
    return defHttp.post(
      {
        url: `${apiModel.UAA}/auth/token/refresh`,
        params,
      },
      {
        isTransformResponse: false,
      }
    );
  },
  getUserInfo: (params) => {
    return defHttp.get({
      url: `${apiModel.UAA}/auth/user`,
      params,
    });
  },
  getPermissions: (params) => {
    return defHttp.get({
      url: `${apiModel.UAA}/auth/permissions`,
      params,
    });
  },
  checkSystem: (params) => {
    return defHttp.post({
      url: `${apiModel.UAA}/auth/change/biz`,
      params,
    });
  },
  checkShop: (params) => {
    return defHttp.post({
      url: `${apiModel.UAA}/auth/change/shop`,
      params,
    });
  },
};
