import { Message } from 'element-ui'
import { MessageBox } from 'element-ui'
export const Mag = {
  success (mag) {
    Message({
      showClose: true,
      message: mag,
      type: 'success'
    })
  },
  error (mag) {
    Message({
      showClose: true,
      message: mag,
      type: 'error'
    })
  },
  info (mag) {
    Message({
      showClose: true,
      message: mag,
      type: 'info'
    })
  },
  warning (mag) {
    Message({
      showClose: true,
      message: mag,
      type: 'warning'
    })
  },
  confirm (msg, sendAjax, callback) {
    MessageBox.confirm(msg, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      sendAjax()
    }).catch(() => {
      callback && callback()
      // this.info('已取消删除操作')
      this.info('已取消')
    })
  }
}