import { mapGetters, mapMutations } from 'vuex';

export default {
  data() {
    return {
      pageTabName: '',
      pageTabs: [],
      pageTab: {},
    };
  },
  props: {
    tabData: Object,
  },
  computed: {
    ...mapGetters({
      defaultPageTabs: 'pageTabs',
      defaultPageTab: 'pageTab',
      roles: 'roles',
    }),
  },
  watch: {
    defaultPageTabs: {
      handler: function (v) {
        this.pageTabs = v;
      },
      immediate: true,
    },
    defaultPageTab: {
      handler: function (v) {
        this.pageTab = v;
        this.backTop();
      },
      immediate: true,
    },
    $route(val) {
      this.pushTab(val);
      console.log(this.roles, 'roles');
      for (let i = 0; i < this.pageTabs.length; i++) {
        if (this.pageTabs[i].active) {
          this.pageTabs[i].active = false;
        }
      }
    },
    pageTabName(v) {
      console.log(v);
      if (!this.pageTabs.length) {
        return;
      }
      const pageTabNameList = this.pageTabs.map((item) => item?.meta?.title);
      const index = pageTabNameList.indexOf(v);
      if (index !== -1) {
        const tab = this.pageTabs[index];
        this.SET_TAB(tab);
        this.$emit('change', tab);
      }
    },
    pageTab(v) {
      console.log(v);
      this.pageTabName = v.meta.title;
    },
  },
  methods: {
    ...mapMutations(['SET_TABS', 'SET_TAB']),
    addTab(item) {
      const tabName = item && item.meta && item.meta.title;
      tabName && this.removeTab(tabName);
      this.$nextTick(() => {
        this.pushTab(item);
      });
    },
    backTop() {
      const obj = document.querySelector('.layout_main');
      if (obj) {
        obj.scrollTop = 0;
      }
    },
    hasRole(list, role) {
      return list.some((item) => {
        if (item.path === role.path) {
          return item.path === item.path;
        } else if (item.children && item.children.length) {
          return this.hasRole(item.children, role);
        } else {
          return false;
        }
      });
    },
    pushTab(item) {
      let newTabName = item.meta.title;
      // item.meta.keep = true;
      const index = this.pageTabs.findIndex((tab) => newTabName === tab?.meta?.title);
      if (item.path === '/' || item.path === '/home') {
        if (this.pageTabs.some((tab) => tab.path === item.path)) {
          this.pageTabs.splice(index, 1);
        }
        this.pageTabs.unshift(item);
      } else if (index === -1) {
        this.pageTabs.push({ ...item });
      } else {
        // 替换同名标签
        this.pageTabs.splice(index, 1, item);
      }
      this.pageTabName = newTabName;
      this.SET_TABS(this.pageTabs); // 更新pageTabs
      if (!item.path) {
        return;
      }
      this.$router.push({ ...item }).catch(() => {
        // 捕获login页面跳转抛出的异常
      });
    },
    removeTab(pageTabName) {
      if (!pageTabName) {
        pageTabName = this.pageTab.meta?.title;
      }
      let pageTabs = this.pageTabs.filter((item) => item);
      let newTab = {};
      const activeTab = this.pageTab;
      console.log(activeTab, '当前选中标签');
      const closeTab = pageTabs.find((item) => item?.meta?.title === pageTabName) || {};
      const errorTabIndex = pageTabs.findIndex((item) => item?.meta?.title === '错误提示');
      const length = pageTabs.length;
      // 关闭标签的序号
      const index = pageTabs.findIndex((tab) => tab?.meta?.title === pageTabName);
      // 前标签集合
      const preTabs = pageTabs.slice(0, index);
      // 后标签集合
      const nextTabs = pageTabs.slice(index + 1, length);
      // 最近路由标签
      const preTab = preTabs.reverse().find((item) => item.path);
      const nextTab = nextTabs.reverse().find((item) => item.path);

      const updateRoute = (newTab) => {
        if (newTab && newTab.path) {
          this.pageTabName = newTab.meta.title;
          this.$router
            .replace({
              ...newTab,
            })
            .catch(() => {
              // 捕获login页面跳转抛出的异常
            });
        } else {
          this.pageTabName = preTabs[0]?.meta.title;
        }
      };

      /**
       * 关闭标签
       * 1、判断当前关闭标签是否路由标签
       * 2、非路由标签(组件标签)，直接关闭；路由标签，检查当前关闭标签nextTabs(后标签集合)，preTabs(前标签集合)最近标签，是路由标签即跳转(注：优先查询nextTabs)
       */
      console.log(preTabs, nextTabs);
      // newTab = nextTab ? nextTab : preTab;
      if (nextTab) {
        if (nextTab.meta.prePath === closeTab.path) {
          newTab = preTab;
        } else {
          newTab = nextTab;
        }
      } else {
        newTab = preTab;
      }
      if (activeTab && activeTab.meta && activeTab.meta.title === pageTabName) {
        // 关闭选中标签
        if (closeTab.prePath) {
          const otherTabs = pageTabs.filter((item) => item?.meta?.title !== closeTab?.meta?.title);
          const otherTabsLength = otherTabs.length;
          // 存在相同依赖组件标签, 更新路由
          newTab = otherTabs[otherTabsLength - 1];
          if (newTab.prePath && closeTab.prePath === newTab.prePath) {
            // if (closeTab.prePath === newTab.prePath) {
            this.pageTabName = newTab.meta.title;
            // }
          } else {
            newTab = otherTabs.find((item) => item.path === closeTab.prePath);
            // 路由标签更新路由
            updateRoute(newTab);
          }
        } else {
          // 路由标签更新路由
          console.log(newTab);
          // 路由标签设置meta属性prePath用于关闭依赖标签
          if (activeTab.meta.prePath) {
            // 更新newTab为对应附件标签
            const tab = pageTabs.find((item) => activeTab?.meta?.prePath === item.path);
            newTab = tab || newTab;
          } else if (activeTab.component === 'error/index') {
            const needClose = activeTab.data.close;
            const index = pageTabs.findIndex((item) => item?.meta?.title === newTab?.meta?.title);
            const preIndex = needClose ? 1 : 0;
            this.pageTabName = preTabs[preIndex]?.meta?.title;
            if (pageTabs[index + 1]) {
              // 在错误页点击我知道了
              if (needClose) {
                this.pageTabs = pageTabs.filter((tab, index) => index !== errorTabIndex - 1);
                this.SET_TABS(this.pageTabs); // 更新pageTabs
              }
              return;
            }
          }
          updateRoute(newTab);
        }
      } else if (activeTab && activeTab.meta) {
        // 关闭未选中标签
        // 关闭标签为路由标签并且是选中标签的依赖标签，更新路由
        const hasComponentPre = pageTabs.some(
          (item) => item.path === activeTab.prePath || item.path === activeTab?.meta?.prePath
        );
        if (closeTab.path && hasComponentPre) {
          // 路由标签更新路由
          if (closeTab.path === activeTab.prePath || closeTab.path === activeTab?.meta?.prePath) {
            updateRoute(newTab);
          }
        }
      }
      if (closeTab.path) {
        // 关闭标签,同时关闭依赖于该标签的标签
        pageTabs = pageTabs.filter((item) => {
          if (item.meta?.prePath) {
            return item.meta.prePath !== closeTab.path;
          } else if (item.prePath) {
            return item.prePath !== closeTab.path;
          }
          return true;
        });
      }
      // 关闭标签
      this.pageTabs = pageTabs.filter((tab) => tab?.meta?.title !== pageTabName);
      this.SET_TABS(this.pageTabs); // 更新pageTabs
    },
    onTabClick(e) {
      if (e.$attrs.path === this.$route.path) {
        return;
      }
      console.log(e.$attrs.path);
      if (e.$attrs.path) {
        this.$router.replace(e.$attrs.path).catch(() => {
          // 捕获login页面跳转抛出的异常
        });
      }
    },
    /**
     * 跳转错误页面
     * @param {object} error
     * @param {number} error.code
     * @param {string} error.message
     * @param {boolean} close  是否关闭错误入口页面
     */
    navToError(error, close = false) {
      // 需要跳转错误页面的状态码集合
      const codeList = [-43199];
      if (codeList.includes(error.code)) {
        const route = {
          meta: {
            title: '错误提示',
            keep: false,
          },
          prePath: undefined,
          component: 'error/index',
          componentName: 'error', // 组件名称
          data: { ...error, close },
        };
        this.addTab(route);
      }
    },
  },
};
