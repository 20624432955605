import { getAction, postAction, getActionParams, postActionUrlParams,postActionMultipart } from './manage.js'

const ADMIN = process.env.VUE_APP_ADMIN
/*
 * 商城后台接口*/
const order = {
    // ES刷新
    esRefresh: params => postAction('/order/mall/es/refresh', params),
    //订单数据
    getData: params => postAction('order/mall/page', params),
    //订单详情 (弃用，订单数据已获取详情数据)
    getOrderDetail: params => getAction('/order/mall/info', params),
    //订单添加备注 / / //
    addRemarks: params => postActionMultipart(`/order/mall/update-remarks/${params.orderNo}`,params),
    //修改收货人信息
    editPerson: params => postAction('/order/mall/update-receipt ', params),
    //订单发货
    deliver: params => postAction('/order/mall/delivery', params,1*60*1000),
    //订单评论列表
    getReplyList: params => postAction('/order/mall/comment/page', params),
    //删除
    replyDel: params => postAction('/order/mall/reply/delete', params),
    //回复
    replyAnswer: params => postAction('/order/mall/reply/answer', params),
    //快递列表
    getExpressList: params => getAction('/mall/reply/page', params),
    //新增
    expressadd: params => postAction('/mall/reply/add', params),

    //删除快递公司
    expressDel: params => postAction('/order/mall/express/company/delete', params),
    // 物流公司列表
    expressList: params => getAction('/order/mall/express/company/page', params),
    //物流公司非分页
    express: params => getAction('/order/mall/express/company/list', params),
    //新增物流公司
    addExpress: params => postAction('/order/mall/express/company/add', params),
    //编辑物流公司
    editExpress: params => postAction('/order/mall/express/company/update', params),
    //新增物流模板
    // addFreight: params => postAction(ADMIN + '/mall/shop-freight/freight', params),
    addFreight: params => postAction('/product/mall/freight/add', params),
    //物流模板列表
    freightList: params => getAction('/order/mall/express/company/page', params),
    //售后订单
    AfterSalesOrder: params => postAction('/order/mall/post-sale/page', params),
    // 拒绝售后
    refused: params => postAction('/order/mall/post-sale/refused', params),
    // 同意售后
    refund: params => postAction('/order/mall/post-sale/refund', params),
    // 订单信息
    origin: params => getAction('/order/mall/post-sale/enabled/amount/' + params, {}),
    // 模板列表
    // shopFreight: params => postAction('/admin/mall/shop-freight/page', params),
    shopFreight: params => postAction('/product/mall/freight/page', params),
    // 删除模板列表
    // shopFreightRemove: params => postAction('/admin/mall/shop-freight/remove', params),
    shopFreightRemove: params => postAction('/product/mall/freight/delete', params),
    // 编辑模板列表
    shopFreightFreight: params => postAction('/admin/mall/shop-freight/freight', params),
    // 模板详情
    // shopFreightInfo: params => getAction('/admin/mall/shop-freight/get-info?id=' + params, {}),
    shopFreightInfo: params => getAction('/product/mall/freight/get-info?id=' + params, {}),

    /*--  订单取消原因  --*/
    // 获取数据
    getOrderCancel: params => postAction('/order/mall/cancel-reason/page', params),
    // 改变禁用/启用状态
    editStatus: params => postAction('/order/mall/cancel-reason/switch-state', params),
    // 新增
    addData: params => postAction('/order/mall/cancel-reason/add', params),
    // 删除
    deleteData: params => postAction('/order/mall/cancel-reason/delete', params),
    // 编辑
    updateData: params => postAction('/order/mall/cancel-reason/update', params),

    activityOrder: params => postAction('/activity/mall/event/order/page', params),

    /*--  售后申请原因  --*/
    // 获取数据
    getApplicationCause: params => postAction('/order/mall/post-sale-reason/page', params),
    // 改变禁用/启用状态
    editApplicationStatus: params => postAction('/order/mall/post-sale-reason/switch-state', params),
    // 新增
    addApplicationData: params => postAction('/order/mall/post-sale-reason/add', params),
    // 删除
    deleteApplicationData: params => postAction('/order/mall/post-sale-reason/delete', params),
    // 编辑
    updateApplicationData: params => postAction('/order/mall/post-sale-reason/update', params),

    /* 管理更新接口 */
    // 获取数据
    getUpData: params => postAction('/admin/sys/version/page', params),
    //新增
    getSaveData: params => postAction('/admin/sys/version/save', params),
    // 查看/编辑接口
    getInfoData: params => getAction('/admin/sys/version/getInfo', params),
    // 删除 /admin/sys/version/delete
    delData: params => getAction(`/admin/sys/version/del/${params}`, {}),
    //首页总览
    homeStatistics: () => getAction('/order/common/home/statistics/total'),
    //首页日销售额
    homeDayTotal: () => getAction('/order/common/home/day/total'),
    //首页交易概况
    homeDeal: () => getAction('/order/common/home/trade/view'),
    //首页门店销售排行
    homeShop: () => getAction('/order/common/home/rank/list')
}

export { order }
