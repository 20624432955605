import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Icon from 'vue-awesome/components/Icon'
import './plugins'
import './styles/index.scss'
import './mixins'
import './utils/actionApp'
import './utils/components'
import './components/installComponents'
import 'vue-awesome/icons'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper.scss'
Vue.use(VueAwesomeSwiper /* { default options with global component } */)
Vue.component('v-icon', Icon)

Vue.config.productionTip = false

const vue = new Vue({
    router,
    store,

    render: (h) => h(App),
}).$mount('#app')

export default vue