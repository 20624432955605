const components = {
    state: {
        components: [],
        pageId: 0
    },
    mutations: {
        setPageId(state, id) {
            state.pageId = id;
        },
        set_components(state, components) {
            state.components = [];
            state.components.push(...components);
        },
        set_component(state, component) {

            if (component.moduleName === 'Search') {
                state.components.splice(1, 0, component);
            } else if (component.moduleName === 'Tabs') {
                const hasSearch = state.components.some(item => item.moduleName === 'Search');
                const index = hasSearch ? 2 : 1;
                state.components.splice(index, 0, component);
            } else if (component.tabPage) {
                const index = state.components.findIndex(item => item.tabPage);
                if (index !== -1) {
                    state.components.splice(index, 1, component);
                } else {
                    state.components.push(component);
                }
            } else {
                state.components.push(component);
            }
        },
        delete_component(state, component) {
            const index = state.components.findIndex(item => item.tabPage);
            state.components.splice(index, 1);
        },
        remove_components(state) {
            state.components = [];
        }
    },
    actions: {}
};

export default components;
