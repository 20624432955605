<template>
  <el-dialog
    :title="modalService.MD.title"
    :width="modalService.MD.width"
    :modal="true"
    :lockScroll="true"
    :closeOnPressEscape="true"
    :showClose="false"
    :center="true"
    :destroyOnClose="true"
    :beforeClose="modalService.beClose"
    :visible.sync="centerDialogVisible"
  >
    <slot />
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelForm">取 消</el-button>
      <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { ModalSercice } from '@/service/ModalService'
export default {
  name: 'smart-modal',
  data () {
    return {
      centerDialogVisible: true,
      modalService: new ModalSercice()
    }
  },
  methods: {
    cancelForm () {
      this.centerDialogVisible = false
      this.$emit('onCancel')
    }
  }
}
</script>

<style>
</style>