<template>
  <keep-alive :include="includes">
    <router-view></router-view>
  </keep-alive>
</template>

<script>
export default {
  name: 'SubLayoutView',
  props: {
    includes: {
      type: Array,
    },
  },
};
</script>

<style scoped></style>
