import { Supervisor } from '@/utils/cache/Supervisor';
import { authCacheType } from '@/configs';
import { AesEncryption, RsaEncryption } from '@/utils/cipher';
import { cacheCipher } from '@/utils/cache/storage';

export const TOKEN_KEY = 'TOKEN__';
export const USER_INFO_KEY = 'USER_INFO__';
export const LOGIN_INFO_KEY = 'LOGIN_INFO__';
const isLocal = authCacheType === 'LOCAL';

export function encryptByRsa(key, password) {
  const encryption = new RsaEncryption({ key });
  return encryption.encryptByRSA(password);
}

export function decryptByRSA(key, password) {
  const encryption = new RsaEncryption({ key });
  return encryption.decryptByRSA(password);
}

export function encryptByAES(val) {
  const encryption = new AesEncryption({ ...cacheCipher });
  return encryption.encryptByAES(val);
}

export function decryptByAES(val) {
  const encryption = new AesEncryption({ ...cacheCipher });
  return encryption.decryptByAES(val);
}

export const getToken = () => {
  return getAuthCache(TOKEN_KEY);
};

export function getAuthCache(key) {
  const fn = isLocal ? Supervisor.getLocal : Supervisor.getSession;
  return fn(key);
}

export function setAuthCache(key, value) {
  const fn = isLocal ? Supervisor.setLocal : Supervisor.setSession;
  return fn(key, value);
}

export function removeAuthCache(key) {
  const fn = isLocal ? Supervisor.removeLocal : Supervisor.removeSession;
  return fn(key);
}

export function clearAuthCache() {
  const fn = isLocal ? Supervisor.clearLocal : Supervisor.clearSession;
  return fn();
}
