import { constantRoutes } from '@/router';
import LayoutView from '@/layout/LayoutView.vue';
import SubLayoutView from '@/layout/SubLayoutView.vue';

const permission = {
  state: {
    routes: [],
    addRoutes: [],
  },
  mutations: {
    SET_ROUTES(state, routes) {
      state.addRoutes = routes;
      state.routes = constantRoutes.concat(routes);
    },
  },
  actions: {
    GenerateRoutes({ commit }, routes) {
      return new Promise((resolve) => {
        const asyncRoutes = filterAsyncRoutes(routes);
        console.table(asyncRoutes, 'asyncRoutes');
        asyncRoutes.push({ path: '*', redirect: '/404', hide: true });
        commit('SET_ROUTES', asyncRoutes);
        resolve(asyncRoutes);
      });
    },
  },
};

// 遍历后台传会路由数据，转化路由组件
function filterAsyncRoutes(asyncRoutesMap) {
  return asyncRoutesMap.filter((route) => {
    // delete route.name;
    if (route.name) {
      route.name = route.path.replace(/\//g, '');
    }
    if (route.component) {
      if (route.component === 'Layout') {
        route.component = LayoutView;
      } else if (route.component === 'SubLayout') {
        route.component = SubLayoutView;
      } else {
        if (route.pid === 0) {
          if (route.children && !route.children.length) {
            route.children = [
              {
                ...route,
                pid: route.id, // 同父级id
              },
            ];
          }
          route.component = LayoutView;
          route.noSub = true;
        } else {
          route.meta = {
            ...route.meta,
            pid: route.pid,
            keep: route.cacheFlag,
          };
          route.component = loadView(route.component);
        }
      }
    } else {
      delete route.component;
    }
    if (route.children && route.children.length) {
      // 一级菜单路由重定向到第一个子菜单路由
      route.redirect = route.children[0].path;
      filterAsyncRoutes(route.children);
    }
    return route.component;
  });
}

export const loadView = (view) => {
  return (resolve) => require([`@/views/${view}`], resolve);
};

export default permission;
