/**
 * 这是从基础框架移植过来的，基础框架的很多组件需要用到
 */
import { axios } from '@/utils/request'

/**
 * 通用数据获取
 * @param {string} url
 * @param {object} params
 */
export function getAction(url, params) {
    return axios({
        url: url,
        method: 'get',
        params: params
    })
}

export function getActionParams(url, params) {
    return axios({
        url: url,
        method: 'get',
        data: params
    })
}

/**
 * 通用数据提交
 * @param {string} url
 * @param {object} params
 */
export function postAction(url, params,timeout) {
    return axios({
        url: url,
        method: 'post',
        data: params,
        timeout:timeout || 5000,
    })
}


export function postActionMultipart(url, params) {
    return axios({
        url: url,
        method: 'post',
        params: params
    })
}

export function postActionSoldOut(url, params) {
    return axios({
        url: url,
        method: 'post',
        data: params,
        headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}
    })
}

/**
 * 通用数据提交
 * @param {string} url
 * @param {object} params
 */
export function postActionUrlParams(url, params) {
    return axios({
        url: url,
        method: 'post',
        params: params
    })
}

/**
 * 通用删除
 * @param {string} url
 * @param {object} params
 */
export function deleteAction(url, params) {
    return axios({
        url: url + '/' + params,
        method: 'post'
    })
}

/**
 * 通用删除
 * @param {string} url
 * @param {object} params
 */
export function deleteJsonAction(url, params) {
    return axios({
        url: url,
        method: 'post',
        data: params
    })
}

/**
 * 通用搜索
 * @param {string} url
 * @param {object} params
 */
export function search(url, params) {
    return axios({
        url: url,
        method: 'post',
        data: params
    })
}

/**
 * 通用文件上传
 * @param {string} url
 * @param {object} params
 */
export function updateFile(url, params) {
    return axios({
        url: url,
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}

/**
 * 通用formData
 * @param {string} url
 * @param {object} params
 */
export function formDataAction(url, params) {
    let form = new FormData()
    for (const key in params) {
        form.append(key, params[key])
    }
    return axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        },
        data: form
    })
}

/**
 * 文件导出请求
 * @param url 请求地址
 * @param entity 请求参数
 * @returns {AxiosPromise} responseType
 */
export function exportEntity(url, entity) {
    return axios({
        method: 'post',
        url: url,
        data: entity,
        timeout: 60000,
        responseType: 'blob'
    });
}

export default {
    getAction, getActionParams, postAction, postActionUrlParams, deleteAction, deleteJsonAction, search, updateFile,formDataAction, exportEntity
}
