import axios from 'axios'
import {
    getToken,
    removeToken,
    // setToken,
    // getTokenTime,
    // getTokenExpiresIn,
    removeExpiresIn,
    removeTokenTime,
    removeRefreshToken,
    removeSys
} from './auth'
import router from '../router'
import store from '../store'
import { Notification, MessageBox } from 'element-ui'


let baseURL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API : '/api'
// const CancelToken = axios.CancelToken;
const service = axios.create({
    baseURL: baseURL,
    timeout: 5000
})

// 请求拦截器
service.interceptors.request.use(
    config => {
        const token = getToken()
        if (token && config.url !== '/uaa/auth/token/refresh') {
            config.headers['Authorization'] = 'Bearer ' + token
        }
        // if (config.data.rechargeId) {
        //     config.headers['Content-Type'] = 'multipart/form-data; charset=UTF-8'
        // }
        // if (config.data.need) {
        //     config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
        // } else {
        //     config.headers['Content-Type'] = 'application/json; charset=UTF-8'
        // }
        // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        // const request = JSON.stringify(config.url) + JSON.stringify(config.method) + JSON.stringify(config.data || '');
        // config.cancelToken = new CancelToken(cancel => {
        //     sources[request] = cancel;
        // });
        return config
    },
    error => {
        return new Promise.reject(error)
    }
)
// 是否正在刷新的标记 -- 防止重复发出刷新token接口
let isRefreshing = false;
// 判断token是否失效: return: true为过期
function isOAverdue() {
    // 当离过期时间还有半小时时, 也判断为过期
    // getTokenItem('time'): 获取存入localStorage的过期时间
    // debugger
    let tokenExpireTime = Number(localStorage.getItem('TokenTime'));
    if(tokenExpireTime == 0){
        return false;
    }
    return (tokenExpireTime - Date.now()) / 1000 <= 300
}
// 失效后同时发送请求的容器 -- 缓存接口
let subscribers = [];
// 刷新 token 后, 将缓存的接口重新请求一次
function onAccessTokenFetched(newToken) {
    subscribers.forEach((callback) => {
        callback(newToken);
    });
    // 清空缓存接口
    subscribers = [];
}
// 添加缓存接口
function addSubscriber(callback) {
    subscribers.push(callback);
}
// 响应拦截器
service.interceptors.response.use(
    response => {
        const code = response.data.status
        if (isOAverdue()&&response.config.url!=='/uaa/auth/token/refresh') {
            if (!isRefreshing) {
                isRefreshing = true;
                // 将刷新token的方法放在vuex中处理了
                store.dispatch('refreshToken2').then((res) => {
                    // 当刷新成功后, 重新发送缓存请求
                    if (res) {
                        onAccessTokenFetched(res);
                    } else {
                        // Notification.error({
                        //     title: '长时间无操作，请重新登录！',
                        //     duration: 1000
                        // })
                        MessageBox.confirm(
                            '登录状态已过期，您可以继续留在该页面，或者重新登录',
                            '系统提示',
                            {
                                confirmButtonText: '重新登录',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }
                        ).then(() => {
                            removeToken()
                            removeExpiresIn()
                            removeTokenTime()
                            removeRefreshToken()
                            removeSys()
                            localStorage.removeItem('currSys')
                            isRefreshing = false;
                            location.pathname = '/'
                        })
                    }
                }).catch(() => {
                    // 刷新token报错的话, 就需要跳转到登录页面
                    removeToken()
                    removeExpiresIn()
                    removeTokenTime()
                    removeRefreshToken()
                    removeSys()
                    localStorage.removeItem('currSys')
                    isRefreshing = false;
                    location.pathname = '/'
                    router.replace({ path: '/login' })
                }).finally(() => {
                    isRefreshing = false;
                });
            }
            // 将其他接口缓存起来 -- 这个Promise函数很关键
            const retryOriginalRequest = new Promise((resolve) => {
                // 这里是将其他接口缓存起来的关键, 返回Promise并且让其状态一直为等待状态,
                // 只有当token刷新成功后, 就会调用通过addSubscriber函数添加的缓存接口,
                // 此时, Promise的状态就会变成resolve
                addSubscriber((newToken) => {
                    // 表示用新的token去替换掉原来的token
                    response.config.headers.Authorization = `Bearer ${newToken}`;
                    // 替换掉url -- 因为baseURL会扩展请求url
                    response.config.url = response.config.url.replace(response.config.baseURL, '');
                    // 用重新封装的config去请求, 就会将重新请求后的返回
                    resolve(service(response.config));
                });
            });
            return retryOriginalRequest;
        } else if(code === 10002 || code === 10001)  {
            MessageBox.confirm(
                '登录状态已过期，您可以继续留在该页面，或者重新登录',
                '系统提示',
                {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).then(() => {
                store.dispatch('Logout').then(() => {
                })
            })
        } else if (code < 200 || code > 300){
            Notification.error({
                title: response.data.message
            })
            return Promise.reject('error')
        } else {
            return response&&response.data
        }
        // if (code < 200 || code > 300) {
        //     Notification.error({
        //         title: response.data.message
        //     })
            if (code === 10002 || code === 10001) {
                MessageBox.confirm(
                    '登录状态已过期，您可以继续留在该页面，或者重新登录',
                    '系统提示',
                    {
                        confirmButtonText: '重新登录',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }
                ).then(() => {
                    store.dispatch('Logout').then(() => {
                        removeToken()
                        removeExpiresIn()
                        removeTokenTime()
                        removeRefreshToken()
                        isRefreshing = false;
                        location.reload() // 为了重新实例化vue-router对象 避免bug
                    })
                })
            }
        //     return Promise.reject('error')
        // } else {
        //     return response.data
        // }
    },
    error => {
        let code = 0
        try {
            code = error.response.status
        } catch (e) {
            if (error.toString().indexOf('Error: timeout') !== -1) {
                Notification.error({
                    title: '网络请求超时',
                    duration: 5000
                })
                return Promise.reject(error)
            }
        }
        if (code) {
            if (code === 10002 || code === 10001) {
                MessageBox.confirm(
                    '登录状态已过期，您可以继续留在该页面，或者重新登录',
                    '系统提示',
                    {
                        confirmButtonText: '重新登录',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }
                ).then(() => {
                    store.dispatch('Logout').then(() => {
                        removeToken()
                        removeExpiresIn()
                        removeTokenTime()
                        removeRefreshToken()
                        isRefreshing = false;
                        location.reload() // 为了重新实例化vue-router对象 避免bug
                    })
                })
            } else if (code === 10102) {
                Notification.error({
                    title: error.response.data.message,
                    duration: 5000
                })
            } else {
                const errorMsg = error.response.data.message
                if (errorMsg !== undefined) {
                    Notification.error({
                        title: errorMsg,
                        duration: 5000
                    })
                }
            }
        } else {
            Notification.error({
                title: '接口请求失败',
                duration: 5000
            })
        }
        // return Promise.reject(error)
    }
)

export { service as axios }
