<template>
  <div class="input">
    <div style="display: flex; align-items: center; justify-content: space-between; flex-wrap: wrap">
      <div v-if="hasAdd" style="margin-bottom: 5px">
        <slot></slot>
      </div>
      <div style="display: flex; flex-wrap: wrap">
        <div v-for="item in this.$props.queryArray" :key="item.key" style="display: flex">
          <!-- 输入框 -->
          <el-col v-if="item.type === 'input'" style="margin-right: 10px">
            <el-input v-model="formState[item.fieldCode]" clearable :placeholder="item.placeholder"></el-input>
          </el-col>
          <!-- 选择框 -->
          <el-col v-else-if="item.type === 'select'" style="margin-right: 10px">
            <el-select
              @change="handleSearch"
              :style="{ width: item.width }"
              style="width: 120px"
              clearable
              v-model="formState[item.fieldCode]"
              :placeholder="item.placeholder"
            >
              <el-option v-for="p in item.options" :key="p.value" :label="p.label" :value="p.value"> </el-option>
            </el-select>
          </el-col>
          <!-- 字典选择 -->
          <el-col v-else-if="item.type === 'dict'" style="margin-right: 10px">
            <smart-dict
              ref="dict"
              :style="{ width: item.width || '100%' }"
              :value="formState[item.fieldCode]"
              :keyLabel="item.keyLabel"
              :keyValue="item.keyValue"
              :lazy="true"
              :placeholder="item.placeholder"
              :url="item.url"
              @updateValue="(e) => (formState[item.fieldCode] = e)"
            />
          </el-col>

          <!-- 日期选择 -->
          <el-col v-else-if="item.type === 'date'" style="margin-right: 10px">
            <el-date-picker
              v-model="time"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-col>
          <!-- 日期时间选择 -->
          <el-col v-else-if="item.type === 'dateRange'" style="margin-right: 10px">
            <el-date-picker
              v-model="time"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              :start-placeholder="item.placeholder1"
              :end-placeholder="item.placeholder2"
            >
            </el-date-picker>
          </el-col>

          <!-- 选择框输入框联动 -->
          <el-col v-else-if="item.type === 'selectToInput'" style="margin-right: 10px">
            <el-input :placeholder="item.placeholder" v-model="formState[item.fieldCode]" class="input-with-select">
              <el-select v-model="value" slot="prepend" placeholder="请选择" style="min-width: 125px">
                <el-option v-for="p in item.options" :key="p.value" :label="p.label" :value="p.value"> </el-option>
              </el-select>
            </el-input>
          </el-col>
        </div>
        <div>
          <el-button type="primary" icon="el-icon-search" size="small" @click="handleSearch">查询</el-button>
          <el-button type="warning" icon="el-icon-refresh-right" size="small" @click="handleReset">重置</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'smart-search',
  props: {
    queryArray: {
      type: Array,
      require: true,
      default: () => [],
    },
    defaultValue: {
      type: Object,
      require: false,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      hasAdd: false,
      input: '',
      options: [],
      value: '',
      query: [],
      time: [],
      formState: {},
      shopId: '',
    };
  },
  watch: {
    value(val, old) {
      this.$props.queryArray.forEach((it) => {
        const { fieldCode, type } = it;
        if (type === 'selectToInput') {
          it.fieldCode = val;
          delete this.formState[fieldCode] === old;
        }
      });
    },
    shopId(val,old) {
      this.formState['shopId'] = val
    }
  },
  mounted() {
    this.slotLen();
    window.addEventListener('keydown', this.enter);
  },
  async created() {
    console.log(111,this.$props.queryArray)
    this.$props.queryArray.forEach((it) => {
      const { type, fieldCode,url } = it
      if (type == 'dict' && fieldCode == 'shopId') {
        url().then(res=>{
          let data = res.data[0]
          console.log(11111,data.id)
          this.shopId = data.id;
        })
       
      }
    })
    
  },
  destroyed() {
    window.removeEventListener('keydown', this.enter);
  },
  methods: {
    enter(e) {
      if (e.keyCode === 13) {
        this.handleSearch(); //搜索请求
      }
    },
    dateFomat() {
      this.$props.queryArray.forEach((it) => {
        const { fieldCode, multiple, type } = it;
        if (type === 'date' || type === 'dateRange') {
          console.log('time--------->', this.time);
          if (this.time && this.time.length) {
            this.formState[it.start] = this.time[0];
            this.formState[it.end] = this.time[1];
          } else {
            this.formState[it.start] = '';
            this.formState[it.end] = '';
          }
        }
      });
    },
    async handleSearch() {
      this.dateFomat();
      const obj = { ...this.formState };
      for (const item in obj) {
        if (Array.isArray(obj[item])) {
          obj[item] = obj[item].join(',');
        }
      }
      this.$emit('on-handler', obj);
    },
    // 检查插槽是否有值
    slotLen() {
      if (this.$slots.default) {
        this.hasAdd = true;
      } else {
        this.hasAdd = false;
      }
    },
    /**
     * @description 初始化查询参数
     * */
    init() {
      const defKeys = Object.keys(this.$props.defaultValue);
      this.$props.queryArray.forEach((it) => {
        const { fieldCode, type } = it;

        if (type === 'date' || type === 'dateRange') {
          // this.formState[it.start] = null;
          // this.formState[it.end] = null;
          this.$set(this.formState, it.start, null);
          this.$set(this.formState, it.end, null);
          this.time = [];
        } else if (type === 'selectToInput') {
          for (const key in this.formState) {
            // this.formState[key] = null;
            this.$set(this.formState, key, null);
          }
        } else {
          this.$set(
            this.formState,
            fieldCode,
            defKeys.includes(fieldCode) ? this.$props.defaultValue[fieldCode] : null
          );
          // this.formState[fieldCode] = defKeys.includes(fieldCode) ? this.$props.defaultValue[fieldCode] : null;
        }
      });
    },
    async handleReset() {
      //有级联选择器的时候要清空
      if (this.$refs.dict?.length) {
        this.$refs.dict.forEach((v, index) => {
          this.$refs.dict[index].state.selected = [];
        });
      }
      await this.init();
      this.$emit('on-handler', this.formState);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
  margin-bottom: 5px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.input .el-range-separator {
  min-width: 23px;
}
.input .el-form-item--small {
  margin-bottom: 0;
}
.el-select-dropdown__item {
  text-align: center;
}
</style>
