export const EmptyUtil = {
  isEmptyString (data) {
    return typeof data === 'undefined' || data == null || data === ''
  },

  isNotEmptyString (data) {
    return !this.isEmptyString(data)
  },

  isEmptyObject (data) {
    return data === null || undefined === data || Object.keys(data).length === 0
  },

  isNotEmptyObject (data) {
    return !this.isEmptyObject(data)
  },

  isEmptyArray (data) {
    if (data === null || data === undefined) {
      return true
    }
    return data.length === 0
  },

  isNotEmptyArray (data) {
    return !this.isEmptyArray(data)
  },

  filterObjEmpty (data) {
    for (const key in data) {
      if (data[key] == null || data[key] === undefined || data[key] === ' ' || data[key] === '') {
        delete data[key]
      }
    }
    return data
  }
}
