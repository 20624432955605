import { getAction, postAction } from './manage'

const fileSetting = {
    //数据
    getData: params => getAction('common-api/file/setting/info', params),
    //新增
    addData: params => postAction('common-api/file/setting/save', params),
}

const fileGroup = {
    //列表数据
    getData: params => getAction('admin/file/group/tree-list', params),
    //新增
    addData: params => postAction('admin/file/group/add', params),
    //编辑
    updateData: params => postAction('admin/file/group/update', params),
    //删除
    removeData: params => postAction('admin/file/group/delete', params),
    // 修改状态
    editStatus: (params) => postAction('admin/file/group/switch-status', params),

    //（单个、批量）删除 图片 by lc
    removePic: params => postAction('admin/file/upload/delete', params),
    // 修改图片名 by lc
    updatePicName: params => postAction('admin/file/upload/update', params),
}
const fileUpload = {
    getData: params => getAction('admin/file/upload/page',params)
}
export {
    fileSetting,fileGroup,fileUpload,
}
