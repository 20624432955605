<template>
  <div :id="id" v-loading="loading" class="rz-drawer rz-modal">
    <div v-if="showModal" :class="{down:down}" :style="{zIndex:zIndex}" class="rz-drawer-mask"
         @click="modalClick"></div>
    <div :class="{show:show,hidden:!show}" :style="{width,zIndex:zIndex+1}" class="rz-drawer-wrap">
      <div class="rz-drawer-wrap-header">
        {{ title }}
        <i class="el-icon-close" @click="close"></i>
      </div>
      <div class="rz-drawer-wrap-body">
        <component :is="content" ref="myComponent" v-model="componentData" v-bind="comProps"
        ></component>
      </div>
      <div v-if="showBtns" class="rz-drawer-wrap-btn">
        <el-button v-if="showCancel" size="small" @click="cancel">{{ cancelBtnText }}</el-button>
        <el-button v-if="showConfirm" size="small" type="primary" @click="confirm">{{
            confirmBtnText
          }}
        </el-button>
        <el-button v-for="(item,index) in otherBtns" :key="index" :type="item.type" size="small"
                   @click="otherClick(item)">
          {{
            item.text
          }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {props} from "./dialog";

export default {
  name: "rz-drawer",
  props: {
    ...props
  },
  data() {
    return {
      componentData: null,
      show: false,
      loading: false,
      down: false,
      zIndex: 0
    }
  },
  computed: {
    //父组件传递的业务数据
    comProps() {
      return {...this.query}
    }
  },
  mounted() {
    this.show = true
    const hasModal = document.getElementsByClassName('rz-modal')
    this.zIndex = 20 + hasModal.length
  },
  methods: {
    otherClick(item) {
      item.handleClick && typeof item.handleClick === 'function' && item.handleClick(this.close, this.componentData, this.$refs.myComponent)
    },
    modalClick() {
      if (this.closeByClickModal) {
        this.close()
      }
    },
    close() {
      this.show = false
      this.down = true
      setTimeout(() => {
        document.getElementById(this.id).remove()
      }, 300)
    },
    cancel() {
      if (this.onCancel && typeof this.onCancel === 'function') {
        this.onCancel(this.close, this.componentData, this.$refs.myComponent)
      } else {
        this.close()
      }
    },
    async confirm() {
      if (this.formRef) {
        const ref = this.$refs.myComponent.$refs[this.formRef]
        await ref.validate()
      }
      this.onConfirm && typeof this.onConfirm === 'function' && this.onConfirm(this.close, this.componentData, this.$refs.myComponent)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.rz-drawer {
  &-mask {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .3);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1111;

    &.down {
      animation: down 300ms linear;
    }
  }

  &-wrap {
    background: #FFFFFF;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1112;
    @include commonShadow;

    &.hidden {
      right: -100%;
      animation: hidden .35s linear;
    }

    &.show {
      animation: active .35s linear;
    }

    &-header {
      height: 40px;
      line-height: 40px;
      position: relative;
      text-align: center;
      font-size: 20px;
      //@include commonBorder;

      i {
        position: absolute;
        right: 20px;
        font-size: 24px;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);

        &:hover {
          color: $--color-primary;
        }

        &:active {
          opacity: .78;
        }
      }
    }

    &-body {
      height: calc(100vh - 90px);
      overflow-y: auto;
      padding: 40px 30px;
      box-sizing: border-box;
    }

    &-btn {
      height: 50px;
      line-height: 50px;
      text-align: center;

    }
  }
}

@keyframes hidden {
  0% {
    right: 0;
  }
  50% {
    right: -50%;
  }
  100% {
    right: -100%
  }
}

@keyframes active {
  0% {
    right: -100%
  }
  50% {
    right: -50%;
  }
  100% {
    right: 0;
  }
}

@keyframes down {
  0% {
    opacity: 1
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 0;
  }
}

</style>
