export class ObjUtil {
  /**
   * 在原对象内挑选目标对象的KEY值放到新对象内
   * @param  target 目标对象
   * @param  source 源对象
   * */
  static pick (target, source) {
    // 目标KEYS
    const targetKeys = Object.keys(target)
    // 源头KEYS
    const sourceKeys = Object.keys(source)
    // 深拷贝一次源数据
    const copySource = JSON.parse(JSON.stringify(source))
    const copyTarget = { ...target }
    const result = {}
    targetKeys.forEach(keyName => {
      // 目标key 在源Key之内
      if (sourceKeys.includes(keyName)) {
        // 同时存在label 和value 属性的值 标识这是一个字典 直接取字典值
        if (copySource[keyName]?.label !== undefined && copySource[keyName]?.value !== undefined) result[keyName] = copySource[keyName].value
        // 如果目标字段需要一个对象 但是当前字段值是空指定 那么直接复制目标对象到服务
        else if (typeof copyTarget[keyName] === 'object' && copyTarget[keyName] !== null && copySource[keyName] === null) result[keyName] = { ...copyTarget[keyName] }
        // 直接原等赋值
        else result[keyName] = copySource[keyName]

      } else {
        result[keyName] = copyTarget[keyName]
      }
    })

    return result
  }

  // 对象深复制
  static deepCopy = data => JSON.parse(JSON.stringify(data))
}
