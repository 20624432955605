// 基础组件的自动化全局注册
import Vue from 'vue'
const requireComponent = require.context('./modules', true, /\.vue$/)
requireComponent.keys().forEach(fileName => {
  // 获取组件配置
  const componentConfig = requireComponent(fileName)
  console.log(componentConfig, '0000')
  // 全局注组件册
  Vue.component(
    componentConfig.default.name,
    componentConfig.default
  )
})