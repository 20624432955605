<template>
        <div style="height: 100%;">
          <div class="login">

          
            <div class="container">
              <!-- <div class="left-img-box">
                <div class="logo"></div>
              </div> -->
              <div class="form-list">
                <div class="title">
                  <img src="@/assets/logo_index_black.png" alt="">
                  <div style="padding-top: 20px;"> EMBRACE THE NATURE,EQUIP FOR ADVENTURE </div>
                </div>
                <FromList/>
              </div>
            </div>
            <div style="text-align: center">
              <p>@2023 - 2024 Intelligence Dolphin(ChengDu) Network Technologyco.,Ltd.</p >
              <p><span @click="onProtocol" class="curs">隐私政策</span> | <span class="curs">使用条款</span> | <span class="curs">城建问题</span></p >
            </div>
        </div>
        <!-- <div class="footer">
          <div class="mr-2 text-info">Copyright &copy;{{ copyYear }} 蔻诗慕娜（广州）化妆品有限公司</div>
          <div class="copyright">
            <el-link :underline="false" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023102054号</el-link>
          </div>
        </div> -->
      </div>
    
</template>

<script>
import FromList from './LoginView.vue'
import router, { constantRoutes } from '@/router';
export default{
  components:{
    FromList
  },
  methods:{
    onProtocol(){
      // router.push('/protocol').catch(() => {});
      this.$router.push({path:'/protocol'});
    }
  }
}
</script>

<style lang="scss" scoped>
.curs {
  cursor: pointer;
}
.login{
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  .container{
    // width: 730px;
    // height: 540px;
     width: 100%;
    height: 100%;
    margin: auto;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .left-img-box{
      // width: 330px;
      width: 1200px;
      height: 100%;
      background: url('@/assets/beijing.png') no-repeat center center;
      background-size: cover;
      .logo{
        width: 400px;
        height: 100px;
        z-index: 999;
        margin:  auto;
       transform: translate(-42%,250%);
        object-fit: cover;
        background: url('@/assets/cos1White.png') no-repeat center center;
        background-size: 100% 100%;
      }
   
    }
    .form-list{
      // width: calc(100% - 330px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 30%;
        padding: 10px 30px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        .title{
          font-size: 20px;
          font-weight: bold;
          width: 100%;
          box-sizing: border-box;
          // padding: 0 35px;
          text-align: center;
          img{
            // width: 318px;
            height: 80px;
          }
        }
      }
  }
}
.footer {
  width: 100%;
  height: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  ::v-deep .el-link--inner {
    color: rgb(187, 183, 183);
    font-size: 12px;
  }
   .text-info {
    color: rgb(187, 183, 183);
  }
}
</style>