import { createLocalStorage, createSessionStorage } from '@/utils/cache/index';

const ls = createLocalStorage();
const ss = createSessionStorage();

export class Supervisor {
  static getLocal(key) {
    return ls.get(key);
  }

  static setLocal(key, value) {
    ls.set(key, value);
  }

  static removeLocal(key) {
    ls.remove(key);
  }

  static clearLocal() {
    ls.clear();
  }

  static getSession(key) {
    return ss.get(key);
  }

  static setSession(key, value) {
    ss.set(key, value);
  }

  static removeSession(key) {
    ss.remove(key);
  }

  static clearSession() {
    ss.clear();
  }
}
