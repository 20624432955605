<template>
  <div id="app">
    <HeaderView v-if="layout.hasHeader && token && route.path !== '/login'"></HeaderView>
    <router-view has-header v-if="showRouter"></router-view>
  </div>
</template>
<script>
import { layout } from '@/configs/layout'
import { mapGetters } from 'vuex'
import HeaderView from '@/layout/HeaderView'

export default {
  name: 'App',
  provide () {
    return {
      reload: this.reload
    }
  },
  components: {
    HeaderView,
  },
  data () {
    return {
      showRouter: true,
      layout,
    }
  },
  computed: {
    ...mapGetters(['token']),
    route () {
      return this.$route
    },
  },
  methods: {
    reload () {
      this.showRouter = false
      this.$nextTick(() => {
        this.showRouter = true
      })
    }
  }
};
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
  overflow: auto;
  background-color: #f5f5f5;
}
.el-drawer__body {
  display: flex;
  flex-direction: column;
  .drawer__header {
    font-size: 25px;
    box-sizing: border-box;
    padding: 0 5px 5px 5px;
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 5px;
  }
  .drawer__content {
    flex: 1;
    box-sizing: border-box;
    padding: 0 5px 5px 5px;
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 5px;
  }
  .drawer__footer {
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 5px 5px;
  }
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
