import aes from 'crypto-js/aes';
import UTF8 from 'crypto-js/enc-utf8';
import pkcs7 from 'crypto-js/pad-pkcs7';
import ECB from 'crypto-js/mode-ecb';
import md5 from 'crypto-js/md5';
import Base64 from 'crypto-js/enc-base64';
import { salt } from '@/configs';
import { JSEncrypt } from 'jsencrypt';

export const { encrypt, decrypt } = aes;
const { parse } = UTF8;
const _JSEncrypt = new JSEncrypt();

export class AesEncryption {
  #iv;
  #key;

  constructor(opt = {}) {
    const { key, iv } = opt;
    if (key) {
      this.#key = parse(key);
    }
    if (iv) {
      this.#iv = parse(iv);
    }
  }

  get getOptions() {
    return {
      mode: ECB,
      padding: pkcs7,
      iv: this.#iv,
    };
  }

  encryptByAES(cipherText) {
    return encrypt(cipherText, this.#key, this.getOptions).toString();
  }

  decryptByAES(cipherText) {
    return decrypt(cipherText, this.#key, this.getOptions).toString(UTF8);
  }
}

export class RsaEncryption {
  #key;

  constructor(opt = {}) {
    const { key } = opt;
    if (key) {
      this.#key = key;
      _JSEncrypt.setKey(key);
    }
  }

  encryptByRSA(cipherText) {
    return _JSEncrypt.encrypt(cipherText);
  }

  decryptByRSA(cipherText) {
    return _JSEncrypt.decrypt(cipherText);
  }
}

export function encryptByBase64(cipherText) {
  return UTF8.parse(cipherText).toString(Base64);
}

export function decodeByBase64(cipherText) {
  return Base64.parse(cipherText).toString(UTF8);
}

export function encryptByMd5(password) {
  const str = salt.charAt(0) + salt.charAt(3) + password + salt.charAt(5) + salt;
  return md5(str).toString().toUpperCase();
}
