import { EmptyUtil } from '@/utils/emptyUtils.js'
import { ObjUtil } from '@/utils/objUtils'
import { Message,Notification } from 'element-ui'
import Vue from 'vue'
export class ModalSercice {

  isUpdate

  isBelow

  rawRecord

  formState

  formRef = null

  static emit

  MD = Vue.observable({
    title: '',
    width: '640',
    centerDialogVisible: true,
  })
  constructor(dto, rawRecord, emit) {

    if (typeof emit === 'function') {
      this.emit = emit
    } else {
      this.emit = () => { }
      Message({
        showClose: true,
        message: 'emit不是一个函数',
        type: 'error'
      })
    }
    this.rawRecord = rawRecord
    // eslint-disable-next-line no-prototype-builtins
    this.isUpdate = !EmptyUtil.isEmptyObject(rawRecord) && !rawRecord.hasOwnProperty('AddBelow')
    if (this.isUpdate) {
      this.formState = ObjUtil.pick(dto, rawRecord)
    } else {
      // 深拷贝一次对象
      const data = dto
      this.formState = { ...data, ...rawRecord }
    }
  }

  /**
    * @desc 修改窗口宽度
    * @param title 窗口标题
    * @param width 窗口宽度数值
    * */
  setMD (title, width = '400') {
    if (width <= 200) {
      this.$message.error('200')
      return
    }
    this.MD.width = width
    this.MD.title = title
    return this
  }


  /**
    * @desc 配置数据新增的执行方法
    * @param request 执行数据新增的执行方法
    * */
  setAddRequest (request) {
    this.addRequest = request
    return this
  }
  /**
     * @desc 配置数据修改的执行方法
     * @param request 执行数据修改的执行方法
     * */
  setUpdateRequest (request) {
    this.updateRequest = request
    return this
  }


  /**
    * 执行数据新增操作
    * */
  addAction = async () => {
    if (typeof this.addRequest !== 'function') throw new Error('尚未初始化数据新增的执行方法,setAddXhr 方法进行初始化')
    const { data } = await this.addRequest(this.formState)
    // Message({
    //   showClose: true,
    //   message: '添加成功',
    //   type: 'success'
    // })
    Notification({
      type: 'success',
      title: '添加成功',
    })
    this.cancelForm('RELOAD')
  }


  /**
     * 执行数据修改操作
     * */
  updateAction = async () => {
    if (typeof this.updateRequest !== 'function') throw new Error('尚未初始化数据修改的执行方法,updateXhr 方法进行初始化')
    const key = this.rawRecord[this.rowKeyName]
    const { data } = await this.updateRequest({ ...this.formState, id: this.rawRecord.id })
    Notification({
      type: 'success',
      title: '编辑成功',
    })
    // Message({
    //   showClose: true,
    //   message: data || '编辑成功',
    //   type: 'success'
    // })
    this.cancelForm('RELOAD')
  }








  /**
    * @desc 关闭之前的回调
    * */
  beClose = (e) => {
    this.formRef && this.formRef.resetFields()
    this.centerDialogVisible = false
    this.emit('cancel', e)
  }

  // 获取ref表单对象
  getRefFrom (dom) {
    this.formRef = dom
    return this
  }
  // 提交表单
  async submitForm () {
    await this.formRef.validate(async (valid) => {
      if (valid) {
        this.isUpdate ? await this.updateAction() : await this.addAction()
      }
      else {
        console.log('error submit!!')
        return false
      }
    })
  }
  // 取消表单
  cancelForm (e = 'NONE') {
    this.beClose(e)
  }
}