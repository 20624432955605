<template>
    <div style="height: 100%;">
      <div v-html="policy"></div>
    </div>
  
  </template>
  
  <script>
  import { shopConfig } from '@/api/shopConfig'
  export default{
    data () {
      return {
        policy: null
      }
    },
    mounted(){
        shopConfig.getAppPolicy({tenantId:1}).then((res) => {
          if (res.status === 200) {
            this.policy = res.data
          }
        })
    },
    methods:{
        
    }
  }
  </script>
  
  <style lang="scss" scoped>
  
  </style>