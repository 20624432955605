import axios from 'axios';
import store from '@/store';
import { getToken, removeAuthCache, TOKEN_KEY } from './auth';
import { Message, MessageBox } from 'element-ui';
import router from '@/router';
import { baseUrl, frontProxy } from '@/configs';

const is = (val, type) => {
  return Object.prototype.toString.call(val) === `[object ${type}]`;
};
const errorTip = (msg) => {
  msg = `<div class="text_left"  style="white-space: pre-wrap;">${msg}</div>`;
  MessageBox.alert(msg, '', {
    confirmButtonText: '确定',
    customClass: 'hide_header',
    center: true,
    showCancelButton: false,
    type: undefined,
    dangerouslyUseHTMLString: true,
  }).catch(() => {});
};

let preMessage = '';

export class VAxios {
  #axiosInstance;
  #options;

  constructor(options) {
    this.#options = options;
    Object.freeze(this.#options);
    this.#createAxios(options);
    this.#setInterceptors();
  }

  #createAxios = (config) => {
    this.#axiosInstance = axios.create(config);
  };
  #getTransform = () => {
    const { transform } = this.#options;
    return transform;
  };
  #setInterceptors = () => {
    const transform = this.#getTransform();
    if (!transform) {
      return;
    }
    const { requestInterceptors, requestInterceptorsCatch, responseInterceptors, responseInterceptorsCatch } =
      transform;
    const axiosCancel = new AxiosCancel();
    // 请求拦截器
    this.#axiosInstance.interceptors.request.use((config) => {
      const {
        headers: { ignoreCancelToken },
      } = config;
      const ignoreCancel =
        ignoreCancelToken !== undefined ? ignoreCancelToken : this.#options.requestOptions?.ignoreCancelToken;
      !ignoreCancel && axiosCancel.addPending(config);
      if (requestInterceptors && is(requestInterceptors, 'Function')) {
        config = requestInterceptors(config, this.#options);
      }
      return config;
    }, undefined);
    requestInterceptorsCatch &&
      is(requestInterceptorsCatch, 'Function') &&
      this.#axiosInstance.interceptors.request.use(undefined, requestInterceptorsCatch);
    // 响应拦截器
    this.#axiosInstance.interceptors.response.use((res) => {
      res && axiosCancel.removePending(res.config);
      if (responseInterceptors && is(responseInterceptors, 'Function')) {
        res = responseInterceptors(res);
      }
      return res;
    }, undefined);
    responseInterceptorsCatch &&
      is(responseInterceptorsCatch, 'Function') &&
      this.#axiosInstance.interceptors.response.use(undefined, responseInterceptorsCatch);
  };

  get(config, options) {
    return this.request({ ...config, method: 'GET' }, options);
  }

  post(config, options) {
    return this.request({ ...config, method: 'POST' }, options);
  }

  put(config, options) {
    return this.request({ ...config, method: 'PUT' }, options);
  }

  delete(config, options) {
    return this.request({ ...config, method: 'DELETE' }, options);
  }

  request(config, options) {
    let conf = config;
    const transform = this.#getTransform();
    const { beforeRequestHook, transformRequestHook } = transform || {};
    const { requestOptions } = this.#options;
    const opt = Object.assign({}, requestOptions, options);
    conf = beforeRequestHook(config, opt);
    conf.requestOptions = opt;
    return new Promise((resolve, reject) => {
      this.#axiosInstance
        .request(conf)
        .then((res) => {
          if (transformRequestHook && is(transformRequestHook, 'Function')) {
            const ret = transformRequestHook(res, opt);
            resolve(ret);
            return;
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

let pendingMap = new Map();
export const getPendingUrl = (config) => [config.method, config.url].join('&');

export class AxiosCancel {
  /**
   * 添加pending请求
   * @param config
   */
  addPending(config) {
    const url = getPendingUrl(config);
    config.cancelToken =
      config.cancelToken ||
      new axios.CancelToken((cancel) => {
        if (!pendingMap.has(url)) {
          pendingMap.set(url, cancel);
        }
      });
  }

  /**
   * 移除pending请求
   */
  removeAllPending() {
    pendingMap.forEach((cancel) => {
      cancel && is(cancel, 'Function') && cancel();
    });
    pendingMap.clear();
  }

  /**
   * 移除重复请求
   * @param config
   */
  removePending(config) {
    const url = getPendingUrl(config);
    if (pendingMap.has(url)) {
      const cancel = pendingMap.get(url);
      cancel && cancel(url);
      pendingMap.delete(url);
    }
  }

  reset() {
    pendingMap = new Map();
  }
}

function deepMerge(src, target) {
  let key;
  for (key in target) {
    // eslint-disable-next-line no-prototype-builtins
    if (target.hasOwnProperty(key)) {
      src[key] = is(src[key], 'Object') ? deepMerge(src[key], target[key]) : target[key];
    }
  }
  return src;
}

function checkStatus(status, msg, errorMessageMode) {
  let errorMsg = '';
  let messageInstance = null;
  const error = (errorMsg) => {
    messageInstance?.close();
    messageInstance = Message.error(errorMsg);
  };
  switch (status) {
    case 400:
      errorMsg = msg;
      break;
    case 401:
      errorMsg = `用户没有权限（令牌、用户名、密码错误）!`;
      // TODO 退出登录
      break;
    default:
      break;
  }
  if (errorMsg) {
    if (errorMessageMode === 'model') {
      //
    } else if (errorMessageMode === 'message') {
      error(errorMsg);
    }
  }
}

const transform = {
  beforeRequestHook: (config, options) => {
    const { apiUrl, onUploadProgress, postForm } = options;
    if (apiUrl) {
      config.url = `${apiUrl}${config.url}`;
    }
    if (onUploadProgress) {
      config.onUploadProgress = onUploadProgress;
    }
    const params = config.params || {};
    const data = config.data || null;
    const method = config.method;
    if (method?.toUpperCase() === 'GET') {
      if (is(params, 'String')) {
        config.url = config.url + params;
        config.params = undefined;
      }
    } else {
      if (Reflect.has(config, 'data') && data && Object.keys(data).length > 0) {
        config.data = data;
        config.params = params;
      } else {
        if (!postForm) {
          config.data = params;
          config.params = undefined;
        }
      }
    }
    return config;
  },
  transformRequestHook: (res, options) => {
    const { errorMessageMode, isReturnNativeResponse, isTransformResponse } = options;
    if (isReturnNativeResponse) {
      return res;
    }
    if (!isTransformResponse) {
      return res.data;
    }
    const { data } = res;
    if (!data) {
      throw new Error('请求出错');
    }
    // 根据后端返回结果定制
    const { status, message, data: result } = data;
    if (status === 200) {
      return result;
    }
    let errorMsg = '';
    switch (status) {
      case 10002:
        if (message) {
          errorMsg = message;
        }
        store?.user?.commit('SET_TOKEN', '');
        removeAuthCache(TOKEN_KEY);
        router.push('/login').catch(() => {});
        break;
      default:
        if (message) {
          errorMsg = message;
        }
        break;
    }
    if (errorMessageMode === 'model') {
      errorTip(errorMsg);
    } else if (errorMessageMode === 'message') {
      if (preMessage !== errorMsg) {
        Message.error(errorMsg);
        preMessage = errorMsg;
      }
    }
    console.log(new Error(errorMsg));
    return { status, message };
    // throw new Error(errorMsg);
  },
  requestInterceptors: (config, options) => {
    const token = getToken();
    if (token && config.requestOptions.withToken !== false) {
      config.headers.Authorization = options.authenticationScheme ? `${options.authenticationScheme} ${token}` : token;
    }
    return config;
  },
  responseInterceptors: (res) => {
    return res;
  },
  responseInterceptorsCatch: (error) => {
    const { response, code, message, config } = error || {};
    const errorMessageMode = config?.requestOptions?.errorMessageMode || 'none';
    const msg = response?.data?.error?.message ?? '';
    const err = error?.toString?.() ?? '';
    let errorMsg = '';
    if (code === 'ECONNABORTED' && message.indexOf('timeout') !== -1) {
      errorMsg = '请求超时';
    }
    if (err?.includes('Network Error')) {
      errorMsg = '网络错误，请检查网络连接';
    }
    if (errorMsg) {
      if (errorMessageMode === 'message') {
        if (preMessage !== errorMsg) {
          Message.error(errorMsg);
          preMessage = errorMsg;
        }
      }
    } else {
      throw new Error(error);
    }
    checkStatus(response?.status, msg, errorMessageMode);
    return Promise.reject(error);
  },
};

function creatAxios(options) {
  return new VAxios(
    deepMerge(
      {
        authenticationScheme: 'Bearer',
        timeout: 10 * 1000,
        transform,
        requestOptions: {
          // 是否返回原生响应头 比如：需要获取响应头时使用该属性
          isReturnNativeResponse: false,
          // 需要对返回数据进行处理
          isTransformResponse: true,
          // 消息提示类型
          errorMessageMode: 'message',
          // 接口地址
          apiUrl: process.env.NODE_ENV === 'production' ? baseUrl : frontProxy ? '/api' : baseUrl,
          // 忽略重复请求
          ignoreCancelToken: true,
          // 携带Token
          withToken: true,
          // form传参方式post请求
          postForm: false,
        },
      },
      options || {}
    )
  );
}

export const defHttp = creatAxios();
