import {defHttp} from '@/utils/https';
import {apiModel} from '@/configs/apiModel';

/**
 * goodsType 商品分类
 * */
export const goodsTypeUrl = {
    getData: `${apiModel.PRODUCT}/mall/category/tree-list`,
    getDataSelect: `${apiModel.PRODUCT}/common/category/list/select`,
    Edit: `${apiModel.PRODUCT}/mall/category/update`,
    addGoodsType: `${apiModel.PRODUCT}/mall/category/add`,
    editStatus: `${apiModel.PRODUCT}/mall/category/switch-status`,
    editShow: `${apiModel.PRODUCT}/mall/category/switch-show`,
    delType: `${apiModel.PRODUCT}/mall/category/delete`,
    updateCycleDays: `${apiModel.PRODUCT}/mall/category/update-cycle`
}
export const goodsType = {
// 商品分类数据
    getData: (params) => defHttp.get({url: goodsTypeUrl.getData, params}),
    // 商品分类下拉搜索
    getDataSelect: (params) => defHttp.get({url: goodsTypeUrl.getDataSelect, params})
    ,
// 编辑分类
    Edit: (params) => defHttp.post({url: goodsTypeUrl.Edit, params}),
// 新增分类
    addGoodsType: (params) => defHttp.post({url: goodsTypeUrl.addGoodsType, params}),
// 修改状态(启用/禁用)
    editStatus: (params) => defHttp.post({url: goodsTypeUrl.editStatus, params}),
// 修改是否显示
    editShow: (params) => defHttp.post({url: goodsTypeUrl.editShow, params}),
// 删除
    delType: (params) => defHttp.post({url: goodsTypeUrl.delType, params}),
//修改开货周期
    updateCycleDays: (params) => defHttp.get({url: goodsTypeUrl.updateCycleDays, params})
}

/**
 * goodsGroup 商品分组
 * */
export const goodsGroupUrl = {
    getData: `${apiModel.PRODUCT}/mall/group/all`,
    getDataSelect: `${apiModel.PRODUCT}/common/group/list/select`,
    addData: `${apiModel.PRODUCT}/mall/group/add`,
    updateData: `${apiModel.PRODUCT}/mall/group/update`,
    removeData: `${apiModel.PRODUCT}/mall/group/delete`,
    editStatus: `${apiModel.PRODUCT}/mall/group/switch-status`
}
export const goodsGroup = {
    //列表数据
    getData: params => defHttp.get({url: goodsGroupUrl.getData, params}),
    // 商品分组下拉搜索
    getDataSelect: (params) => defHttp.get({url: goodsGroupUrl.getDataSelect, params}),
    //新增
    addData: params => defHttp.post({url: goodsGroupUrl.addData, params}),
    //编辑
    updateData: params => defHttp.post({url: goodsGroupUrl.updateData, params}),
    //删除
    removeData: params => defHttp.post({url: goodsGroupUrl.removeData, params}),
    // 修改状态(启用/禁用)
    editStatus: (params) => defHttp.post({url: goodsGroupUrl.editStatus, params})
}

/**
 * goodsArchives 商品档案
 * */
export const goodsArchivesUrl = {
    getData: `${apiModel.PRODUCT}/mall/product/archives/page`,
    addData: `${apiModel.PRODUCT}/mall/product/archives/add`,
    goodsDetail: `${apiModel.PRODUCT}/mall/product/archives/info/`,
    onGoodsDetail: `${apiModel.PRODUCT}/mall/`,
    updateData: `${apiModel.PRODUCT}/mall/product/archives/update`,
    delData: `${apiModel.PRODUCT}/mall/product/archives/delete`,
    esRefresh: `${apiModel.PRODUCT}/mall/product/archives/es/refresh`,
    createSn: `${apiModel.PRODUCT}/mall/product/archives/sn/create`,
    checkSn: `${apiModel.PRODUCT}/mall/product/archives/sn/check`
}
export const goodsArchives = {
    // 商品档案数据
    getData: (params) => defHttp.post({url: goodsArchivesUrl.getData, params}),
    // 新增档案数据
    addData: (params) => defHttp.post({url: goodsArchivesUrl.addData, params}),
    // 档案详情
    goodsDetail: (id) => defHttp.get({url: goodsArchivesUrl.goodsDetail + id}),
    // 已上架档案详情
    onGoodsDetail: (id) => defHttp.get({url: goodsArchivesUrl.onGoodsDetail + id}),
    // 编辑
    updateData: (params) => defHttp.post({url: goodsArchivesUrl.updateData, params}),
    // 删除
    delData: (params) => defHttp.post({url: goodsArchivesUrl.delData, params}),
    // ES刷新
    esRefresh: (params) => defHttp.post({url: goodsArchivesUrl.esRefresh, params}),
    // 生成sn条码
    createSn: (params) => defHttp.get({url: goodsArchivesUrl.createSn, params}),
    // 检测sn条码
    checkSn: (params) => defHttp.get({url: goodsArchivesUrl.checkSn, params}),
}
/**
 * goodsManage 商品管理
 * */
export const goodsManageUrl = {
    getData: `${apiModel.PRODUCT}/mall/product/rel/shop-in-search`,
    getSkuData: `${apiModel.PRODUCT}/mall/product/rel/shop-sku-search`,
    getUnSaleData: `${apiModel.PRODUCT}/mall/product/rel/archives-not-in-shop-search`,
    Edit: `${apiModel.PRODUCT}/mall/spec/update`,
    changeState: `${apiModel.PRODUCT}/mall/product/rel/up-down`,
    getId: `${apiModel.PRODUCT}/mall/product-rel/get-id`,
    delGood: `${apiModel.PRODUCT}/mall/product/rel/delete`,
    addGoods: `${apiModel.PRODUCT}/mall/product-rel/add`,
    getGoodInfo: `${apiModel.PRODUCT}/mall2/product/archives/`,
    editGoods: `${apiModel.PRODUCT}/mall/product-rel/update`,
    freight: `${apiModel.PRODUCT}/mall/freight/list/select`,
    goodsUp: `${apiModel.PRODUCT}/mall/product/rel/add`,
    goodsUpUpdate: `${apiModel.PRODUCT}/mall/product/rel/update`,
    onGoodsDetail: `${apiModel.PRODUCT}/mall/product/rel/`,
    updateProCycle: `${apiModel.PRODUCT}/mall/product/rel/update-cycle`,
    unpack: `${apiModel.PRODUCT}/mall/product/rel/unpack`,
    addFreight: `${apiModel.PRODUCT}/mall/freight/add`
}
export const goodsManage = {
    // 商品规格数据
    /*--2021.11.3 yxy--*/
    // getData: (params)=> getAction('product/mall/product-rel/page',params),
    getData: (params) => defHttp.post({url: goodsManageUrl.getData, params}),
    getSkuData: (params) => defHttp.post({url: goodsManageUrl.getSkuData, params}),
    getUnSaleData: (params) => defHttp.post({url: goodsManageUrl.getUnSaleData, params}),
    /*--2021.11.3 yxy end--*/
    // 编辑规格
    Edit: (params) => defHttp.post({url: goodsManageUrl.Edit, params}),
    // 商品下架
    changeState: (params) => defHttp.post({url: goodsManageUrl.changeState, params}),
    // 详情
    getId: (params) => defHttp.get({url: goodsManageUrl.getId, params}),
    //删除商品
    delGood: (params) => defHttp.post({url: goodsManageUrl.delGood, params}),
    //新增商品
    addGoods: (params) => defHttp.post({url: goodsManageUrl.addGoods, params}),
    //商品详情
    /*--2021.11.3 yxy--*/
    // getGoodInfo: (params) => postActionUrlParams('product/mall/product-rel/get-id', params),
    getGoodInfo: (params) => defHttp.get({url: goodsManageUrl.getGoodInfo + params.id}),
    /*--2021.11.3 yxy end--*/
    //修改商品
    editGoods: (params) => defHttp.post({url: goodsManageUrl.editGoods, params}),
    //选择运费模板
    selectFreight: (params) => defHttp.post({url: goodsManageUrl.freight, params}),
    //新增运费模板
    addFreight:  (params) => defHttp.post({url: goodsManageUrl.addFreight, params}),
    //商品上架
    goodsUp: (params) => defHttp.post({url: goodsManageUrl.goodsUp, params}),
    //商品上架修改
    goodsUpUpdate: (params) => defHttp.post({url: goodsManageUrl.goodsUpUpdate, params}),
    // 已上架档案详情
    onGoodsDetail: (id) => defHttp.get({url: goodsManageUrl.onGoodsDetail + id}),
    updateProCycle: (params) => defHttp.get({url: goodsManageUrl.updateProCycle, params}),
    //拆分
    unpack: (params) => defHttp.post({url: goodsManageUrl.unpack, params}),
}
