
import { Message } from 'element-ui'
import { EmptyUtil } from '@/utils/emptyUtils.js'
export class DrawerService {

  isUpdate

  isBelow

  rawRecord

  formState

  formRef = null

  static emit

  loading = false

  LAYER = {
    size: 640,
    title: '',
    visible: true,

  }
  constructor(dto, rawRecord, emit) {
    if (typeof emit === 'function') {
      this.emit = emit
    } else {
      this.emit = () => { }
      Message({
        showClose: true,
        message: 'emit不是一个函数',
        type: 'error'
      })
      this.visible = false
    }
    this.rawRecord = rawRecord
    // eslint-disable-next-line no-prototype-builtins
    this.isUpdate = !EmptyUtil.isEmptyObject(rawRecord) && !rawRecord.hasOwnProperty('AddBelow')
    if (this.isUpdate) {
      this.formState = rawRecord
    } else {
      // 深拷贝一次对象
      const data = dto
      this.formState = { ...data, ...rawRecord }
    }
  }

  /**
    * @desc 修改窗口宽度
    * @param title 窗口标题
    * @param width 窗口宽度数值
    * */
  setLAYER (title, size = 640) {
    if (size <= 200) {
      Message({
        showClose: true,
        message: '抽屉宽度像素不能小于200',
        type: 'error'
      })
      return
    }
    this.LAYER.size = size
    this.LAYER.title = title
    return this
  }

  /**
    * @desc 配置数据新增的执行方法
    * @param request 执行数据新增的执行方法
    * */
  setAddRequest (request) {
    this.addRequest = request
    return this
  }
  /**
     * @desc 配置数据修改的执行方法
     * @param request 执行数据修改的执行方法
     * */
  setUpdateRequest (request) {
    this.updateRequest = request
    return this
  }


  /**
    * 执行数据新增操作
    * */
  addAction = async () => {
    if (typeof this.addRequest !== 'function') throw new Error('尚未初始化数据新增的执行方法,setAddXhr 方法进行初始化')
    const { data } = await this.addRequest(this.formState)
    Message({
      showClose: true,
      message: data,
      type: 'success'
    })
    this.cancelForm('RELOAD')
  }


  /**
     * 执行数据修改操作
     * */
  updateAction = async () => {
    if (typeof this.updateRequest !== 'function') {
      Message({
        showClose: true,
        message: '尚未初始化数据修改的执行方法,updateXhr 方法进行初始化',
        type: 'error'
      })
      throw new Error('尚未初始化数据修改的执行方法,updateXhr 方法进行初始化')

    }
    const key = this.rawRecord[this.rowKeyName]
    const { data } = await this.updateRequest(this.formState)
    Message({
      showClose: true,
      message: data,
      type: 'success'
    })
    this.cancelForm('RELOAD')
  }


  /**
    * @desc 关闭之前的回调
    * */
  handleClose = (e) => {
    this.formRef.resetFields()
    this.centerDialogVisible = false
    this.emit('cancel', e)
  }

  // 获取ref表单对象
  getRefFrom (dom) {
    this.formRef = dom
    return this
  }

  // 提交表单
  async submitForm () {
    await this.formRef.validate(async (valid) => {
      if (valid) {
        this.loading = true
        this.isUpdate ? await this.updateAction() : await this.addAction()
      }
      else {
        console.log('error submit!!')
        return false
      }
      this.loading = false
    })
  }
  // 取消表单
  cancelForm (e = 'NONE') {
    this.handleClose(e)
  }
}