<template>
  <div :style="{ backgroundColor: theme.headerBlack }" class="header-view plr-2">
    <div class="mr-2">
      <!-- <img
        src="../assets/logo_index_black.png"
        alt=""
      /> -->
      <span class="name">N HUB</span>
      <!-- <img src="../assets/cos+白色.png" alt=""> -->
    </div>
    <MenuView
      style="width: 100%;border-bottom: 1px solid #000000"
      :active-text-color="menuTheme.activeBlack"
      :background-color="menuTheme.backgroundBlack"
      :data="Menus"
      :defaultActive="defaultActive"
      :text-color="menuTheme.text"
      mode="horizontal"
      router
    >
      <!-- 更多菜单 -->
      <el-menu
        v-if="moreMenus.length > 0"
        :active-text-color="menuTheme.activeBlack"
        :background-color="menuTheme.background"
        :text-color="menuTheme.text"
        class="el-menu-vertical"
        mode="horizontal"
      >
        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-menu"></i>
            <span>更多菜单</span>
          </template>
          <MenuView
            :active-text-color="menuTheme.active"
            :background-color="menuTheme.background"
            :data="moreMenus"
            :defaultActive="defaultActive"
            :text-color="menuTheme.text"
            mode="vertical"
            router
          />
        </el-submenu>
      </el-menu>

      <el-row align="middle" class="ml-a" type="flex">
        <template v-if="layout.hasSubSystem">
          <SubSystemView class="pointer"></SubSystemView>
          <el-divider direction="vertical"></el-divider>
        </template>
        <el-upload
          action="qiNiuUploadApi"
          :headers="headers"
          :auto-upload="false"
          :show-file-list="false"
          :on-change="(response, file) => handleSuccess(response, file)"
          :before-upload="beforeUpload"
          multiple
        >
        <img v-if="userInfo.headerImg || userInfo.headerImg != ''" style="width: 40px;" class="mlr-1" :src="userInfo.headerImg"/>
        <img v-else style="width: 40px;" class="mlr-1" src="https://dalishop-prod.obs.cn-north-4.myhuaweicloud.com/1/1/2024/06/25/20240625_93723da10134f04e9b097ce0511ee4809609.png"/>
              <!-- <el-avatar class="mlr-1" src="https://dalishop-prod.obs.cn-north-4.myhuaweicloud.com/1/1/2024/06/25/20240625_93723da10134f04e9b097ce0511ee4809609.png"></el-avatar> -->
          </el-upload>
        <el-dropdown trigger="click" @command="handleCommand">
          <span :class="{ 'text-white': !lightHeader }" class="el-dropdown-link pointer">
            {{ userInfo.realName }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="userInfo">修改密码</el-dropdown-item>
            <el-dropdown-item command="logout" divided>退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-row>
    </MenuView>
    <!-- 修改密码组件 -->
    <Password ref="passWord" />
  </div>
</template>

<script>
import { isFunction } from 'lodash-es'
import { mapActions, mapGetters } from 'vuex'
import { getToken } from '@/utils/auth';
import { isLightColor } from '@/utils'
import { layout } from '@/configs/layout'
import { theme } from '@/configs/theme'
import SubSystemView from '@/layout/SubSystemView'
import { useMenu } from '@/hooks/useMenu'
import Password from '../layout/Password.vue'
import singlePic from '@/components/singlematerial';
import { upload } from '@/api/upload';
export default {
  name: 'HeaderView',
  components: {
    SubSystemView,Password,singlePic
  },
  data () {
    return {
      layout,
      theme,
      headers: {
        Authorization: getToken(),
      },
    }
  },
  computed: {
    ...mapGetters(['roles', 'topMenus', 'userInfo','shopInfo']),
    Menus () {
      return this.topMenus.filter((item, index) => index < 8)
    },
    moreMenus () {
      return this.topMenus.filter((item, index) => index >= 8)
    },
    menuTheme () {
      return theme.menu
    },
    lightHeader () {
      return isLightColor(theme.header)
    },
    defaultActive: {
      get () {
        console.log('this.$route?.meta?.pid',this.$route?.meta?.pid)
        console.log('this.topMenus',this.topMenus)
        console.log('this.topMenus.find((item) => item.id === this.$route?.meta?.pid)?.path',this.topMenus.find((item) => item.id === this.$route?.meta?.pid)?.path)
        if(this.$route?.meta?.pid==='513'){
          return '/order'
        }else{
        return this.topMenus.find((item) => item.id === this.$route?.meta?.pid)?.path
        }
      },
      set () { },
    },
  },
  watch: {
    defaultActive: {
      handler: function (v) {
        const { asideMenus } = useMenu(this.roles, v)
        this.$store.commit('SET_ASIDE_MENUS', asideMenus)
      },
    },
  },
  methods: {
    ...mapActions(['Logout']),
    handleSuccess(res, file) {
      const formData = new FormData();
      formData.append('file', res.raw);
      formData.append('group', this.groupId != '-1' ? this.groupId : null);
      this.progress = true;
      this.progressPercent = 0;
      upload.uploadFile(formData).then((data) => {
        this.times = setInterval(() => {
          ++this.progressPercent;
          if (this.progressPercent > 89) {
            clearInterval(this.times);
            this.times = null;

            this.getPage(this.page);
          }
        }, 20);
      })
    },
    beforeUpload(file) {
      const isPic =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/gif' ||
        file.type === 'image/jpg';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isPic) {
        this.$message.error('上传图片只能是 JPG、JPEG、PNG、GIF 格式!');
        return false;
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isPic && isLt2M;
    },
    handleCommand (command) {
      const commandFn = {
        logout: this.Logout,
      }
      if (commandFn[command] && isFunction(commandFn[command])) {
        commandFn[command]()
      }else{
        this.$refs.passWord.init()
      }
    },
    handleSelect (index) {
      console.log(index, '555')
    }
  },
};
</script>

<style lang="scss" scoped>
.header-view {
  display: flex;
  align-items: center;
  width: 100vw;
  min-height: 56px;
  // border-bottom: solid 1px #e6e6e6;
  box-shadow:10px 0 16px rgba(0, 0, 0, 0.3);
  .mr-2 {
    width: 200px;
    // height: 100%;
    height: inherit;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .name {
      color: #ffffff;
      font-size: 20px;
      font-weight: bold;
    }
    img {
      width: 35px;
      height: 60%;
      // border-radius: 60%;
      // box-shadow: 0 0 10px #fff;
      // height: 100%;
      width: 100%;
    }
  }
}
::v-deep .el-submenu__title i {
  color: #ffffff;
}
::v-deep .el-menu-vertical {
  height: 100%;
  border: none !important;
  display: flex;
  align-items: center;
}

::v-deep .el-menu-item .is-active {
  background-color: rgb(64, 158, 255);
}
</style>
