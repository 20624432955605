import { createStorage as create } from '@/utils/cache/storage';

const createOptions = (storage) => {
  return {
    storage,
  };
};

export const WebStorage = create(createOptions(sessionStorage));

export const createStorage = (storage = sessionStorage) => {
  return create(createOptions(storage));
};

export const createSessionStorage = () => {
  return create(createOptions(sessionStorage));
};

export const createLocalStorage = () => {
  return create(createOptions(localStorage));
};

export default WebStorage;
