export const getFiles = (modulesFile) => {
  try {
    return modulesFile.keys().reduce((module, modulePath) => {
      const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
      const value = modulesFile(modulePath);
      module[moduleName] = value.default;
      return module;
    }, {});
  } catch (error) {
    return Promise.reject(error);
  }
};

export const isLightColor = (val = '') => {
  //HEX十六进制颜色值转换为RGB(A)颜色值
  let i;
  // 16进制颜色值的正则
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  // 把颜色值变成小写
  let color = val.toLowerCase();
  if (reg.test(color)) {
    // 如果只有三位的值，需变成六位，如：#fff => #ffffff
    if (color.length === 4) {
      let colorNew = '#';
      for (i = 1; i < 4; i += 1) {
        colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1));
      }
      color = colorNew;
    }
    // 处理六位的颜色值，转为RGB
    const colorChange = [];
    for (i = 1; i < 7; i += 2) {
      colorChange.push(parseInt('0x' + color.slice(i, i + 2)));
    }
    const grayLevel = colorChange[0] * 0.299 + colorChange[1] * 0.587 + colorChange[2] * 0.114;
    return grayLevel >= 192;
  } else {
    // result = '无效';
    return console.error('请传入十六进制颜色值');
  }
};

export function timestamp(dateValue) {
  return +new Date(dateValue);
}

export function localDateTime(date) {
  if (!date) {
    date = new Date();
  }
  return new Date(date).toLocaleString();
}
