import RzDialog from '@/components/rz-dialog'
import RzDrawer from '@/components/rz-drawer'
import store from '@/store'
import Vue from 'vue'

export function Dialog(option = {}) {
    const id = 'dialog' + Date.parse(new Date())
    let body = document.body,
        dom = document.createElement('div')
    body.appendChild(dom)
    return new Vue({
        store,
        render: (h) => {
            return h(
                RzDialog,
                {
                    props: {
                        ...option,
                        id
                    }
                },
            )
        }
    }).$mount(dom)
}

export function Drawer(option = {}) {
    const id = 'drawer' + Date.parse(new Date())
    let body = document.body,
        dom = document.createElement('div')
    body.appendChild(dom)
    return new Vue({
        store,
        render: (h) => {
            return h(RzDrawer, {
                props: {
                    ...option,
                    id
                }
            },)
        }
    }).$mount(dom)
}
