<template>
  <div>

    <el-cascader
      v-model="state.selected"
      clearable
      filterable
      :disabled="state.disabled"
      :options="state.options"
      :placeholder="state.placeholder"
      @focus="loadOptions"
      @change="onChange"
    >
    </el-cascader>
  </div>
</template>

<script>
import { EmptyUtil } from '@/utils/emptyUtils'

export default {
  name: "smart-dict",
  props: {
    dataList: {
      type: Array,
      required: false,
      default: () => [],
    },
    keyValue:{
      type: String,
      required: true,
    },
    keyLabel:{
      type: String,
      required: true,
    },

    url: {
      type: Function,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },

    value: {
      type: [String, Number, Object, Boolean, Array],
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    placeholder: {
      type: String,
      required: false,
      default: '请选择',
    },
    lazy: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      state: {
        show: false,
        options: [],
        disabled: this.$props.disabled,
        placeholder: this.$props.placeholder,
        selected: this.$props.value,
        multiple: this.$props.multiple,
      },

    }
  },
  created () {
    console.log(44)
    this.loadOptions()
  },

  methods: {
    onChange (e) {
      const value = this.$props.isRadio ? e.target.value : e
      this.$emit('updateValue', value)
      this.$emit('change', value)
    },

    // 递归处理数组

    processArray (data) {
      return data.map(item => {
        const {children} = item
        const obj = Object.assign(item)
        const processedItem = {
          label: '',
          value: ''
        }
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(this.$props.keyLabel)&&obj.hasOwnProperty(this.$props.keyValue)) {
            for (const key in obj) {
              if (key === this.$props.keyLabel) {
                processedItem.label = obj[key]
              }
              else if (key===this.$props.keyValue){
            
                processedItem.value = obj[key]
              }
            }
        }else{
          throw '给定的属性有误'
        }
        
        if (children?.length > 0) {
          processedItem.children = this.processArray(children)
        }else{
          processedItem.children = null
        }

        return processedItem
      })
    },

    async loadOptions () {
      if (this.$props.dataList.length > 0) {
        this.state.options = this.$props.dataList
        return
      }

      if (this.state.options.length === 0) {
        this.state.show = false
        const { data } = await this.$props.url()
        const arr = this.processArray(data)
        this.state.options = arr
        this.state.show = true
      }
    }
  },
  watch: {
    selected (x) {
      console.log(x);
      // if (x !== y) 
      // this.state.selected = x

    }

  }


}

</script>

<style scoped lang="scss">
</style>
