import checkPermission from '@/utils/permission'
// 基础组件的自动化全局注册
import Vue from 'vue'
// 所有插件必须导出一个install 方法
export const install = app => {
  app.directive('auth', {
    inserted: (el, binding) => {
      const { value } = binding
      if (value && !checkPermission(value)) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    },
  })
}
Vue.use(install)