export const cacheCipher = {
  key: '_11111000001111@',
  iv: '@11111000001111_',
};
import { AesEncryption } from '@/utils/cipher';

export const createStorage = ({
  storage = sessionStorage,
  prefixKey = '',
  key = cacheCipher.key,
  iv = cacheCipher.iv,
  hasEncrypt = true,
} = {}) => {
  if (hasEncrypt && [key.length, iv.length].some((item) => item !== 16)) {
    throw new Error('When hasEncrypt is true, the key or iv must be 16 bits!');
  }
  const encryption = new AesEncryption({ key, iv });
  const WebStorage = class WebStorage {
    #hasEncrypt;
    #storage;
    #prefixKey;
    #encryption;

    constructor() {
      this.#storage = storage;
      this.#prefixKey = prefixKey;
      this.#encryption = encryption;
      this.#hasEncrypt = hasEncrypt;
    }

    #getKey(key) {
      return `${this.#prefixKey}${key}`.toUpperCase();
    }

    set(key, value) {
      const stringData = JSON.stringify({ value });
      const stringifyValue = this.#hasEncrypt ? this.#encryption.encryptByAES(stringData) : stringData;
      this.#storage.setItem(this.#getKey(key), stringifyValue);
    }

    get(key) {
      const val = this.#storage.getItem(this.#getKey(key));
      if (!val) {
        return null;
      }
      try {
        const decVal = this.#hasEncrypt ? this.#encryption.decryptByAES(val) : val;
        const data = JSON.parse(decVal);
        const { value } = data;
        return value;
      } catch {
        return null;
      }
    }

    remove(key) {
      this.#storage.removeItem(this.#getKey(key));
    }

    clear() {
      this.#storage.clear();
    }
  };
  return new WebStorage();
};
