<template>
  <TableView ref="table-view" :other-params="params" :selection-key="selectKey" :isSingle="isSingle" v-model="selection" :columns="columns"
             :options="options"
             :query="query">
    <template #sellState="{row}">
      <el-tag v-show="row.sellState == '0'" type='danger' @click='changeStatus(row)'>未上架
      </el-tag>
      <el-tag v-show="row.sellState == '1'" type='success' @click='changeStatus(row)'>已上架
      </el-tag>
    </template>
    <template #productName="{row}">
      <div style='display: flex;align-items: center;text-align: left;'>
        <div class='table_img'>
          <el-image style='margin-right: 20px' :src='row.productImg' :preview-src-list='[row.productImg]'
                    class='pics'>
            <div slot='error' class='image-slot'>
              <img src='@/assets/error_1.png' alt=''>
            </div>
          </el-image>
          <el-tag size='mini' v-if='row.specType == "0"' type='info'>单</el-tag>
          <el-tag size='mini' v-else type='success'>多</el-tag>
        </div>
        <div class='table_word'>
          <div class='product-name'> {{ row.productName }}</div>
          <div class='product-sn' v-if='row.snList || row.productSn'>
            {{ row.snList ? row.snList.join() : row.productSn }}
          </div>
        </div>
      </div>
    </template>
    <!--    <template #action="{row}">-->
    <!--      <el-button type="text" size="mini" @click="choose(row)">选择</el-button>-->
    <!--    </template>-->
  </TableView>
</template>

<script>
import {goodsManageUrl} from "@/api/goods";

export {goodsManageUrl} from '@/api/goods'
export default {
  name: "selectProduct",
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
      api:{
          type:String,
      },
      params:{
        type:Object,
          default(){
            return {}
          }
      },
     selectKey:{
        type:String,
         default:'productSkuId'
     },
    isSingle: {
      type: Boolean,
      default: false
    }
  },
  model: {
    event: 'update'
  },
  data() {
    return {
      query: [
        {
          key: 'keywords',
          label: '',
          prop: 'keywords',
          tagName: 'input',
          value: '',
          placeholder: '条码 名称 关键字 副标题 '
        }
      ],
      options: {
        api: goodsManageUrl.getSkuData,
        method: 'post',
      },
      columns: [
        {
          type: 'selection'
        },
        {
          label: '商品名称',
          key: 'productName',
          prop: 'productName',
          slot: 'productName',// 作用域插槽
          align: 'center'
          // width: 100,
        },
        {
          label: '商品成本',
          key: 'cost',
          prop: 'cost',
          align: 'center'
          // width: 80
        },
        {
          label: '商品售价',
          key: 'price',
          prop: 'price',
          align: 'center'
        },
        {
          label: '线上Vip价',
          key: 'onlineVipPrice',
          prop: 'onlineVipPrice',
          align: 'center'
          // width: 80
        },
        {
          label: '线下Vip价',
          key: 'offlineVipPrice',
          prop: 'offlineVipPrice',
          align: 'center'
          // width: 80
        },
        {
          label: '总销量',
          key: 'solds',
          prop: 'solds',
          align: 'center'
        },
        {
          label: '库存',
          key: 'stock',
          prop: 'stock',
          align: 'center'
        },
        {
          label: '状态',
          key: 'sellState',
          prop: 'sellState',
          align: 'center',
          slot: 'sellState'
        },
        // {
        //   label: '操作',
        //   key: 'action',
        //   width: 250,
        //   align: 'center',
        //   slot: 'action'
        // }
      ],
      selection: []
    }
  },
    created() {
    if (this.api){
        this.options.api = this.api
    }

      },
    mounted() {
    this.$refs['table-view'].setSelection(this.data)

  },
  watch: {
    'selection': {
      deep: true,
      handler() {
        this.$emit('update', this.selection)
      }
    }
  },
}
</script>

<style scoped>

</style>
