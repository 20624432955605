<template>
  <!--    运费模板配置-->
  <div>
    <el-drawer
      append-to-body
      :close-on-click-modal="false"
      @closed="cancelCU"
      :visible.sync="status"
      :title="title"
      size="70%"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="模板名称" prop="name" style="width: 40%">
          <el-input size="small" v-model="ruleForm.name" />
        </el-form-item>
        <el-form-item label="计费方式" prop="type">
          <el-radio-group v-model="ruleForm.type">
            <el-radio :label="2">按重量</el-radio>
            <el-radio :label="1">按件数</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="配送区域及运费" prop="area">
          <table id="recruit">
            <thead>
              <tr>
                <th>可配送区域</th>
                <th>{{ ruleForm.type != 2 ? '首件 (个)' : '首重 (Kg)' }}</th>
                <th>{{ ruleForm.type != 2 ? '运费 (元)' : '运费 (元)' }}</th>
                <th>{{ ruleForm.type != 2 ? '续件 (个)' : '续重 (Kg)' }}</th>
                <th>{{ ruleForm.type != 2 ? '续费 (元)' : '续费 (元)' }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in tableNum" :key="index">
                <td>
                  <span v-for="(city, indexs) in item.area" :key="indexs">
                    <span>{{ city.name }}&nbsp;&nbsp;&nbsp;</span>
                    
                    <span v-if="city.areaName.length > 0">{{ '(' + String(city.areaName) + ')' }}&nbsp;&nbsp;&nbsp;</span>
                  </span>

                  <div class="action_header">
                    <el-button type="text" class="text-success" @click="handEdit(item, index)">编辑 </el-button>
                    <el-button type="text" class="text-danger" @click="handDel(index, item)">删除 </el-button>
                  </div>
                </td>
                <td>
                  <el-input-number size="small" v-model="item.firstValue" :min="0" />
                </td>
                <td>
                  <el-input-number size="small" v-model="item.firstValuePrice" :min="0" />
                </td>
                <td>
                  <el-input-number size="small" v-model="item.nextValue" :min="0" />
                </td>
                <td>
                  <el-input-number size="small" v-model="item.nextValuePrice" :min="0" />
                </td>
              </tr>
              <tr>
                <td colspan="5" @click="addArea" style="text-align: left">
                  <el-button :disabled="this.areaData.length === flag" type="text" class="text-success" size="small"
                    >添加区域</el-button
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </el-form-item>
        <el-row>
          <el-col :offset="10">
            <el-button @click="resetForm('ruleForm')" class="unify-button">重置</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')" class="unify-button">{{ isAdd?'创建':'修改' }}</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-drawer>
    <AreaModel ref="AreaModel" @edit="edit" @addData="addData"></AreaModel>
  </div>
</template>

<script>
import AreaModel from './AreaModel'
import Area from '@/assets/Area.json'
import { order } from '@/api/order'
import { ListService } from '@/service/ListService'
export default {
  name: 'smart-template',
  components: {
    AreaModel
  },
  data () {
    return {
      title: '新增',
      status: false,
      isAdd: true,

      ruleForm: {
        name: '',
        type: 1,
        id: null
      },
      rules: {
        name: [{ required: true, message: '模板名字', trigger: 'blur' }],
        type: [{ required: true, message: '请选计费方式', trigger: 'blur' }]
        // area:[{ required: true, message: '请选计费方式', trigger: 'change' }],
      },
      tableNum: [],
      areaData: [],
      editIndex: null,
    }
  },
  computed: {
    flag () {
      let count = 0
      if (this.tableNum.length > 0) {
        let countArr = this.tableNum.map(item => {
          return item.area.length
        })
        count = countArr.reduce((a, b) => {
          return a + b || a
        })
      }
      return count
    }
  },
  methods: {
    init () {
      console.log('初始化')
      this.getArea()
    },
    cancelCU () {
      this.status = false
      this.ruleForm = {
        name: '',
        type: 1
      }
      this.tableNum = []
      this.editIndex = null

      this.$parent.initPage()

    },
    handDel (index, item) {
      let arrFrom = JSON.parse(JSON.stringify(this.areaData))
      if (item) {
        this.tableNum.forEach(a => {
          a.area.forEach(b => {
            arrFrom.map(c => {
              if (b.id == c.id) {
                c.isShow = false
              }
            })
          })
        })
        item.area.map(items => {
          arrFrom.map(val => {
            if (val.id == items.id) {
              val.isShow = true
              val.areaName = []
              val.checkAll = false
              val.isIndeterminate = false
              val.check = false
              val.checkedCitiesId = []
            }
          })
        })
      }
      this.$refs.AreaModel.areaForm = arrFrom
      this.tableNum.splice(index, 1)
    },
    edit (data) {
      this.$set(this.tableNum[this.editIndex], 'area', data.area)
    },
    addData (data) {
      this.tableNum.push(data)
    },
    handEdit (item, index) {
      let arrFrom = JSON.parse(JSON.stringify(this.areaData))
      if (item) {
        this.tableNum.forEach(a => {
          a.area.forEach(b => {
            arrFrom.map(c => {
              if (b.id == c.id) {
                c.isShow = false
              }
            })
          })
        })
        item.area.map(items => {
          arrFrom.map(val => {
            if (val.id == items.id) {
              if (items.isIndeterminate) {
                val.isShow = true
                val.checkAll = false
                val.isIndeterminate = true
                val.areaName = items.areaName
              } else {
                val.checkAll = true
                this.$set(val, 'isShow', true)
                let names = val.nameList.map(id => {
                  return id.name
                })
                val.areaName = names
                val.isIndeterminate = false
              }
            }
          })
        })
      }
      this.$refs.AreaModel.areaForm = arrFrom.filter(val => {
        return val.isShow
      })
      this.$refs.AreaModel.status = true
      this.$refs.AreaModel.isAdd = false
      this.editIndex = index
    },
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const data = [...this.tableNum]
          // console.log('data666',data)
          let jsonResult = []
          data.map((val, index) => {
            jsonResult.push({
              areaName: [],
              areaCode: [],
              firstValue: 0,
              firstValuePrice: 0,
              nextValue: 0,
              nextValuePrice: 0
            })
            jsonResult[index].firstValue = val.firstValue
            jsonResult[index].firstValuePrice = val.firstValuePrice
            jsonResult[index].nextValue = val.nextValue
            jsonResult[index].nextValuePrice = val.nextValuePrice
            val.area.map(items => {
              items.areaSonName = items.nameArr
              items.areaSonCode = items.checkedCitiesId
              items.areaParentCode = items.id
              items.areaParentName = items.name
              jsonResult[index].areaName.push(items.nameArr)
              jsonResult[index].areaCode.push(items.checkedCitiesId)
            })
          })
          jsonResult.map((val, index) => {
            val.area = data[index].area
            val.areaName = data[index].areaName
            val.areaCode = data[index].areaCode
            val.firstValue = data[index].firstValue
            val.firstValuePrice = data[index].firstValuePrice
            val.nextValue = data[index].nextValue
            val.nextValuePrice = data[index].nextValuePrice
            val.areaName = String(val.areaName)
            val.areaCode = String(val.areaCode)
          })
          // console.log('jsonResult--------->',jsonResult)
          jsonResult.forEach(v=>{
            let codeListData=[]
          let nameListData=[]
            if(v.area?.length ){
              v.area.forEach(s=>{
                if(s.areaSonCode?.length){
                  codeListData=[...codeListData,...s.areaSonCode]
                  nameListData=[...nameListData,...s.areaSonName]
                }
              })
            }
            v.areaCode=codeListData.toString()
            v.areaName=nameListData.toString()
          })
          let query = {
            name: this.ruleForm.name,
            chargingType: this.ruleForm.type,
            jsonResult
          }
          //新增
          if (this.isAdd) {
            order.addFreight(query).then(res => {
              let { data, message, status } = res
              if (status == 200) {
                this.$notify({
                  title: '新增成功',
                  type: 'success'
                })
                this.cancelCU()
              } else {
                this.$notify({
                  title: message,
                  type: 'error'
                })
              }
            })
            //编辑
          } else {
            query.id = this.ruleForm.id
            order.addFreight(query).then(res => {
              let { data, message, status } = res
              if (status == 200) {
                this.$notify({
                  title: "修改成功",
                  type: 'success'
                })
                this.cancelCU()
              } else {
                this.$notify({
                  title: message,
                  type: 'error'
                })
              }
            })
          }

        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
      this.tableNum = []
      this.ruleForm.name = ''
      this.$refs.AreaModel.areaForm = []
      this.getArea()
    },
    formatData (data) {
      data.map(val => {
        val.area.map(item => {
          item.name = item.areaParentName
          item.id = item.areaParentCode
          item.areaName = item.areaSonName
          item.nameArr = item.areaSonName
          item.checkedCitiesId = item.areaSonCode
          item.checkAll = false
          item.isIndeterminate = true
          item.isShow = false
          this.areaData.map(vals => {
            if (vals.id == item.id) {
              if (vals.nameList.length === item.areaSonName.length) {
                item.areaName = []
                item.checkAll = true
                item.isIndeterminate = false

              }
            }
          })
        })
      })
      this.tableNum = data
    },
    addArea () {
      if(this.title==='新增运费模板'){this.getArea()}
      let arrFrom = JSON.parse(JSON.stringify(this.$refs.AreaModel.areaForm))
      // console.log(arrFrom)
      if (this.tableNum.length > 0) {
        let arr2 = arrFrom.filter(val => {
          return val.isShow && !val.isIndeterminate
        })
        this.$refs.AreaModel.areaForm = arr2
      }

      this.$refs.AreaModel.status = true
      this.$refs.AreaModel.isAdd = true
    },
    getArea () {
      this.$refs.AreaModel.areaForm=[]
      Area.forEach(val => {
        this.$refs.AreaModel.areaForm.push({
          name: val.name,
          id: val.id,
          isIndeterminate: false,
          checkAll: false,
          check: false,
          isShow: true,
          areaName: [],
          checkedCitiesId: [],
          nameList: val.childNode
        })
      })

      this.areaData = this.$refs.AreaModel.areaForm
    }
  }
}
</script>

<style lang="scss" scoped>
div.el-checkbox-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  > label.el-checkbox {
    width: 300px;
    margin-bottom: 50px;
  }
}

/*设置表格总体样式*/
#recruit {
  width: 100%;
  border-collapse: collapse;
  /* 为表格设置合并边框模型： */
  text-align: center;
}

/*设置单元格样式*/
#recruit tbody td {
  /* font-size: 1em;*/
  border: 1px solid #ebeef5 !important;
  padding: 7px;
  width: 20%;
  font-size: 12px;
}

#recruit th {
  font-size: 12px;
  font-weight: bold;
  border: 0 !important;
  padding: 7px;
  width: 20%;
  background-color: #f9f9f9;
}

#recruit {
  border: 1px solid #ebeef5;
  padding: 7px;
}
</style>
