import { throttle } from 'lodash-es';
import store from '@/store';
import { getAuthCache, getToken, LOGIN_INFO_KEY } from '@/utils/auth';
import { localDateTime, timestamp } from '@/utils/index';

window.onload = function () {
  function userActionApp(time, advance = 6 * 1000) {
    let timer = null;
    let count = 0;
    const waitTime = time * 60;
    const checkTime = function () {
      const token = getToken();
      if (!token) {
        clearInterval(timer);
        console.log('----', advance);
        return;
      }
      count++;
      // 操作过期
      if (count >= waitTime) {
        console.log('用户到达未操作限定时间', time + '分钟');
        const nowDate = timestamp(localDateTime());
        const loginInfo = getAuthCache(LOGIN_INFO_KEY) || {};
        const _refreshTokenExpiration = timestamp(localDateTime(loginInfo.refreshTokenExpiration));
        if (!_refreshTokenExpiration) {
          return;
        }
        if (_refreshTokenExpiration - advance <= nowDate) {
          const throttled = throttle(async () => {
            await store.dispatch('Logout');
            console.log('刷新token过期，请重新登录', '---');
          }, 1000);
          throttled();
        } else {
          const throttled = throttle(async () => {
            console.log('500ms 后刷新', _refreshTokenExpiration, nowDate);
            count = 0;
            clearInterval(timer);
            await store.dispatch('refreshToken');
          }, 500);
          throttled();
        }
      }
    };
    const refresh = function () {
      const token = getToken();
      const nowDate = timestamp(localDateTime());
      const loginInfo = getAuthCache(LOGIN_INFO_KEY) || {};
      const _accessTokenExpiration = timestamp(localDateTime(loginInfo.accessTokenExpiration));
      const _expiresIn = loginInfo.expiresIn * 1000;

      if (!_accessTokenExpiration || !token) {
        count = _expiresIn;
        return;
      }
      // console.log('用户操作中');
      clearInterval(timer);

      if (_accessTokenExpiration - advance <= nowDate) {
        const throttled = throttle(async () => {
          console.log('500ms 后刷新token', '---', _accessTokenExpiration);
          await store.dispatch('refreshToken');
          body.addEventListener('click', refresh);
          body.addEventListener('keydown', refresh);
          body.addEventListener('mousemove', refresh);
          body.addEventListener('mousewheel', refresh);
        }, 500);
        body.removeEventListener('click', refresh);
        body.removeEventListener('keydown', refresh);
        body.removeEventListener('mousemove', refresh);
        body.removeEventListener('mousewheel', refresh);
        throttled();
      } else {
        count = 0;
        timer = setInterval(checkTime, 1000);
      }
    };
    timer = setInterval(checkTime, 1000);
    const body = document.querySelector('html');
    body.addEventListener('click', refresh);
    body.addEventListener('keydown', refresh);
    body.addEventListener('mousemove', refresh);
    body.addEventListener('mousewheel', refresh);
  }

  // 默认5分钟未操作重新获取token
  userActionApp(5);
};
