import { axios } from '@/utils/request'
const basePath = '/file/upload/images'
import {apiModel} from '@/configs/apiModel'
const ADMIN = apiModel.ADMIN; // 后台管理服务器 admin

export const upload = {
    // 上传文件
    uploadFile: data => {
        return axios({
            url: ADMIN + basePath,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            method: 'post',
            timeout:2*60*1000,
            data: data
        })
    },
    //上传微信证书
    uploadingWxCert: data =>{
        return axios({
            url: '/weixin/pay-config/uploading-wx-cert',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            method: 'post',
            data: data
        })
    },
    //上传微信证书
    uploadingPosExe: data =>{
        return axios({
            url: '/admin/sys/version/pos-exe',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            method: 'post',
            data: data
        })
    }
}
