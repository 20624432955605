<template>
  <el-menu
    v-if="bindValues.mode === 'horizontal'"
    :class="[{ 'is-collapse': bindValues.collapse, 'is-light': isLightTheme }, `is-${bindValues.mode || 'vertical'}`]"
    class="menu-view"
    v-bind="bindValues"
    @select="selectMenu"
  >
    <template v-for="(item, index) in data">
      <MenuTreeItem
        :key="item.path"
        :collapse="bindValues.collapse"
        :index="index"
        :item="item"
        :mode="bindValues.mode"
      />
    </template>
    <slot></slot>
  </el-menu>

  <el-scrollbar v-else :class="[{ 'is-collapse': bindValues.collapse }]" class="menu-scroll">
    <el-menu
      :class="[{ 'is-collapse': bindValues.collapse, 'is-light': isLightTheme }, `is-${bindValues.mode || 'vertical'}`]"
      class="menu-view"
      v-bind="bindValues"
    >
      <template v-for="(item, index) in data">
        <MenuTreeItem
          :key="item.path"
          :collapse="bindValues.collapse"
          :index="index"
          :item="item"
          :mode="bindValues.mode"
        />
      </template>
      <div class="menu-tool mt-a text-center">
        <slot></slot>
      </div>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { isLightColor } from '@/utils'
import MenuTreeItem from '@/components/MenuTreeItem'
import { useMenu } from '@/hooks/useMenu'
import { mapGetters } from 'vuex'

export default {
  name: 'MenuView',
  components: {
    MenuTreeItem,
  },
  data () {
    return {}
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      },
    },
    defaultActive: {
      type: String,
    },
    textColor: {
      type: String,
    },
    activeTextColor: {
      type: String,
    },
    backgroundColor: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(['roles']),
    bindValues () {
      const values = { ...this.$attrs }
      Object.keys(this.$props).map((key) => {
        if (!['data'].includes(key)) {
          values[key] = this.$props[key]
        }
      })
      return values
    },
    isLightTheme () {
      return isLightColor(this.backgroundColor || '#fff')
    },
  },
  methods: {
    selectMenu (index) {
      console.log(index)
      const { topMenus, asideMenus } = useMenu(this.roles, index)
      this.$store.commit('SET_TOP_MENUS', topMenus)
      this.$store.commit('SET_ASIDE_MENUS', asideMenus)
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/plugins/element.scss';

.menu-scroll {
  min-width: 180px;
  // width: 180px;
  padding-bottom: 0;

  &.is-collapse {
    min-width: 64px;
  }
}

.menu-view {
  min-width: 180px;
  // width: 180px;
  transition: min-width 0.5s;
  // overflow: scroll;

  &.is-vertical {
    display: flex;
    flex-direction: column;
  }

  &.el-menu--horizontal {
    display: flex;
    align-items: center;
    width: 80%;
    // border-bottom: 0;
    .el-menu-item {
      height: 56px;
      line-height: 56px;
      border: none;
    }
    :deep(.is-active) {
    background-color: #FBFBFB !important;
    height: 65%;
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-weight: bold;
    transition: 0.3s;
}
  }

  &.is-collapse {
    min-width: auto;
  }

  &:not(.is-light) {
    ::v-deep .el-menu-item:not(.is-active) i {
      color: #fff;
    }
  }

  ::v-deep .el-menu-item.is-active {
    background-color: rgba($--color-primary, 10%);
  }

  &.is-vertical {
    ::v-deep .el-menu-item.is-active {
      border-right: 3px solid currentColor;
    }
  }

  &.is-light {
    .el-menu-item i {
      color: #999;
    }
  }
}
.menu-view::-webkit-scrollbar {
  display: none;
}
.menu-tool {
  border-top: 1px solid #eee;
}

.el-menu {
  height: 100%;
  //border-right: solid 1px #e6e6e6;
}

::v-deep .el-scrollbar__bar.is-horizontal {
  display: none;
}
</style>
