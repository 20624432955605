import {
  clearAuthCache,
  getAuthCache,
  getToken,
  LOGIN_INFO_KEY,
  setAuthCache,
  TOKEN_KEY,
  USER_INFO_KEY,
} from '@/utils/auth';
import { authApi } from '@/api/auth';
import router, { constantRoutes } from '@/router';
import { useMenu } from '@/hooks/useMenu';
import { systemApi } from '@/api/system';
import Vue from 'vue/dist/vue.esm.js';
import store from '../index';
let id;
let shopId;
export default {
  state: {
    token: getToken(),
    loginInfo: getAuthCache(LOGIN_INFO_KEY) || {},
    userInfo: {},
    roles: [],
    topMenus: [],
    asideMenus: [],
    permission: [],
    systemList: JSON.parse(getAuthCache('systemList')) || [],
    shopList: [],
    shopInfo: {},
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
      setAuthCache(TOKEN_KEY, token);
    },
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo;
      setAuthCache(USER_INFO_KEY, userInfo);
    },
    SET_LOGIN_INFO(state, loginInfo) {
      state.loginInfo = loginInfo;
      setAuthCache(LOGIN_INFO_KEY, loginInfo);
    },
    SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_TOP_MENUS(state, menus) {
      state.topMenus = menus;
    },
    SET_ASIDE_MENUS(state, menus) {
      state.asideMenus = menus;
    },
    SET_PERMISSION(state, permission) {
      state.permission = permission;
    },
    SET_SYSTEM_LIST(state, list) {
      state.systemList = list;
      setAuthCache('systemList', JSON.stringify(list));
    },
    SET_SYSTEM_CHECK(state, id) {
      if (state.systemList) {
        let arr = state.systemList;
        arr.map((val) => {
          if (id === val.id) {
            // val.bg = 'warning'
            val.col = 2;
          } else {
            // val.bg = 'success'
            val.col = 1;
          }
        });
        state.systemList = arr;
        setAuthCache('systemList', JSON.stringify(state.systemList));
      }
    },
    SET_SHOP_INFO(state, info) {
      state.shopInfo = info;
    },
    SET_SHOP_LIST(state, list) {
      state.shopList = list;
    },
    SET_SHOP_CHECK(state, id) {
      if (state.shopList) {
        let arr = state.shopList;
        arr.map((val) => {
          if (id === val.id) {
            // val.bg = 'warning'
            val.col = 2;
          } else {
            // val.bg = 'success'
            val.col = 1;
          }
        });
        state.shopList = arr;
      }
    },
  },
  actions: {
    login({ commit, state }, userInfo) {
      return new Promise((resolve, reject) => {
        authApi.login(userInfo).then(({ status, message, data }) => {
          if (status === 200) {
            const { accessToken } = data;
            commit('SET_TOKEN', accessToken);
            commit('SET_LOGIN_INFO', data);

            const findRedirect = window.location.href.includes('?redirect=');
            // const redirect = findRedirect ? window.location.href.split('?redirect=')?.[1] : window.location.href
            const redirect = findRedirect && window.location.href.split('?redirect=')?.[1];
            store.dispatch('GetRoles').then((data) => {
              if (redirect && decodeURIComponent(redirect) !== '/') {
                window.location.href = `${redirect}`;
              } else {
                console.log('66',state.topMenus)
                const path = state.topMenus[0]?.path || '/';
                router.push(path).catch((error) => {
                  console.log(error, '---error');
                });
              }
            })
            // 排除默认根路由，该项目没有启用'/'路由
            // if (redirect && decodeURIComponent(redirect) !== '/') {
            //   window.location.href = `${redirect}`;
            // } else {
            //   console.log('66',state.topMenus)
            //   const path = state.topMenus[0]?.path || '/';
            //   router.push(path).catch((error) => {
            //     console.log(error, '---error');
            //   });
            // }
            resolve(data);
          } else {
            return reject(message);
          }
        });
      });
    },
    refreshToken({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (!state.token) {
          reject('no token');
        }
        let query = {
          token: state.token,
          refreshToken: state.loginInfo.refreshToken,
        };
        authApi
          .reLogin(query)
          .then(({ status, message, data }) => {
            if (status === 200) {
              const { accessToken } = data;
              commit('SET_TOKEN', accessToken);
              commit('SET_LOGIN_INFO', data);
              console.log('更新登录信息', state, data);
            } else {
              return reject(message);
            }
          })
          .catch(() => {
            commit('SET_TOKEN', '');
            commit('SET_ROLES', []);
            commit('SET_PERMISSION', []);
            commit('SET_SYSTEM_LIST', []);
            clearAuthCache();
            router.push('/login').catch(() => {});
          });
      });
    },
    // 获取用户信息
    GetUserInfo({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        authApi
          .getUserInfo({ token: state.token })
          .then((data) => {
            if (data) {
              const { currentBizSystem, currentShop } = data;
              commit('SET_USER_INFO', data);
              id = currentBizSystem.id;
              if (currentShop) {
                shopId = currentShop.id;
              }
              dispatch('GetTenantInfo', data.tenantId);
              setAuthCache('currSys', currentBizSystem.name);
            }
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GetTenantInfo({ commit, state }, tenantId) {
      systemApi.common.getTenantInfo(tenantId).then((data) => {
        document.title = data.name;
        commit('SET_SHOP_INFO', data);
        console.log(Vue, 'vue---');
        // Vue.$nextTick(() => {
        document.title = data.name;
        const link = document.querySelector("link[rel*='icon']");
        link?.setAttribute('href', data.logo);
        // })
      });
    },
    // 获取用户权限
    GetRoles({ commit, state }) {
      // 请使用符合业务场景的逻辑处理
      return new Promise((resolve, reject) => {
        authApi
          .getPermissions({ token: state.token })
          .then((data) => {
            if (data) {
              const bgArr = ['bgs-admin', 'bgs-mall', 'bgs-shop', 'bgs-pos', 'bgs-good'];
              const { menuList, permissions, bizSystemList, shopList } = data;
              const routes = [...constantRoutes, ...menuList];
              if (menuList && menuList.length) {
                const { topMenus, asideMenus } = useMenu(routes);
                console.log('77',topMenus)
                commit('SET_ROLES', routes);
                commit('SET_TOP_MENUS', topMenus);
                commit('SET_ASIDE_MENUS', asideMenus);
                commit('SET_PERMISSION', permissions);
                bizSystemList.map((val, index) => {
                  val.icon = 'el-icon-menu';
                  val.col = 1;
                  val.bg = bgArr[index];
                });
                commit('SET_SYSTEM_LIST', bizSystemList);
                commit('SET_SYSTEM_CHECK', id);
                if (shopList && id === 3) {
                  shopList.map((val, index) => {
                    val.icon = 'el-icon-s-shop';
                    val.col = 1;
                    val.bg = bgArr[index];
                  });
                  commit('SET_SHOP_LIST', shopList);
                  commit('SET_SHOP_CHECK', shopId);
                } else {
                  commit('SET_SHOP_LIST', []);
                }
              } else {
                // commit('SET_ROLES', ['ROLE_DEFAULT'])
              }
              data.roles = menuList;
            }
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 登出
    Logout({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (!state.token) {
          reject('no token');
        }
        authApi
          .logout({ token: state.token })
          .then((res) => {
            commit('SET_TOKEN', '');
            commit('SET_ROLES', []);
            commit('SET_PERMISSION', []);
            commit('SET_SYSTEM_LIST', []);
            clearAuthCache();
            router.push('/login').catch(() => {});
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
