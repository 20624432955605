<template>
  <el-container :class="{ 'has-header': hasHeader }">
    <MenuView
      v-if="asideMenus.length"
      :collapse="layout.collapse"
      :data="[...asideMenus]"
      :defaultActive="$route.path"
      router
    >
      <el-button
        :icon="layout.collapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
        type="text"
        @click="changeCollapse"
      ></el-button>
    </MenuView>
    <el-main :class="{ 'is-collapse': layout.collapse, 'has-tabs': layout.hasTabs }">
      <TabsView v-show="layout.hasTabs"></TabsView>
      <keep-alive :include="[...includes, 'SubLayoutView', 'HomeView']">
        <!--        <router-view has-aside></router-view>-->
        <router-view
          id="router-view"
          v-if="
            pageTab && pageTab.path && pageTab.meta && !pageTab.meta.linkFlag && tabsName.includes(pageTab.meta.title)
          "
          :includes="includes"
          has-aside
        ></router-view>
        <!--            tabData废弃属性，不要在新功能使用，需要pageTab数据请使用pageTabs混入                -->
        <component
          :is="apps"
          v-else-if="pageTab && pageTab.meta && !pageTab.meta.linkFlag"
          :tabData="pageTab"
        ></component>
      </keep-alive>
      <!-- <div class="footer">
        <span class="mr-2 text-info">Copyright &copy;{{ copyYear }} 蔻诗慕娜（广州）化妆品有限公司</span>
        <div class="copyright">
          <el-link :underline="false" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023102054号</el-link>
        </div>
      </div> -->
    </el-main>
  </el-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { layout } from '@/configs/layout';
import { theme } from '@/configs/theme';
import TabsView from '@/layout/TabsView';
import pageTabs from '@/mixins/pageTabs';
export default {
  name: 'LayoutView',
  mixins: [pageTabs],
  components: {
    TabsView,
  },
  props: {
    hasHeader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      layout,
    };
  },
  computed: {
    ...mapGetters(['asideMenus']),
    copyYear() {
            return new Date().getFullYear()
        },
    menuTheme() {
      return theme.menu;
    },
    tabsName() {
      return this.pageTabs
        .filter((tab) => tab.meta && tab.meta.title)
        .map((tab) => {
          return tab.meta && tab.meta.title;
        });
    },
    apps() {
      const component = this.pageTab.component;
      return component ? () => import(`@/views/${component}.vue`) : undefined;
    },
    includes() {
      return this.pageTabs
        .filter((item) => item.meta?.keep)
        .map((item) => {
          const path = item.path || item.prePath;
          return (
            item.componentName ||
            path.replace(/\/./g, (a) => {
              return a.replace('/', '').toUpperCase();
            })
          );
        });
    },
  },
  methods: {
    changeCollapse() {
      layout.collapse = !layout.collapse;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-container {
  display: flex;

  &.has-header {
    height: calc(100vh - 78px);
  }
}

.el-main {
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow: hidden;
  padding-bottom: 0 !important;
}
#router-view {
  height: calc(100% - 10px - 20px);
}
.footer {
  width: 100%;
  height: 30px;
  // padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  ::v-deep .el-link--inner {
    color: rgb(187, 183, 183);
    font-size: 12px;
  }
   .text-info {
    color: rgb(187, 183, 183);
  }
}
</style>
