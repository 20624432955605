<template>
  <el-submenu v-if="item.children?.length > 1" :key="item.path" :index="`${index}`" :popper-append-to-body="false">
    <template v-if="item.meta" slot="title">
      <i :class="item.meta.icon"></i>
      <span>{{ item.meta.title }}</span>
    </template>
    <template v-for="(sub, si) in item.children">
      <MenuTreeItem :key="si.path" :index="`${index}_${si}`" :item="sub" />
    </template>
  </el-submenu>
  <el-menu-item v-else-if="item.meta.type === 4" :key="item.path" index="">
    <template v-if="item.meta">
      <i :class="item.meta.icon"></i>
      <el-link :href="item.meta.src" :title="item.meta.title" :underline="false" target="_blank">
        {{ item.meta.title }}
      </el-link>
    </template>
  </el-menu-item>
  <el-menu-item v-else :key="item.path" :index="item.path">
    <template v-if="item.meta">
      <i :class="item.meta.icon"></i>
      <span>{{ item.meta.title }}</span>
    </template>
  </el-menu-item>
</template>

<script>
import { layout } from '@/configs/layout';

export default {
  name: 'MenuTreeItem',
  data() {
    return {
      layout,
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          children: [],
        };
      },
    },
    index: {
      type: [Number, String],
    },
    mode: {
      type: String,
      default: 'vertical',
      validator: (v) => {
        return ['vertical', 'horizontal'].includes(v);
      },
    },
    collapse: {
      type: Boolean,
      default: false,
    },
    popperAppendToBody: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-tree {
  &.is-horizontal {
    display: flex;
  }
}
</style>
