
// 基础组件的自动化全局注册
import Vue from 'vue'
const requireComponent = require.context('./element', true, /\.js$/)

const modules = requireComponent.keys().reduce((module, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = requireComponent(modulePath)
  module[moduleName] = value.default
  return module
}, {})

// 全局注组件册
Vue.component(
  modules
)