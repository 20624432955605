export const apiModel = {
    UAA: '/uaa',
    ADMIN: '/admin',
    PRODUCT: '/product',
    COMMON: '/common-api',
    DESIGNER: '/designer',
    JOB: '/job',
    ORDER: '/order',
    DISTRIBUTION:'/distribution',
    MEMBER:'/member',
    WEIXIN: '/weixin'
};
