<template>
  <div ref="markedContent">
    <component :is="inst" :key="currentKey" ref="inst" :data="data" :permission="permission"></component>
  </div>
</template>
<script>
import Vue from 'vue/dist/vue.esm.js';
// import {loadView} from "@/store/modules/permission";

export default {
  name: 'DynamicTemplate',
  props: {
    template: {
      type: String,
    },
    currentKey: {
      type: String,
    },
    data: {
      type: Object,
      default: null,
    },
    permission: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      inst: null,
    };
  },
  mounted() {
    this.compile();
  },
  methods: {
    compile() {
      // 变量html是生成好的vue格式的HTML模板字符串，
      // 这个模板里面可以包含各种vue的指令，数据绑定等操作，
      // 比如 v-if, :bind, @click 等。
      if (!this.template) {
        return;
      }

      // Vue.extend是vue的组件构造器，专门用来构建自定义组件的，
      // 但是不会注册，类似于js中的createElement，
      // 创建但是不会添加。
      // 在这里创建出一个子组件对象构造器。
      this.inst = Vue.extend({
        props: {
          data: {
            type: Object,
            default: () => {
              return {};
            },
          },
          permission: {
            type: Object,
            default: () => {
              return {};
            },
          },
        },
        components: {},
        template: `
		  <div>${this.template}</div>`,
      });
    },
  },
  // 本质上来讲，这个子组件不是任何组件的子组件，
  // 它是由vue直接在全局动态生成的一个匿名组件，然后将它插入到当前位置的。
  // 也正是因此，它才能够完成动态的生成和添加。
};
</script>
