<template>
  <div>
    <el-upload
      class="pic-uploader-component"
      :auto-upload="false"
      action=""
      :headers="headers"
      :show-file-list="false"
      :on-change="uploadChange"
    >
      <img v-if="resourcesUrl" :src="resourcesUrl" class="pic" />
      <img v-else-if="inUrl" :src="inUrl" class="pic" />
      <i v-else class="el-icon-plus pic-uploader-icon" />
    </el-upload>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { mapGetters } from 'vuex'
import { upload } from '@/api/upload'
export default {
  name: 'smart-update-image',
  props: {
    value: {
      default: 'true',
      type: String
    },
    inUrl: {
      default: '',
    }
  },
  data () {
    return {
      resourcesUrl: '',
      headers: {
        Authorization: getToken()
      },
      url: ""
    }
  },
  computed: {
    // ...mapGetters(['qiNiuUploadApi'])
  },
  methods: {
    // 图片上传
    handleUploadSuccess (response, file, fileList) {
      this.$emit('input', file.response.data[0])
    },
    // 限制图片上传大小
    beforeAvatarUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    uploadChange (file, fileList) {
      const formData = new FormData()
      formData.append('file', file.raw)
      upload.uploadFile(formData).then(data => {
        this.resourcesUrl = data.data.url
        this.$emit("backUrl", this.resourcesUrl)
      })
    }
  }
}
</script>

<style lang="scss">
.pic-uploader-component .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  .pic-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .pic {
    width: 178px;
    height: 178px;
    display: block;
  }
}
.pic-uploader-component .el-upload:hover {
  border-color: #409eff;
}
</style>
