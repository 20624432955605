
import Vue from 'vue'
import { Mag } from '@/utils/message'
import { EmptyUtil } from '@/utils/emptyUtils'
import { Notification } from 'element-ui'
export class ListService {
  title

  nameKey

  rowKey

  dataSource = Vue.observable([]);

  enablePagination = true

  permission

  selectedRowKeys = []

  disabledBatch = true



  /**
   * @desc 额外的数据搜索对象
   * */
  searchData = {}

  TB = {
    stripe: 'stripe',
    rowKey: 'id',
    height: '450px',
    style: 'width: 100%',
    selectable: (row, index) => {
      this.selectedRowKeys = row.map(item => { return item.id })
      console.log('selectedRowKeys', this.selectedRowKeys)
    }
  }

  pagination = {
    current: 1,
    pageSize: 20,
    pageSizes: [20, 40, 60, 80],
    total: 0,
    layout: 'total, sizes, prev, pager, next, jumper'
  }

  /**
   * @desc 加载数据的执行方法
   * */
  fetchXhr

  /**
   * @desc 删除数据的执行方法
   * */
  deleteXhr



  /**
     * 服务初始化
     * @param title  实例标题
     * @param rowKey   数据的主键字段名称
     * @param nameKey 数据对应的标题字段名称
     * */

  constructor({ title, rowKey, nameKey = null }) {
    this.title = title
    this.rowKey = rowKey
    this.nameKey = nameKey
  }

  /**
   * @desc 初始化列表查询执行方法
   * @param xhr 列表请求方法
   * @param enablePagination 是否开启分页查询 默认开启
   * */
  setFetchXhr (xhr, enablePagination = true) {
    this.fetchXhr = xhr
    console.log('xhr------>',xhr)
    // console.log('enablePagination------>',enablePagination)
    if (enablePagination === false) {
      this.pagination = false
      this.enablePagination = false
    }
    // console.log('this------>',this)

    return this
  }
  /**
   * @desc 初始化数据导出方法
   * @param xhr 执行数据导出的执行方法
   * */
  setExportXhr (xhr) {
    this.exportXhr = xhr
    return this
  }
  /**
   * @desc 初始化数据删除执行方法
   * @param xhr 执行数据删除的执行方法
   * */
  setDeleteXhr (xhr) {
    this.deleteXhr = xhr
    return this
  }

  /**
   * @desc 添加额外的搜索条件
   * @param data 注入额外的搜索条件对象
   * */
  addSearchParams = (data) => {
    this.searchData = { ...this.searchData, ...data }
    this.onFetch()
  }

  /**
     * @desc  调用数据请求方法
     * */
  onFetch = async () => {
    if (typeof this.fetchXhr !== 'function') {
      Mag.error('数据对象操作异常:当前实例尚未配置或初始化 【setFetchXhr】方法')
      return
    }
    try {
      // 处理查询参数 如果有开启了分页功能  那么查询参数需要合并额外的分页参数
      const searchParams = this.enablePagination
        ? { ...this.searchData, pageSize: this.pagination.pageSize, currPage: this.pagination.current }
        : { ...this.searchData }
      const { data } = await this.fetchXhr(searchParams)
      if (this.enablePagination) {
        this.dataSource = data.list
        this.pagination.total = data.totalCount
      } else {
        this.dataSource = data

      }
    } catch (e) {
      console.error(e)
    } finally {
      this.TB.loading = false
    }
  }

  /**
   * @desc  删除对象
   * @param row 可选值：要删除的数据， 如果为空则删除选中的数据
   * @param callback  删除成功后执行的回调参数 不配置则重新加载数据
   *  */
  onDelete = (row = {}, callback = null) => {
    if (typeof this.deleteXhr !== 'function') {
      Mag.error('数据对象操作异常:当前实例尚未配置或初始化 【deleteXhr】方法')
      return
    }
    let msg
    let data = []
    console.log('66',row,this.nameKey)
    if (EmptyUtil.isNotEmptyObject(row)) {
      const tip = this.nameKey ? `【${row[this.nameKey]}】` : ''
      msg = `确定要删除${tip}这条 ${this.title} 数据吗?`
      data.push(row[this.rowKey])
    } else {
      data = this.selectedRowKeys
      msg = `确定要批量删除选中的 ${data.length} 条 ${this.title} 数据吗?`
    }
    const sendAjax = async () => {
      const { message } = await this.deleteXhr(data)
      // Mag.success(message)
      Notification({
        type: 'success',
        title: '删除成功',
      })
      if (callback != null) {
        callback()
      } else {
        this.selectedRowKeys = []
        await this.onFetch()
      }
    }
    Mag.confirm(msg, sendAjax)
  }

  /**
   * @desc  翻页方法(1)
   * */
  onCurrentChange = async e => {
    if (!this.enablePagination) {
      return
    }
    this.pagination.current = e
    await this.onFetch()
  }
  /**
   * @desc  翻页方法(2)
   * */
  onPageSizeChange = async e => {
    if (!this.enablePagination) {
      return
    }
    this.pagination.pageSize = e
    await this.onFetch()
  }

  /**
   * @desc  搜索回调
   * */
  onSearch = async (e) => {
    if (this.enablePagination) {
      this.pagination.current = 1
    }
    this.searchData = e
    await this.onFetch()
  }

  /**
 * @desc 重置/刷新
 */
  onReset = async () => {
    this.pagination.current = 1
    this.pagination.pageSize = 10
    await this.onFetch()
  };


  /**
   * 编辑弹窗控制层
   * */
  editCtr = {
    show: false,
    record: {},
    open: (e = {}) => {
      this.editCtr.show = true
      this.editCtr.record = e
    },
    close: async (e) => {
      this.editCtr.show = false
      this.editCtr.record = {}
      if (e === 'NONE') return
      if (e === 'RELOAD' && this.enablePagination) this.pagination.current = 1
      await this.onFetch()
    },
  }

  /**
   * 只读弹窗
   * */
  readCtr = {
    record: {},
    num: null,
    open: (e = {}, num) => {
      this.readCtr.num = num
      this.readCtr.record = e
    },
    close: async (e) => {
      this.readCtr.record = {}
      this.readCtr.num = null
    },
  }
  /**
   * 多次调用弹窗
   * */
  muiltCtr = {
    record: {},
    num: null,
    open: (e = {}, num) => {
      this.muiltCtr.num = num
      this.muiltCtr.record = e
    },
    close: async (e) => {
      this.muiltCtr.record = {}
      this.muiltCtr.num = null
      if (e === 'NONE') return
      if (e === 'RELOAD' && this.enablePagination) this.pagination.current = 1
      await this.onFetch()
    },
  }

  /**
  * 批量选择
  * */

  handleSelectionChange = (e) => {
    this.selectedRowKeys.length = e.length
    this.disabledBatch = this.selectedRowKeys.length === 0
  }


  onExport = () => {
    if (typeof this.exportXhr !== 'function') {
      Mag.error('尚未初始化数据导出的执行方法,请使用 【setExportXhr】方法进行初始化')
      return
    }
    const sendAjax = async () => {
      const { data } = await this.exportXhr(this.searchData.value)

      Mag.success(data)
    }
    const msg = `确定要根据当前查询条件导出${this.title}查询结果吗?`
    Mag.confirm(msg, sendAjax, '确定导出')
  }
}
