import { defHttp } from '@/utils/https'
import { apiModel } from '@/configs/apiModel'

const MODELS = {
  SYSTEM_COMMON: `${apiModel.ADMIN}/sys/common`,
  SYSTEM_MENU: `${apiModel.ADMIN}/sys/menu`,
  SYSTEM_ADMIN: `${apiModel.ADMIN}/sys/admin`,
  SYSTEM_ROLE: `${apiModel.ADMIN}/sys/role`,
  SYSTEM_ORG: `${apiModel.ADMIN}/sys/org`,
  SYSTEM_DICT: `${apiModel.COMMON}/dict`,
  SYSTEM_PARAM: `${apiModel.COMMON}/param`,
  SYSTEM_POST: `${apiModel.ADMIN}/sys/post`,
  SYSTEM_BUSINESS: `${apiModel.DESIGNER}/business`,
  SYSTEM_SCHEMA_RELATION: `${apiModel.DESIGNER}/schemaRelation`,
  SYSTEM_METADATA: `${apiModel.DESIGNER}/metadata`,
  SYSTEM_DESIGNER_COMMON: `${apiModel.DESIGNER}/common`, //
  SYSTEM_DESIGNER_BUSINESS_TABLE: `${apiModel.DESIGNER}/business-table`,
  SYSTEM_LOG: `${apiModel.JOB}/log`,
  SYSTEM_GROUP: `${apiModel.JOB}/group`,
}
export const systemApi = {
  common: {
    // 获取系统
    getTenantInfo: (tenantId, params) => {
      return defHttp.get({
        url: `${apiModel.ADMIN}/common/tenant/info/${tenantId}`,
        params,
      })
    },
  },
  menu: {
    // 系统列表
    getSystemBizList: (params) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_MENU}/biz-list`,
        params,
      })
    },
    // 系统菜单列表
    getSystemMenuList: (params) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_MENU}/list`,
        params,
      })
    },
    // 系统角色菜单列表
    getSystemMenuRoleTree: (params) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_MENU}/menu-role-tree`,
        params,
      })
    },
    // 菜单信息 TODO 接口有问题
    getMenuById: (params) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_MENU}/get`,
        params,
      })
    },
    // 编辑菜单
    addMenu: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_MENU}/add`,
          params,
        },
        {
          isTransformResponse: false,
        }
      )
    },
    // 编辑菜单
    updateMenu: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_MENU}/update`,
          params,
        },
        {
          isTransformResponse: false,
        }
      )
    },
    // 新增菜单
    deleteMenu: (params) => {
      return defHttp.post({
        url: `${MODELS.SYSTEM_MENU}/delete`,
        params,
      })
    },
  },
  admin: {
    // 岗位列表
    getPostList: (params) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_ADMIN}/post-list`,
        params,
      })
    }, // 角色列表
    getRoleList: (params) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_ADMIN}/role-list`,
        params,
      })
    },
    // 用户详情
    getUserInfoById: (id) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_ADMIN}/${id}`,
      })
    },
    addUser: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_ADMIN}/add`,
          params,
        },
        {
          isTransformResponse: false,
        }
      )
    },
    updateUser: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_ADMIN}/update`,
          params,
        },
        {
          isTransformResponse: false,
        }
      )
    },
    userState: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_ADMIN}/state`,
          params,
        },
        {
          postForm: true,
          isTransformResponse: false,
        }
      )
    },
    deleteUser: (params) => {
      return defHttp.post({
        url: `${MODELS.SYSTEM_ADMIN}/delete`,
        params,
      })
    },
    checkLoginName: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_ADMIN}/check-loginname-unique`,
          params,
        },
        {
          postForm: true,
        }
      )
    },
    checkEmail: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_ADMIN}/check-email-unique`,
          params,
        },
        {
          postForm: true,
        }
      )
    },
    checkJobNo: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_ADMIN}/check-jobno-unique`,
          params,
        },
        {
          postForm: true,
        }
      )
    },
    checkMobile: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_ADMIN}/check-mobile-unique`,
          params,
        },
        {
          postForm: true,
        }
      )
    },
    checkIdCard: (params) => {
      return defHttp.post({
        url: `${MODELS.SYSTEM_ADMIN}/check-idcard-unique`,
        params,
      })
    },
  },
  role: {
    // 角色详情
    getRoleInfoById: (id) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_ROLE}/${id}`,
      })
    },
    // 新增角色
    addRole: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_ROLE}/add`,
          params,
        },
        {
          isTransformResponse: false,
        }
      )
    },
    // 编辑角色
    updateRole: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_ROLE}/update`,
          params,
        },
        {
          isTransformResponse: false,
        }
      )
    },
    roleState: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_ROLE}/state`,
          params,
        },
        {
          postForm: true,
          isTransformResponse: false,
        }
      )
    },
    // 删除角色
    deleteRole: (params) => {
      return defHttp.post({
        url: `${MODELS.SYSTEM_ROLE}/delete`,
        params,
      })
    },
  },
  dict: {
    // 新增字典
    addDict: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_DICT}/add`,
          params,
        },
        {
          isTransformResponse: false,
        }
      )
    },
    // 编辑字典
    updateDict: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_DICT}/update`,
          params,
        },
        {
          isTransformResponse: false,
        }
      )
    },
    //修改字典状态
    dictState: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_DICT}/state`,
          params,
        },
        {
          postForm: true,
          isTransformResponse: false,
        }
      )
    },
    getDictById: (id) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_DICT}/${id}`,
      })
    },
    // 删除字典
    deleteDict: (params) => {
      return defHttp.post({
        url: `${MODELS.SYSTEM_DICT}/delete`,
        params,
      })
    },
  },
  sysParam: {
    // 新增参数
    addParam: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_PARAM}/add`,
          params,
        },
        {
          isTransformResponse: false,
        }
      )
    },
    // 编辑参数
    updateParam: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_PARAM}/update`,
          params,
        },
        {
          isTransformResponse: false,
        }
      )
    },
    //修改参数状态
    paramState: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_PARAM}/state`,
          params,
        },
        {
          postForm: true,
          isTransformResponse: false,
        }
      )
    },
    //查询单条参数
    getParamById: (id) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_PARAM}/${id}`,
      })
    },
    // 删除参数
    deletePram: (params) => {
      return defHttp.post({
        url: `${MODELS.SYSTEM_PARAM}/delete`,
        params,
      })
    },
  },
  sysPost: {
    // 新增参数
    addPost: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_POST}/add`,
          params,
        },
        {
          isTransformResponse: false,
        }
      )
    },
    // 编辑参数
    updatePost: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_POST}/update`,
          params,
        },
        {
          isTransformResponse: false,
        }
      )
    },
    //修改参数状态
    postState: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_POST}/state`,
          params,
        },
        {
          postForm: true,
          isTransformResponse: false,
        }
      )
    },
    // 删除参数
    deletePost: (params) => {
      return defHttp.post({
        url: `${MODELS.SYSTEM_POST}/delete`,
        params,
      })
    },
  },
  business: {
    // 新增参数
    addBusiness: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_BUSINESS}/add`,
          params,
        },
        {
          isTransformResponse: false,
        }
      )
    },
    // 编辑参数
    updateBusiness: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_BUSINESS}/update`,
          params,
        },
        {
          isTransformResponse: false,
        }
      )
    },
    // 删除参数
    deleteBusiness: (params) => {
      return defHttp.post({
        url: `${MODELS.SYSTEM_BUSINESS}/delete`,
        params,
      })
    },
    //查询数据库表
    getTableList: (schemaName) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_METADATA}/list/table?schemaName=${schemaName}`,
      })
    },
    //获取表字段
    getTableColumn: (params) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_METADATA}/list/column`,
        params,
      })
    },
    //查询服务和数据库关系对应列表
    getRelationList: (params) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_SCHEMA_RELATION}/querySchemaRelation`,
        params,
      })
    },
    //根据中文查询出拼音
    getSpellByChinese: (params) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_DESIGNER_COMMON}/pinyin/convert`,
        params,
      })
    },
    // 获取后台枚举
    getTypeEnum: (typeEnum) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_DESIGNER_COMMON}/list/enum/${typeEnum}`,
      })
    },
    // 业务表-表列表
    businessTableList: (bizCode) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_DESIGNER_BUSINESS_TABLE}/list/${bizCode}`,
      })
    },
    // 业务表-表别名
    businessTableAlias: (bizCode) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_DESIGNER_BUSINESS_TABLE}/alias/${bizCode}`,
      })
    },
    // 业务表-新增表
    businessTableAdd: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_DESIGNER_BUSINESS_TABLE}/add`,
          params,
        },
        {
          isTransformResponse: false,
        }
      )
    },
  },
  serviceSchemaRelation: {
    //服务和数据库关系对应列表
    // 新增参数
    addSchemaRelation: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_SCHEMA_RELATION}/save`,
          params,
        },
        {
          isTransformResponse: false,
        }
      )
    },
    // 编辑参数
    updateSchemaRelation: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_SCHEMA_RELATION}/update`,
          params,
        },
        {
          isTransformResponse: false,
        }
      )
    },
    // 删除参数
    deleteSchemaRelation: (params) => {
      return defHttp.post({
        url: `${MODELS.SYSTEM_SCHEMA_RELATION}/delete`,
        params,
      })
    },
  },
  dispatchLog: {
    //调度日志调用接口
    // 新增参数
    executeDetail: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_LOG}/execute-detail`,
          params,
        },
        {
          isTransformResponse: false,
          postForm: true,
        }
      )
    },
    // 编辑参数
    killDetail: (id) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_LOG}/kill/${id}`,
      })
    },
    // 清理日志
    clearLog: (param) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_LOG}/clear`,
        param,
      })
    },
  },
  actuator: {
    //调度日志调用接口
    // 新增参数
    addActuator: (params) => {
      return defHttp.post({
        url: `${MODELS.SYSTEM_GROUP}/save`,
        params,
      })
    },
    // 编辑参数
    updateActuator: (params) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_GROUP}/update`,
        params,
      })
    },
    // 删除参数
    deleteActuator: (id) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_GROUP}/remove/${id}`,
      })
    },
  },
  org: {
    // 部门列表
    getOrgList: (params) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_ORG}/list`,
        params,
      })
    },
    // 部门详情
    getOrgById: (id) => {
      return defHttp.get({
        url: `${MODELS.SYSTEM_ORG}/${id}`,
      })
    },
    // 新增部门
    addOrg: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_ORG}/add`,
          params,
        },
        {
          isTransformResponse: false,
        }
      )
    },
    // 编辑部门
    updateOrg: (params) => {
      return defHttp.post(
        {
          url: `${MODELS.SYSTEM_ORG}/update`,
          params,
        },
        {
          isTransformResponse: false,
        }
      )
    },
    // 删除部门
    deleteOrg: (params) => {
      return defHttp.post({
        url: `${MODELS.SYSTEM_ORG}/delete`,
        params,
      })
    },
  },
}
