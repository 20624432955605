<template>
  <div v-if="type == 'image'">
    <div v-if="myValue">
      <ul
        class="el-upload-list el-upload-list--picture-card"
        :style="'width: ' + width + 'px;height: ' + height + 'px'"
      >
        <li
          tabindex="0"
          class="el-upload-list__item is-ready"
          :style="'width: ' + width + 'px;height: ' + height + 'px'"
        >
          <div>
            <el-image :src="myValue" fit="contain" alt="" class="el-upload-list__item-thumbnail">
              <div slot="error" class="image-slot">
                <img src="@/assets/error_1.png" alt="" />
              </div>
            </el-image>
            <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-preview" @click="zoomMaterial(myValue)">
                <i class="el-icon-view" />
              </span>
              <span class="el-upload-list__item-delete" @click="deleteMaterial">
                <i class="el-icon-delete" />
              </span>
            </span>
          </div>
        </li>
      </ul>
    </div>
    <div
      v-else
      tabindex="0"
      class="el-upload el-upload--picture-card"
      :style="'width: ' + width + 'px;height: ' + height + 'px;' + 'line-height:' + height + 'px;'"
      @click="toSeleteMaterial"
    >
      <i class="el-icon-plus" />
    </div>

    <el-dialog append-to-body :visible.sync="dialogVisible" width="35%">
      <img :src="url" alt="" style="width: 100%" />
    </el-dialog>

    <el-dialog title="图片素材库" append-to-body :visible.sync="listDialogVisible" width="70%">
      <el-container>
        <el-aside width="unset">
          <div style="margin-bottom: 10px">
            <el-button class="el-icon-plus" size="small" @click="materialgroupAdd()"> 添加分组 </el-button>
          </div>
          <el-tabs
            v-model="materialgroupObjName"
            v-loading="materialgroupLoading"
            tab-position="left"
            @tab-click="tabClick"
          >
            <el-tab-pane v-for="item in materialgroupList" :key="item.id" :name="String(item.id)">
              <span slot="label"> {{ item.name }}</span>
            </el-tab-pane>
          </el-tabs>
        </el-aside>
        <el-main>
          <el-card>
            <div slot="header">
              <el-row>
                <el-col :span="12">
                  <span>{{ materialgroupObj.name }}</span>
                  <!--                  <span v-if="materialgroupObj.id != '-1'">-->
                  <!--                    <el-button size="small" type="text" class="el-icon-edit" style="margin-left: 10px;" @click="materialgroupEdit(materialgroupObj)">重命名</el-button>-->
                  <!--                    <el-button size="small" type="text" class="el-icon-delete" style="margin-left: 10px;color: red" @click="materialgroupDelete(materialgroupObj)">删除</el-button>-->
                  <!--                  </span>-->
                </el-col>
                <el-col :span="12" style="text-align: right">
                  <el-upload
                    action="qiNiuUploadApi"
                    :headers="headers"
                    :auto-upload="false"
                    :show-file-list="false"
                    :on-change="(response, file) => handleSuccess(response, file)"
                    :before-upload="beforeUpload"
                    multiple
                  >
                    <el-button size="small" type="primary">上传图片</el-button>
                  </el-upload>
                </el-col>
              </el-row>
            </div>
            <div v-loading="tableLoading">
              <el-alert v-if="(LIST.length = 0)" title="暂无数据" type="info" :closable="false" center show-icon />
              <el-row :gutter="5">
                <el-checkbox-group v-model="urls" :max="1">
                  <el-col :span="4">
                    <el-card
                      v-if="progress"
                      :body-style="{ padding: '5px' }"
                      style="height: 180px; display: flex; justify-content: center; align-items: center"
                    >
                      <el-progress type="circle" :percentage="progressPercent"></el-progress>
                    </el-card>
                  </el-col>

                  <el-col v-for="(item, index) in LIST" :key="index" :span="4">
                    <el-card :body-style="{ padding: '5px' }" v-if="item != 0">
                      <el-image
                        style="width: 100%; height: 100px"
                        :src="item.url"
                        fit="contain"
                        :preview-src-list="[item.url]"
                        :z-index="999"
                      >
                        <div slot="error" class="image-slot">
                          <img src="@/assets/error_1.png" alt="" />
                        </div>
                      </el-image>
                      <div>
                        <el-checkbox class="material-name" :label="item.url"> 选择 </el-checkbox>
                        <el-row>
                          <el-col :span="24" class="col-do">
                            <el-button type="text" size="medium" @click="materialDel(item)">删除</el-button>
                          </el-col>
                        </el-row>
                      </div>
                    </el-card>
                  </el-col>
                </el-checkbox-group>
              </el-row>
              <el-pagination
                :current-page.sync="page.currentPage"
                :page-sizes="[12, 24]"
                :page-size="page.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="page.total"
                class="pagination"
                @size-change="sizeChange"
                @current-change="pageChange"
              />
            </div>
          </el-card>
        </el-main>
      </el-container>
      <span slot="footer" class="dialog-footer">
        <el-button @click="listDialogVisible = false" class="unify-button">取 消</el-button>
        <el-button type="primary" @click="sureUrls" class="unify-button">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { fileGroup, fileUpload } from '@/api/file';
import { getToken } from '@/utils/auth';
import { upload } from '@/api/upload';
import { mapGetters } from 'vuex';
import { Notification } from 'element-ui';
export default {
  name: 'MaterialList',
  props: {
    // 素材数据
    value: {
      type: String,
    },
    // 素材类型
    type: {
      type: String,
    },
    // 素材限制数量，默认5个
    num: {
      type: Number,
      default() {
        return 5;
      },
    },
    // 宽度
    width: {
      type: Number,
      default() {
        return 150;
      },
    },
    // 宽度
    height: {
      type: Number,
      default() {
        return 150;
      },
    },
  },
  data() {
    return {
      times: null,
      progressPercent: 0,
      progress: false,
      headers: {
        Authorization: getToken(),
      },
      dialogVisible: false,
      myValue: this.value,
      url: '',
      listDialogVisible: false,
      materialgroupList: [],
      materialgroupObjName: '',
      materialgroupObj: {},
      materialgroupLoading: false,
      LIST: [],
      resultNumber: 0,
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 12, // 每页显示多少条
        ascs: [], // 升序字段
        descs: 'create_time', // 降序字段
      },
      tableLoading: false,
      groupId: null,
      urls: [],
    };
  },
  watch: {
    value: function (val) {
      this.myValue = val;
    },
  },
  methods: {
    moveMaterial(index, type) {
      if (type == 'up') {
        const tempOption = this.value[index - 1];
        this.$set(this.value, index - 1, this.value[index]);
        this.$set(this.value, index, tempOption);
      }
      if (type == 'down') {
        const tempOption = this.value[index + 1];
        this.$set(this.value, index + 1, this.value[index]);
        this.$set(this.value, index, tempOption);
      }
    },
    zoomMaterial(myValue) {
      this.dialogVisible = true;
      this.url = myValue;
    },
    deleteMaterial() {
      const that = this;
      this.$confirm('是否确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(function () {
        that.myValue = '';
        that.urls = [];
        that.$emit('delImg', that.myValue);
      });
    },
    toSeleteMaterial() {
      this.listDialogVisible = true;
      if (this.LIST.length <= 0) {
        this.materialgroupPage();
      }
    },
    materialgroupPage() {
      this.materialgroupLoading = true;
      fileGroup.getData().then((res) => {
        this.materialgroupLoading = false;
        const materialgroupList = res.data;
        // materialgroupList.unshift({
        //   id: '-1',
        //   name: '全部分组'
        // })
        this.materialgroupList = materialgroupList;
        this.tabClick({
          index: 0,
        });
        if (this.materialgroupList[0]) {
          this.materialgroupObjName = this.materialgroupList[0].name;
        }
      });
      // materialgroupPage({
      //   total: 0, // 总页数
      //   currentPage: 1, // 当前页数
      //   pageSize: 200, // 每页显示多少条
      //   ascs: [], // 升序字段
      //   descs: 'create_time'// 降序字段
      // }).then(response => {
      //   this.materialgroupLoading = false
      //   const materialgroupList = response.content
      //   materialgroupList.unshift({
      //     id: '-1',
      //     name: '全部分组'
      //   })
      //   this.materialgroupList = materialgroupList
      //   this.tabClick({
      //     index: 0
      //   })
      // })
    },
    materialgroupDelete(materialgroupObj) {
      const that = this;
      this.$confirm('是否确认删除该分组？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(function () {
        fileGroup.removeData({ id: materialgroupObj.id }).then(function () {
          that.$delete(that.materialgroupList, materialgroupObj.index);
        });
      });
    },
    materialgroupEdit(materialgroupObj) {
      const that = this;
      this.$prompt('请输入分组名', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: materialgroupObj.name,
      })
        .then(({ value }) => {
          fileGroup
            .updateData({
              id: materialgroupObj.id,
              name: value,
            })
            .then(function () {
              materialgroupObj.name = value;
              that.$set(that.materialgroupList, materialgroupObj.index, materialgroupObj);
            });
        })
        .catch(() => {});
    },
    materialgroupAdd() {
      const that = this;
      this.$prompt('请输入分组名', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(({ value }) => {
          fileGroup
            .addData({
              name: value,
            })
            .then(function () {
              that.materialgroupPage();
            });
        })
        .catch(() => {});
    },
    tabClick(tab, event) {
      this.urls = [];
      const index = Number(tab.index);
      const materialgroupObj = this.materialgroupList[index];
      materialgroupObj.index = index;
      this.materialgroupObj = materialgroupObj;
      this.materialgroupObjName = materialgroupObj.name;
      this.page.currentPage = 1;
      this.page.total = 0;
      if (materialgroupObj.id != '-1') {
        this.groupId = materialgroupObj.id;
      } else {
        this.groupId = null;
      }
      this.getPage(this.page);
    },
    getPage(page, params) {
      this.tableLoading = true;
      const query = {
        currPage: page.currentPage,
        pageSize: page.pageSize,
        subject: this.groupId,
      };
      fileUpload
        .getData(query)
        .then((res) => {
          const VAL = { ...res };
          this.progressPercent = 100;
          this.progress = false;
          this.page.total = VAL.data.totalCount;
          this.page.currentPage = VAL.data.currPage;
          this.page.pageSize = VAL.data.pageSize;
          this.LIST = { ...VAL.data.list };
          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    sizeChange(val) {
      this.page.pageSize = val;
      this.getPage(this.page);
    },
    pageChange(val) {
      this.page.currentPage = val;
      // this.page.pageSize = val
      this.getPage(this.page);
    },
    materialRename(item) {
      const that = this;
      this.$prompt('请输入素材名', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: item.name,
      })
        .then(({ value }) => {
          // eslint-disable-next-line no-undef
          putObj({
            id: item.id,
            name: value,
          }).then(function () {
            that.getPage(that.page);
          });
        })
        .catch(() => {});
    },
    materialUrl(item) {
      const that = this;
      this.$prompt('素材链接', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: item.url,
      })
        .then(({ value }) => {})
        .catch(() => {});
    },
    materialDel(item) {
      const that = this;
      this.$confirm('是否确认删除该素材？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(function () {
        // eslint-disable-next-line no-undef
        // delObj(item.id)
        //   .then(function () {
        //     that.getPage(that.page)
        //   })
        fileGroup.removePic([item.id]).then((res) => {
          if (res.status === 200) {
            Notification({
              type: 'success',
              title: '删除成功',
            });
          }
          that.getPage(that.page);
        });
      });
    },
    handleCommand(command) {
      const that = this;
      const s = command.split('-');
      // eslint-disable-next-line no-undef
      putObj({
        id: s[0],
        groupId: s[1],
      }).then(function () {
        that.getPage(that.page);
      });
    },
    handleProgress(event, file, fileList) {
      // let uploadProgress = file.percentage.toFixed(0)
      // this.uploadProgress = uploadProgress
    },
    handleSuccess(res, file) {
      const formData = new FormData();
      formData.append('file', res.raw);
      formData.append('group', this.groupId != '-1' ? this.groupId : null);
      this.progress = true;
      this.progressPercent = 0;
      upload.uploadFile(formData).then((data) => {
        this.times = setInterval(() => {
          ++this.progressPercent;
          if (this.progressPercent > 89) {
            clearInterval(this.times);
            this.times = null;

            this.getPage(this.page);
          }
        }, 20);
      });
      // const that = this
      // this.uploadProgress = 0
      // addObj({
      //   type: '1',
      //   groupId: this.groupId != '-1' ? this.groupId : null,
      //   name: file.name,
      //   url: response.link
      // }).then(() => {
      //   this.resultNumber++
      //   if (fileList.length === this.resultNumber) {
      //     that.getPage(that.page)
      //     this.resultNumber = 0
      //   }
      // })
    },
    beforeUpload(file) {
      const isPic =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/gif' ||
        file.type === 'image/jpg';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isPic) {
        this.$message.error('上传图片只能是 JPG、JPEG、PNG、GIF 格式!');
        return false;
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isPic && isLt2M;
    },
    sureUrls() {
      this.myValue = this.urls[0];
      this.$emit('input', this.urls[0]);
      this.listDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
/*/deep/ .el-icon-circle-close{*/
/*  color: red;*/
/*}*/
.material-name {
  padding: 8px 0px;
}
.col-do {
  text-align: center;
}
.button-do {
  padding: unset !important;
  font-size: 12px;
}

.el-upload--picture-card i {
  font-size: 16px;
}
.el-upload-list--picture-card .el-upload-list__item:last-child {
  margin: 0;
}
.el-upload-list--picture-card {
  display: block;
}
</style>
