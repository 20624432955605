<template>
  <div :class="{ 'has-aside': hasAside }" class="table-view" ref="rz-table-view">
    <div class="table-header" ref="table-header">
      <slot v-if="slots.header" name="header"></slot>
      <template v-else>
        <SearchView
          :items="[...query]"
          :loading="loading"
          :model="form"
          :selection="selection"
          @search="(obj) => pageChange(1, obj)"
        ></SearchView>
      </template>
      <el-row v-if="showTools" class="tools-view" style="margin-bottom: 5px" type="flex">
        <div>
          <template v-for="(item, index) of tools">
            <el-button :key="index" @click="toolAction(item)">{{ item.text }}</el-button>
          </template>
        </div>
        <el-dropdown :hide-on-click="false" class="ml-a" trigger="click">
          <el-link :underline="false"><i class="el-icon-s-tools"></i></el-link>
          <el-dropdown-menu slot="dropdown">
            <el-checkbox-group v-model="columnSelection">
              <el-dropdown-item v-for="(item, index) in columnList" :key="index">
                <el-checkbox :label="item.label"></el-checkbox>
              </el-dropdown-item>
            </el-checkbox-group>
          </el-dropdown-menu>
        </el-dropdown>
        <el-link :underline="false" class="ml-xs">
          <i class="el-icon-refresh" @click="reload()"></i>
        </el-link>
      </el-row>
    </div>
    <el-table
      :key="columnSelection.length"
      ref="tableRef"
      v-loading="loading"
      :data="list"
      class="table"
      :height="options.height || '100%'"
      :max-height="options.height ? 'auto' : '100%'"
      v-bind="{ border: true, ...bindValues }"
      @select="handleSelect"
      @select-all="handleSelectAll"
    >
      <el-table-column
        v-for="(column, index) in columnList.filter((item) => columnSelection.includes(item.label))"
        :key="index"
        v-bind="{ label: column.label, prop: column.prop, type: column.type, align: column.align, width: column.width }"
      >
        <template v-if="column.slot && Object.keys({ ...slots, ...scopedSlots }).includes(column.slot)" #default="data">
          <slot :name="column.slot" v-bind="{ ...data }"></slot>
        </template>
        <template
          v-else-if="column.prop && Object.keys({ ...slots, ...scopedSlots }).includes(column.prop)"
          #default="data"
        >
          <slot :name="column.prop" v-bind="{ ...data }"></slot>
        </template>
        <template v-else-if="column.prop === 'state' || column.type === 'state'" #default="{ row }">
          <el-link :type="['info', 'success'][row[column.prop]]" :underline="false" style="font-size: 12px">
            {{ row[column.prop] !== 0 && !row[column.prop] ? '--' : enums.state[row[column.prop]] }}
          </el-link>
        </template>
        <template v-else-if="!column.type" #default="{ row }">
          <el-tooltip :content="row[column.prop] !== 0 && !row[column.prop] ? '--' : String(row[column.prop])">
            <div class="text-single">{{ row[column.prop] !== 0 && !row[column.prop] ? '--' : row[column.prop] }}</div>
          </el-tooltip>
        </template>
        <template v-else-if="column.type === 'actions' && actions.length" #default="data">
          <div v-for="(item, i) in actions" :key="i">
            <el-button v-if="item.tagName === 'button'" :type="item.type" @click="item.action({ ...data })"
              >{{ item.text }}
            </el-button>
          </div>
        </template>
      </el-table-column>
      <slot></slot>
    </el-table>
    <div class="table-footer" ref="table-pagination">
      <template v-if="slots.footer">
        <slot name="footer"></slot>
      </template>
      <el-pagination
        v-if="showPage"
        :current-page="page.current"
        :page-size="page.size"
        :page-sizes="pageSizes"
        :total="page.total"
        ref="table-pagination"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="sizeChange"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
    <slot name="components"></slot>
    <DynamicTemplate :template="template"></DynamicTemplate>
  </div>
</template>

<script>
import SearchView from '@/components/tableView/SearchView'
import { defHttp } from '@/utils/https'
import { enums } from '@/enum'
import { isFunction } from 'lodash-es'
import DynamicTemplate from '@/components/DynamicTemplate'

export default {
  name: 'TableView',
  components: {
    SearchView,
    DynamicTemplate,
  },
  data () {
    return {
      enums,
      isInitCall: false,
      loading: false,
      template: null,
      api: '',
      method: 'get',
      page: {
        size: 10,
        current: 1,
        total: 0,
      },
      pageSizes: [10, 20, 30, 50],
      list: [],
      selection: [],
      columnSelection: [],
      reloadParams: {},
      tableHeight: '100%',
    }
  },
  props: {
    columns: {
      type: Array,
      default: () => {
        return []
      },
    },
    // 1、传入options.api、options.list时，props传入的data数据无效；2、调用组件init方法，props传入的data数据无效
    data: {
      type: Array,
      default: undefined,
    },
    hasAside: {
      type: Boolean,
      default: true,
    },
    onReload: {
      type: Function,
    },
    /**
     * 列表配置
     * @param {Object} options
     * @param {string} [options.api = ''] 请求列表数据接口
     * @param {Array} [options.list = []] 列表数据
     * @description 1、传入api、list时，props传入的data数据无效；2、传入list将不会使用api去请求数据
     */
    options: {
      type: Object,
      default: () => {
        return {}
      },
    },
    otherParams: {
      type: Object,
      default: () => {
        return {}
      },
    },
    query: {
      type: Array,
      default: () => {
        return []
      },
    },
    tools: {
      type: Array,
      default: () => {
        return []
      },
    },
    actions: {
      type: Array,
      default: () => {
        return []
      },
    },
    showPage: {
      type: Boolean,
      default: true,
    },
    showTools: {
      type: Boolean,
      default: true,
    },
    treeProps: {
      type: Object,
    },
    /**
     * 当表格为存在多选框时，勾选已有数据
     *   selectionKey<String>: 匹配字段key，
     **/
    selectionKey: {
      type: String,
      default: 'productId',
    },
    /**
     * 是否单选,默认false
     */
    isSingle: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    event: 'update',
  },
  computed: {
    bindValues () {
      const values = { ...this.$attrs }
      Object.keys(this.$props).map((key) => {
        if (!['columns', 'data', 'hasAside', 'options', 'query', 'showTools', 'showPage'].includes(key)) {
          values[key] = this.$props[key]
        }
      })
      return values
    },
    columnList: {
      get () {
        return Array.from(this.columns, (item) => {
          item.check = true
          return item
        })
      },
      set () {
        //
      },
    },
    slots () {
      return this.$slots
    },
    scopedSlots () {
      return this.$scopedSlots
    },
    form () {
      let obj = { ...this.otherParams }
      this.query.forEach((item) => {
        item.prop && (obj[item.prop] = item.value)
      })
      return obj
    },
  },
  watch: {
    selection: {
      handler () {
        this.$emit('update', this.selection)
      },
      deep: true,
    },
    data (v) {
      if (!this.isInitCall && !this.options.api && !this.options.list) {
        this.list = v || []
      }
    },
    columns: {
      handler: function (v) {
        this.columnSelection = Array.from(v, (item) => item.label)
      },
      immediate: true,
      deep: true,
    },
    'options.page': {
      handler: function (v) {
        Object.assign(this.page, v)
      },
      immediate: true,
    },
  },
  created () {
    this.checkSlotTag()
  },
  mounted () {
    const { api, list, method = 'get', height } = this.options
    if (api) {
      this.api = api
      this.method = method
      if (!Array.isArray(list)) {
        this.pageInit(this.getList, this.getList)
      } else if (list) {
        this.list = list
      }
    } else if (!this.isInitCall && this.data?.length) {
      this.list = this.data
    }
    // 若不赋值高度，则自动计算
    if (!height) {
      setTimeout(() => {
        // this.computedHeight()
      }, 10)
    }
    // if (!this.tableData) {
    //   this.getList()
    // }
  },
  methods: {
    /**
     * 计算table高度
     * @param {Number} tableHeader 搜索栏高度
     * @param {Number} tableViewHeight 组件整体高度
     * @param {Number} paginationHeight 分页器高度
     */
    computedHeight () {
      const tableHeader = this.$refs['table-header'].clientHeight
      const tableViewHeight = this.$refs['rz-table-view'].clientHeight
      const paginationHeight = this.$refs['table-pagination'].clientHeight
      this.tableHeight = tableViewHeight - tableHeader - paginationHeight - 10 + 'px'
    },
    /**
     * 初始化列表
     * @param {Object} options
     * @param {string} [options.api = ''] 请求列表数据接口
     * @param {Array} [options.list = []] 列表数据
     * @description 1、调用init方法时，props传入的data数据无效；2、传入list将不会使用api去请求数据
     */
    init (options = {}) {
      const { list, method = 'get', selection, selectKey = 'id', params } = options
      this.reloadParams = params
      if (selection?.length) {
        this.setSelection(selection, selectKey)
      }
      Object.assign(this.options, options)
      this.method = method
      this.isInitCall = true
      Object.keys(options).map((key) => {
        if (options[key]) {
          this[key] = options[key]
        }
      })
      if (!Array.isArray(list)) {
        this.getList()
      } else if (list) {
        this.list = list
      }
    },
    async getList (current) {
      if (!this.api) {
        return
      }
      const params = {
        ...this.form,
        ...this.reloadParams,
      }
      if (!this.options.noPage) {
        params.currPage = current || this.page.current
        params.pageSize = this.page.size
      }
      await this.search(params)
    },
    async search (params) {
      this.loading = true
      console.log(this.$store.getters, 'getters1')
      const res = await defHttp[this.method]({
        url: this.api,
        params,
      })
      console.log(this.$store.getters, 'getters2')
      this.loading = false
      if (!res) {
        return
      }
      if (!this.options.noPage) {
        this.list = res.list
        this.page.total = res.totalCount
      } else {
        this.list = res
      }
      this.$nextTick(() => {
        //遍历已渲染的列表
        this.setSelection(this.selection, this.selectionKey)
      })
    },
    reload (params) {
      if (this.onReload && isFunction(this.onReload)) {
        this.onReload()
      } else {
        if (params) {
          this.reloadParams = params
        }
        this.pageChange(1)
      }
    },
    checkSlotTag () {
      const defaultSlots = this.slots.default || []
      defaultSlots.forEach((slot) => {
        if (slot.tag.indexOf('ElTableColumn') === -1) {
          console.warn(`${slot.tag} is not 'ElTableColumn', Please use 'ElTableColumn'`)
        }
      })
    },
    toolAction (tool) {
      if (tool.action) {
        tool.action()
      }
      this.template = tool.template
    },
    sizeChange (val) {
      this.page.size = val
      this.getList()
    },
    pageChange (val, obj = {}) {
      this.page.current = val
      Object.assign(this.reloadParams, obj)
      console.log(this.reloadParams, obj, '----see')
      this.getList()
    },
    handleSelect (selection, row) {
      if (this.isSingle) {
        this.$refs.tableRef?.clearSelection()
        this.$refs.tableRef?.toggleRowSelection(row, true)
        this.selection = [row]
        this.$emit('update', this.selection)
      } else {
        this.handleSelectionChange(selection)
      }
    },
    handleSelectAll (selection) {
      this.handleSelectionChange(selection)
    },
    /**
     * 选中时先剔除已存在的本页选择项，然后将新的合并
     * */
    handleSelectionChange (selection) {
      const otherSelection = this.selection.filter((v) => !v.isThisPage)
      const list = selection.map((v) => {
        return {
          ...v,
          isThisPage: true,
        }
      })
      this.selection = [...otherSelection, ...list]
    },
    /**
     * isThisPage<Boolean> 当前页的选择项
     * */
    setSelection (selection, selectKey) {
      selectKey = selectKey || this.selectionKey
      let selectionClone = selection
      if (selection?.length) {
        this.list.forEach((row) => {
          selectionClone.forEach((v) => {
            if (v[selectKey] === row[selectKey]) {
              this.$refs.tableRef.toggleRowSelection(row, true)
              v.isThisPage = true
            } else {
              v.isThisPage = false
            }
          })
        })
        this.selection = selectionClone
      } else {
        this.$refs.tableRef?.clearSelection()
      }
    },
  },
  beforeDestroy () {
    this.isInitCall = false
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/table-view.scss';
::v-deep .cell {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
