<template>
  <div :id="id" class="rz-modal" :class="{'full-modal':fullscreen}">
    <div v-if="showModal && !fullscreen" :style="{zIndex:zIndex}" class="rz-dialog" @click="modalClick"></div>
    <transition name="bounce">
      <div v-if="show" :style="{width,top,zIndex:zIndex+1}" class="rz-dialog-wrap" :class="{'full-screen':fullscreen}">
        <div class="header text-single" v-if="showHeader">
          <span> {{ title }}</span>
          <i class="el-icon-close" @click="close"></i>
        </div>
        <div class="rz-dialog-body">
          <component :is="content" ref="myComponent" v-model="componentData" v-bind="comProps"
                     @selection="selection"></component>
        </div>
        <div v-if="showBtns" class="buttons">
          <el-button v-if="showCancel" size="small" @click="cancel">{{ cancelBtnText }}</el-button>
          <el-button v-if="showConfirm" size="small" type="primary" @click="confirm">{{ confirmBtnText }}</el-button>
          <el-button v-for="(item,index) in otherBtns" :key="index" :type="item.type" size="small"
                     @click="otherClick(item)">
            {{
              item.text
            }}
          </el-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {props} from "./dialog";

export default {
  props: {
    ...props
  },
  name: "rz-dialog",
  data() {
    return {
      show: false,
      componentData: null,
      zIndex: 2021
    }
  },
  computed: {
    comProps() {
      return {...this.query}
    }
  },
  mounted() {
    setTimeout(() => {
      this.show = true
      const hasModal = document.getElementsByClassName('rz-modal')
      this.zIndex = 20 + hasModal.length
    }, 10)
  },
  methods: {
    otherClick(item) {
      const ref = this.$refs.myComponent
      item.handleClick && typeof item.handleClick === 'function' && item.handleClick(this.close, this.componentData, ref)
    },
    selection(e) {
      this.componentData = e
    },
    modalClick() {
      if (this.closeByClickModal) {
        this.close()
      }
    },
    close() {
      this.show = false
      setTimeout(() => {
        document.getElementById(this.id).remove()
      }, 350)
    },
    cancel() {
      if (this.onCancel && typeof this.onCancel === 'function') {
        this.onCancel(this.close)
      } else {
        this.close()
      }
    },
    async confirm() {
      if (this.formRef) {
        const ref = this.$refs.myComponent.$refs[this.formRef]
        await ref.validate()
      }
      this.onConfirm && typeof this.onConfirm === 'function' && this.onConfirm(this.close, this.componentData, this.$refs.myComponent)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/common.scss";

.rz-dialog {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.35);
  position: fixed;
  z-index: 1;
  top: 0;
}

.rz-dialog-wrap {
  padding: 20px;
  padding-top: 0;
  background: #FFFFFF;
  border-radius: 20px;
  box-sizing: border-box;
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translate(-50%, -0%);
  z-index: 2;
  @include commonShadow;

  &.full-screen {
    top: 0 !important;
    left: 0 !important;
    transform: translate(0, 0);
    width: 100vw !important;
    height: 100vh;
    border-radius: 0px;

    .rz-dialog-body {
      height: calc(100vh - 50px - 62px);
      max-height: unset;

    }
  }

  .header {
    text-align: center;
    font-size: 20px;
    //font-weight: bolder;
    position: relative;
    line-height: 30px;
    height: 50px;
    padding: 10px 24px;
    width: 100%;
    box-sizing: border-box;

    i {
      position: absolute;
      right: 0;
      font-size: 24px;
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);

      &:hover {
        color: $--color-primary;
      }

      &:active {
        opacity: .78;
      }
    }
  }

  .rz-dialog-body {
    max-height: 50vh;
    min-height: 200px;
    overflow-y: auto;
  }

  .buttons {
    text-align: center;
    margin-top: 20px;
  }
}

.bounce-enter-active {
  animation: bounce-in .35s;
}

.bounce-leave-active {
  animation: bounce-in .35s reverse;
}

.full-modal {
  .bounce-enter-active {
    animation: bounce-in-fullscreen .35s;
  }

  .bounce-leave-active {
    animation: bounce-in-fullscreen .35s reverse;
  }
}

@keyframes bounce-in {
  0% {
    transform: scale(0) translate(-50%, -0%);
  }
  50% {
    transform: scale(1.2) translate(-50%, -0%);
  }
  100% {
    transform: scale(1) translate(-50%, -0%);
  }
}

@keyframes bounce-in-fullscreen {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
