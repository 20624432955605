<template>
    <!--密码重置弹出框-->
    <el-dialog title="重置密码" :visible.sync="visible" :append-to-body="true" width="500px" :close-on-click-modal="false">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="原密码" prop="oldPwd">
                <el-input type="password" v-model="ruleForm.oldPwd"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPwd">
                <el-input type="password" v-model="ruleForm.newPwd"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="confirmPassword">
                <el-input type="password" v-model="ruleForm.confirmPassword"></el-input>
            </el-form-item>
            <el-form-item style='text-align: center' label-width='0'>
                <el-button @click="visible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
import { formatPwd } from '@/utils'
import { authApi } from '@/api/auth'
import { mapGetters } from 'vuex'

export default {
    name: 'password',
    data() {
        //前端验证密码
        const confirmPassword = (rule, value, callback) => {
            if (this.ruleForm.newPwd !== value) {
                callback(new Error('确认密码与新密码不一致'));
            } else {
                callback();
            }
        };
        return {
            visible: false,
            ruleForm: {
                name: '',
                oldPwd: '',
                newPwd: '',
                confirmPassword: '',
            },
            rules: {
                oldPwd: [
                    { required: true, message: '原密码不能为空', trigger: 'blur' },
                    { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
                ],
                newPwd: [
                    { required: true, message: '新密码不能为空', trigger: 'blur' },
                    { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
                ],
                confirmPassword: [
                    { required: true, message: '确认密码不能为空', trigger: 'blur' },
                    { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' },
                    { validator: confirmPassword, trigger: 'blur' }
                ],
            },
        }
    },
    computed: {
        ...mapGetters(['userInfo'])
    },
    methods: {
        init() {
            this.visible = true
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                let params = {
                    id: Number(this.userInfo.id),
                    oldPwd: this.ruleForm.oldPwd,
                    newPwd: this.ruleForm.newPwd
                };
                if (valid) {
                    authApi.modifyPwd(params).then(data => {
                        console.log('data',data)
                        if (data && data.status ===500) {
                            // this.$message.error(data.message);
                        } else {
                            this.visible = false;
                            this.$message.success('密码修改成功')
                        }
                    });
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
    }
}
</script>

<style scoped>

</style>
