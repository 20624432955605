<template>
  <div class="smart-page">
    <div class="search">
      <slot name="search"></slot>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
    <slot></slot>
    <div class="footer">
      <slot name="footer"></slot>
    </div>

  </div>
</template>

<script>
export default {
  name: 'smart-page'
}
</script>

<style lang="scss" scoped>
.smart-page {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-direction: column;
  .search {
    border-bottom: 1px solid #f5f5f5;
    box-sizing: border-box;
  }
  .content {
    flex: 1;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .content::-webkit-scrollbar {
    display: none;
  }
  .footer {
    margin-top: 10px;
  }
}
// table表头固定
:deep(.el-table)  {
  overflow: visible;
}
 
:deep(.el-table__header-wrapper)  {
  position: sticky;
  top: 0px;//这个值根据实际情况而定
  z-index: 10;
}

</style>
