import router from '@/router';
import { cloneDeep } from 'lodash-es';

export const useMenu = (menus, path) => {
  // console.log(menus, '---menus');
  const list = cloneDeep(menus);
  const activePath = path || router.currentRoute.path;
  let asideMenus = [];
  const topMenus = list
    .filter((item) => +item.pid === 0)
    .map((item) => {
      item.children = undefined;
      return item;
    });
  const activeMenu = menus.find((item) => {
    const rootPath = activePath.match(/^\/\w+/);
    return item.lv === 1 && (item.path === rootPath?.[0] || item.path === activePath || item.redirect === activePath);
  });
  if (activeMenu?.children?.length) {
    asideMenus = activeMenu.children;
  }
  // console.log(topMenus, 'topMenus');
  // console.log(asideMenus, 'asideMenus');
  return {
    menus,
    topMenus,
    asideMenus,
  };
};
