<template>
  <el-form  v-if="showSearchView" ref="formRef" :model="form" v-bind="{ ...bindValues }" style="display: flex;" :class="items?.length>1? 'more':'one'">
    <!-- 按钮和筛选条件左右排列 -->
    <div>
      <template v-for="item in items">
      <el-form-item :key="item.prop" :prop="item.prop">
        <el-button v-if="item.tagName === 'button'" :type="item.type" @click="buttonClick(item.action)">
          {{ item.text }}
        </el-button>
      </el-form-item>
    </template>
    </div>
    <div>
      <template v-for="item in items">
      <el-form-item :key="item.prop" :label="item.label" :prop="item.prop">
        <!-- <el-button v-if="item.tagName === 'button'" :type="item.type" @click="buttonClick(item.action)">
          {{ item.text }}
        </el-button> -->
        <el-input
          v-if="item.tagName === 'input'"
          v-model="form[item.prop]"
          :placeholder="item.placeholder || '请输入'"
          clearable
          v-bind="item.props"
          @keyup.enter.native="onSearch"
        ></el-input>
        <el-select
          v-if="item.tagName === 'select'"
          :style="{ width: item.width||'' }"
          v-model="form[item.prop]"
          :placeholder="item.placeholder || '请选择'"
          clearable
          v-bind="item.props"
        >
          <el-option
            v-for="(option, oi) in item.options"
            :key="oi"
            :label="option.label"
            :value="option.value"
          ></el-option>
        </el-select>
        <el-cascader
          v-if="item.tagName === 'cascader'"
          v-model="form[item.prop]"
          :placeholder="item.placeholder || '请选择'"
          clearable
          v-bind="item.props"
        ></el-cascader>
        <el-date-picker v-if="item.tagName === 'date'" v-model="form[item.key]" v-bind="item.props"></el-date-picker>
      </el-form-item>
    </template>
    <el-form-item v-if="showButtons">
      <el-button :loading="loading" type="primary" @click="onSearch">查询</el-button>
      <el-button :loading="loading" type="default" @click="resetForm">重置</el-button>
    </el-form-item>
    </div>
   
  </el-form>
</template>

<script>
import { isFunction } from 'lodash-es';

export default {
  name: 'SearchView',
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
    inline: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selection: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    bindValues() {
      const values = { ...this.$attrs };
      Object.keys(this.$props).map((key) => {
        if (!['model', 'loading', 'items', 'selection'].includes(key)) {
          values[key] = this.$props[key];
        }
      });
      return values;
    },
    showSearchView() {
      return this.items && this.items.length && this.items.filter((item) => item.tagName).length;
    },
    showButtons() {
      return this.items.filter((item) => item.tagName && item.tagName !== 'button').length;
    },
    props() {
      return this.$props;
    },
  },
  mounted() {
    this.initForm();
  },
  methods: {
    haveBoutton(e){
      e.find(v=>{
        if(v.tagName==='button'){
          return true
        }
        return false
      })
    },
    initForm() {
      Object.keys(this.props.model).map((key) => {
        this.$set(this.form, key, this.props.model[key]);
      });
    },
    buttonClick(action) {
      if (isFunction(action)) {
        action();
      } else {
        this.$emit(action, this.selection);
      }
    },
    onSearch() {
      this.$emit('search', this.form);
    },
    resetForm() {
      this.$refs.formRef.resetFields();
      this.initForm();
      this.$emit('search', this.form);
    },
  },
};
</script>

<style scoped>
.one{
  justify-content: left;
}
.more{
  justify-content: space-between;
}
</style>
