

export const FormRuleUtil = {

  RELIST: [
    { dataType: 'code', expression: /^[a-zA-Z0-9_\-\w]+$/, errMsg: '只允许使用数字、字母、下划线、短横线' },
    { dataType: 'code2', expression: /^[a-zA-Z0-9\w]+$/, errMsg: '只允许使用数字、字母' },
    { dataType: 'email', expression: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/, errMsg: '数据格式错误' },
    { dataType: 'mobile', expression: /^1\d{10}$/, errMsg: '数据格式错误' },
    { dataType: 'url', expression: /^(http:|https:|\/\/)/, errMsg: '数据格式错误' },
    { dataType: 'qq', expression: /^[1-9]\d{4,13}$/, errMsg: '数据格式错误' },
    { dataType: 'date', expression: /^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/, errMsg: '数据格式错误' },
    { dataType: 'ip', expression: /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/, errMsg: '数据格式错误' },
  ],
  strRule (name, required, maxLength = 0, RegexType = null) {
    const arr = []
    if (required) {
      arr.push({
        required,
        message: `${name}不能为空`,
        trigger: 'change',
      })
    }
    if (maxLength > 0) {
      arr.push({
        max: maxLength,
        message: `${name}不能超过${maxLength}个字符`,
        trigger: 'change',
      })
    }
    if (RegexType) {
      const obj = this.RELIST.find(item => item.dataType === RegexType)
      if (obj !== undefined) {
        arr.push({
          pattern: obj.expression,
          message: `${name}${obj.errMsg}`,
          trigger: 'change',
        })
      }
    }
    return arr
  },

  // 通用数据类型验证放方法
  numRule (name, required, min = 0, max = 2147483647) {
    const arr = []
    if (required) {
      arr.push({
        required,
        message: `${name}不能为空`,
        trigger: 'change',
      })
    }

    arr.push({
      type: 'number',
      min,
      max,
      message: `${name}值请设置在 ${min} 与  ${max}区间的数字`,
      trigger: 'change',
    })

    return arr
  },
  // 通用布尔类型验证放方法
  boolRule (name, required) {
    return [
      {
        required,
        type: 'boolean',
        message: `${name}不能为空`,
        trigger: 'change',
      },
    ]
  }
}