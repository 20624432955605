import Vue from 'vue';
import selectProduct from "@/components/selectProduct";
const areaForm = require('@/jsons/Area.json')
Vue.mixin({
    data() {
        return {
            isUpdatedLifecycle: false,
            areaForm
        };
    },
    updated() {
        this.isUpdatedLifecycle = true;
    },
    methods: {
        pageInit(init, reload) {
            if (init && typeof init === 'function') {
                init();
                console.log('mounted');
            }
            this.$on('hook:activated', () => {
                if (!this.isUpdatedLifecycle) {
                    return;
                }
                if (reload && typeof reload === 'function') {
                    console.log('activated');
                    reload();
                }
            });
        },
        deleteConfirm(msg, title = '提示', options) {
            const defaultOptions = {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                showCancelButton: true,
                type: 'warning',
            };
            Object.assign(defaultOptions, options);
            return new Promise((resolve) => {
                this.$confirm(msg || '此操作将永久删除数据, 是否继续?', title, defaultOptions)
                    .then(() => {
                        resolve(true);
                    })
                    .catch(() => {
                        resolve(false);
                        this.$message({
                            type: 'info',
                            message: '已取消',
                        });
                    });
            });
        },
        // 导出Excel
        downloadExcel(res, name) {
            let data = res.data;
            if (!data) {
                return;
            }
            if (!name) {
                const list = res.headers['content-disposition'].split(';');
                let temp = list[1].split('filename=')[1];
                ['filename', 'file-name', 'fileName'].forEach((item) => {
                    const fileName = list[1].split(`${item}=`)[1];
                    if (fileName) {
                        temp = fileName;
                    }
                });
                name = decodeURI(temp);
            }
            if ('msSaveOrOpenBlob' in navigator) {
                //ie使用的下载方式
                window.navigator.msSaveOrOpenBlob(data, name + '.xlsx');
            } else {
                let url = window.URL.createObjectURL(
                    new Blob([data], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    })
                );
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                // 获取文件名
                // download 属性定义了下载链接的地址而不是跳转路径
                link.setAttribute('download', name);
                document.body.appendChild(link);
                link.click();
            }
        },
        // 导出
        download(url, name) {
            if (!url) {
                return;
            }
            let link = document.createElement('a');
            link.style.display = 'none';
            link.href = url;
            // 获取文件名
            // download 属性定义了下载链接的地址而不是跳转路径
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
        },
        // 导出到下载中心提示
        exportTips(fileName) {
            const msg = {
                1: `<div>
                                <h4>导出任务提交成功，请勿重复操作</h4>
                                <div>请稍后前往 “下载中心” 下载文件</div>
                                <div>“${fileName}”</div>
                            </div>`,
            };
            this.$confirm(msg[1], '', {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: undefined,
                customClass: 'hide_header reverse-btn',
                center: true,
                dangerouslyUseHTMLString: true,
                showCancelButton: false,
                showClose: false,
            })
                .then(() => {
                })
                .catch(() => {
                });
        },
        // 操作权限
        hasPermission(value) {
            if (value && value instanceof Array && value.length > 0) {
                const roles = this.permission || [];
                const permissionList = value;
                return roles.some((role) => {
                    return permissionList.includes(role);
                });
            } else {
                console.log(value, '----------');
                this.$message.closeAll();
                this.$message({
                    message: '需要角色权限列表',
                    type: 'error',
                });
                return false;
            }
        },
        /**
         * @param {String} text - 需要复制的文本
         * */
        async copyValueMixin(text) {
            try {
                await navigator.clipboard.writeText(text).catch(() => {
                    this.$message.error('复制失败');
                    throw Error('复制失败');
                });
            } catch {
                const textArea = document.createElement('textarea');
                textArea.value = text;
                // 使text area不在viewport，同时设置不可见
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                await new Promise((res, rej) => {
                    // 执行复制命令并移除文本框
                    document.execCommand('copy') ? res() : rej();
                    textArea.remove();
                }).catch(() => {
                    this.$message.error('复制失败');
                });
                this.$message.success('复制成功');
            }
            this.$message.success('复制成功');
        },
        //  选择商品
        selectProduct(query = {}) {
            return new Promise((resolve, reject) => {
                this.$Dialog({
                    title: '选择商品',
                    fullscreen: true,
                    content: selectProduct,
                    query,
                    onConfirm: (_close, data) => {
                        console.log(data);
                        _close()
                        resolve(data)
                    },
                    onClose: (_close) => {
                        _close()
                        reject()
                    }
                })
            })

        },
    },

})
;
