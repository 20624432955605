import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
import { getAuthCache, getToken } from '@/utils/auth'
import { Notification } from 'element-ui'

import LayoutView from '../layout/LayoutView.vue'
import LoginView from '@/views/user'
import Protocol from '@/views/protocol'
import { useMenu } from '@/hooks/useMenu'

Vue.use(VueRouter)

export const constantRoutes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
    },
    component: LoginView,
  },
  {
    path: '/protocol',
    name: 'protocol',
    meta: {
      title: '协议',
    },
    component: Protocol,
  },
  /*{
    path: '/',
    name: 'home',
    meta: {
      title: '首页',
      icon: 'el-icon-menu',
    },
    component: LayoutView,
  },*/
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
})

function setRoutes (roles, to, from, next) {
  store
    .dispatch('GenerateRoutes', roles)
    .then((asyncRoutes) => {
      asyncRoutes.forEach((route) => {
        if (route.path) {
          router.addRoute(route)
        } else {
          console.log('no path', route)
        }
      })
      let redirect = decodeURIComponent(from.query.redirect || to.path) // 重定向路由
      const currSys = getAuthCache('currSys')
      console.log(currSys, 'currSys')
      if ((!redirect || redirect === '/') && asyncRoutes.length) {
        const firstRoute = asyncRoutes[0]
        redirect = firstRoute.path
      }
      const { topMenus, asideMenus } = useMenu(roles, redirect)
      store.commit('SET_TOP_MENUS', topMenus)
      store.commit('SET_ASIDE_MENUS', asideMenus)
      console.log('00',redirect,to.path)
      if (redirect === to.path) {
        next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
      } else {
        next({ path: redirect })
      }
    })
    .catch(async (error) => {
      console.log('error: ', error)
      Notification({
        type: 'error',
        title: '暂无权限，请联系管理人员！',
      })
      await store.dispatch('Logout')
    })
}

const whiteList = ['/login', '/about','/protocol']
router.beforeEach((to, from, next) => {
  const token = getToken()
  console.log(token, '---------', to)
  if (whiteList.indexOf(to.path) !== -1) {
    if (to.path === '/login' && token) {
      console.log('isLogin & token')
      next('/')
    } else {
      next()
    }
  } else if (!token) {
    // redirect login page
    const redirectData = {
      path: '/login',
      replace: false,
    }
    if (to.path && to.path !== '/login') {
      redirectData.query = {
        ...redirectData.query,
        redirect: to.fullPath,
      }
    }
    next(redirectData)
  } else {
    const roles = store.getters.roles
    if (!roles?.length) {
      store.dispatch('GetUserInfo').then(() => {
        store.dispatch('GetRoles').then((data) => {
          const roles = data.roles
          console.log('111a',roles,to,from)
          setRoutes(roles, to, from, next)
        })
      })
    } else {
      next()
    }
  }
})

export default router
