const pageTabs = {
  state: {
    pageTabs: [],
    pageTab: {},
  },
  mutations: {
    SET_TABS: (state, pageTabs) => {
      state.pageTabs = pageTabs;
    },
    SET_TAB: (state, pageTab) => {
      state.pageTab = pageTab;
    },
  },
};
export default pageTabs;
